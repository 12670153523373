import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import RolesItem from "../../../../../../Components/RolesItem/RolesItem";
import { GlobalAuthFetch } from "../../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const RolesFormStepOne = (props) => {
  const { selected, setSelected } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [registry, setRegistry] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { 0: resRegistry } = await Promise.all([
        GlobalAuthFetch({ path: "/registry/allSelected" }),
      ]);

      setRegistry(resRegistry?.payload?.data);
    };
    fetchData();
  }, [dispatch]);

  return (
    <div className="justify-content-start">
      <Row>
        <Col>
          <Field
            data-cy="roleName"
            name="name"
            label={t("global:roles.name")}
            component={BMXTextField}
          />
        </Col>
      </Row>
      <div className="RolesList overflow-auto">
        {registry &&
          registry.length > 0 &&
          registry.map((el) => (
            <RolesItem values={selected} setValues={setSelected} key={el.uuid}>
              {el}
            </RolesItem>
          ))}
        {/* <RolesItem values={selected}  setValues={setSelected} >Secretartuy</RolesItem> */}
      </div>
    </div>
  );
};

export default RolesFormStepOne;
