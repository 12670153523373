import { Form } from "react-final-form";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import {  useState } from "react";
import LexmarkPlotFormStepOne from "./LexmarkPlotFormSteps/LexmarkPlotFormStepOne";
import moment from "moment";

const LexmarkPlotForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState({
    timeRange:{value:"DAY"},
    startDate: moment().subtract(3, 'months').valueOf(),
    endDate:moment().valueOf(),
    suppliesType:"Toner"
  });
  

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {}}
      validate={() => {}}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="LexmarkPlotForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <LexmarkPlotFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default LexmarkPlotForm;
