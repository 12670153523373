import { env } from "../../env";
import { GlobalAuthFetch } from "../GlobalAuthFetch/GlobalAuthFetch";
import Cookies from "js-cookie";
import JSZip from "jszip";
import { Buffer } from "buffer";

export const saveFilesAsZip = async (dataArray, fileName) => {
  const zip = new JSZip();
  const remoteZips = dataArray.map(async (file) => {
    zip.file(`${file.name}`, file.data);
    return file.data;
  });
  Promise.all(remoteZips)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        downloadFromBloob(content, `${fileName || ""}.zip`);
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const checkMSType = (fileName) => {
  const typeFile = fileName.split(".").pop();
  if (["doc", "docx"].includes(typeFile)) {
    return "ms-word";
  }
  if (["xls", "xlsx"].includes(typeFile)) {
    return "ms-excel";
  }
  if (["ppt", "pptx"].includes(typeFile)) {
    return "ms-powerpoint";
  }
};

export const checkFileType = (file) => {
  const { name } = file;
  const msFile = checkMSType(name);
  if (Boolean(msFile)) return [msFile, file];
  const typeFile = name.split(".").pop();
  if (["tif"].includes(typeFile)) return ["tif", file];
  if (["pdf"].includes(typeFile)) return ["pdf", file];
  if (["jpg"].includes(typeFile)) return ["jpg", file];
  if (["png"].includes(typeFile)) return ["png", file];

  return ["default", file];
};

export const downloadFromBloob = (
  data,
  name,
  { document = window.document.body } = {}
) => {
  const OBJ_URL = URL.createObjectURL(data);
  const a = window.document.createElement("a");
  a.href = OBJ_URL;
  a.download = name;
  document.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(OBJ_URL);
  document.removeChild(a);
};

export const downloadFile = async (file) => {
  const { uuid, name } = file;
  const {
    payload: { data },
  } = await GlobalAuthFetch({
    path: `/attachments/${uuid}`,
    addons: { responseType: "blob" },
  });
  downloadFromBloob(data, name);
};

export const openPDF = async (file) => {
  const { uuid } = file;
  const {
    payload: { data },
  } = await GlobalAuthFetch({
    path: `/attachments/${uuid}`,
    addons: { responseType: "blob" },
  });
  const blob = new Blob([data], { type: "application/pdf" });
  const OBJ_URL = URL.createObjectURL(blob);
  window.open(OBJ_URL);
};

export const openImage = async (file) => {
  const { uuid } = file;
  const {
    payload: { data },
  } = await GlobalAuthFetch({
    path: `/attachments/${uuid}`,
    addons: { responseType: "blob" },
  });
  const blob = new Blob([data], { type: "image/png" });
  const OBJ_URL = URL.createObjectURL(blob);
  window.open(OBJ_URL);
};

export const openMSFileToView = (file) => {
  const token = Buffer.from(`Bearer ${Cookies.get("BMX")}`, "binary").toString(
    "base64"
  );

  window.open(
    `https://view.officeapps.live.com/op/view.aspx?src=${window.origin}/${env.REACT_APP_WEBDAV_PATH_API}/${token}/${env.REACT_APP_WEBDAV_FOLDER}/${file.tmpName}`
  );
};

export const openMSFileToEdit = (file) => {
  const token = Buffer.from(`Bearer ${Cookies.get("BMX")}`, "binary").toString(
    "base64"
  );

  return window.open(
    `${checkMSType(file.tmpName)}:ofe|u|${window.origin}/${
      env.REACT_APP_WEBDAV_PATH_API
    }/${token}/${env.REACT_APP_WEBDAV_FOLDER}/${file.tmpName}`
  );
};

export const onOpenToView = (file) => {
  const [type, _file] = checkFileType(file);
  if (type === "jpg" || type === "png" || type === "tif") {
    return openImage(_file);
  }
  if (type === "pdf") {
    return openPDF(_file);
  }
  if (!file.size && type === "default") {
    return window.open(`http://${file.name}`, "_blank", "noreferrer");
  }
  if (type === "default" && file.size) {
    return downloadFile(file);
  }
  openMSFileToView(file);
};
