import { withTranslation } from "react-i18next";
import "./AttachmentsEditFileFormStepOne.scss";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";
import { Attachments } from "../../../../../Utils/GlobalVariables/Statuses/GlobalStatuses";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import moment from "moment";

class AddAttachmentsFormStepOne extends PureComponent {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      attachments: Attachments(t),
    };
  }

  render() {
    const { t, values } = this.props;
    const { attachments } = this.state;
    return (
      <div className="AttachmentsEditFileFormStepOne">
        <Row>
          <Col md={12}>
            <Field
              name="folderName"
              disabled
              component={BMXTextField}
              label={t("global:attachments.folderName")}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Field
              name="fileName"
              component={BMXTextField}
              label={t("global:attachments.fileName")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name="status"
              values={[
                attachments.active,
                attachments.draft,
                attachments.secret,
                attachments.archive,
              ]}
              component={BMXSelectField}
              label={t("global:settings.status")}
            />
          </Col>
          <Col md={6}>
            <Field
              name="archiveDate"
              component={BMXDatePicker}
              label={t("global:settings.archiveDate")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col md={12}>
                <Field
                  name="attachmentDescription"
                  component={BMXTextField}
                  label={t("global:settings.attachmentDescription")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name="comments"
              component={BMXTextField}
              label={t("global:settings.comments")}
            />
          </Col>
        </Row>
        <Row>
          <span>{`${t("global:settings.attachmentAddBy")}: ${values?.createdBy?.firstName || ""} ${values?.createdBy?.lastName || ""} / ${t("global:settings.attachmentCreatedAt")}: ${moment(values?.createdAt).format("DD-MM-YYYY HH:mm")}` }</span>
        </Row>
      </div>
    );
  }
}

export default compose(withTranslation())(AddAttachmentsFormStepOne);
