import SecondaryTable from "../../../Tables/SecondaryTable/SecondaryTable";
import { Icon, IconButton } from "@mui/material";
import { t } from "i18next";
import DoubleLine from "../../../../../Components/DoubleLine/DoubleLine";
import moment from "moment";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";


const SecretariatCorespondenceStepTwo = (props) => {
    const { values } = props;
    const dispatch = useDispatch();
    function deleteTableElement(element) {
        dispatch(
            show("ConfirmModal", {
                onSubmit: async () => {
                    const { payload } = await GlobalAuthFetch({
                        path: `TODO`,
                        method: "DELETE",
                    });
                    if (payload?.status >= 300 || !payload?.status) {
                        return;
                    }

                    //   loadData();
                    NotificationManager.success(t("global:success"));
                    dispatch(hide("ConfirmModal"));
                },
            }));
    }

    return (
        <div>
            <SecondaryTable
                // sortChange={this.sortChange}
                // searchFunction={this.searchFunction}
                search={false}
                pagination={false}
                headers={[
                    {
                        key: "nameAndLastName",
                        name: t("global:nameAndLastName"),
                        width: "auto",
                        justifyContent: "left",
                        verticalCenter: true,
                    },
                    {
                        key: "dateAndTimeOfTansfer",
                        name: t("global:dateAndTimeOfTansfer"),
                        width: "auto",
                        justifyContent: "left",
                        verticalCenter: true,
                    },
                    {
                        key: "dateAndTimeOfCollection",
                        name: t("global:dateAndTimeOfCollection"),
                        width: "auto",
                        justifyContent: "left",
                        verticalCenter: true,
                    },
                    {
                        key: "Buttons",
                        name: "",
                        width: "10%",
                        justifyContent: "end",
                        sort: false,
                        verticalCenter: true,
                    },
                ]}
                data={values?.deliveredTo?.map((element) => ({
                    nameAndLastName: <DoubleLine firstLine={element.worker?.nameAndLastName || `${element.worker?.firstName} ${element.worker?.lastName || ""}`} secondLine={element.department}></DoubleLine>,
                    dateAndTimeOfTansfer: <div><span>{Boolean(element.createdAt) ? moment(element.createdAt).format("DD/MM/YYYY") : "-"}</span> <span>{Boolean(element.createdAt) ? moment(element.createdAt).format("HH:mm") : ""}</span></div>,
                    dateAndTimeOfCollection: <div><span>{Boolean(element.receivedDate) ? moment(element.receivedDate).format("DD/MM/YYYY") : "-"}</span> <span>{Boolean(element.receivedDate) ? moment(element.receivedDate).format("HH:mm") : ""}</span></div>,
                    Buttons: (
                        <div style={{ display: "flex" }}>
                            <IconButton onClick={() => deleteTableElement(element)}>
                                <Icon
                                    style={{ fontSize: "16px", padding: "0" }}
                                    className="error text"
                                >
                                    delete
                                </Icon>
                            </IconButton>
                        </div>
                    ),
                }))}
            />

        </div>
    );
}

export default SecretariatCorespondenceStepTwo;