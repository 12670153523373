import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { useEffect, useState } from "react";

const RentContractFormStepTwo = (props) => {
  const { view } = props;

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const servicePriorityContractFetch = GlobalAuthFetch({
        path: "/dictionary/servicePriorityContract/fieldName",
      });
      const [servicePriorityContractPayload] = await Promise.all([
        servicePriorityContractFetch,
      ]);
      setServicePriorityContractSelect(
        servicePriorityContractPayload?.payload?.data?.dictionaryEntries
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [servicePriorityContractSelect, setServicePriorityContractSelect] =
    useState([]);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.more")} />
      <Row>
        <Col xs={2}>
          <Field
            disabled={view}
            name="accountDay"
            label={`${t("global:rentContract.accountDay")}`}
            component={BMXTextField}
            data-cy="accountDay"
            step={0.5}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 28 } }}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            disabled={view}
            name="transferDay"
            data-cy="transferDay"
            component={BMXTextField}
            label={t("global:rentContract.transferDay")}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            disabled={view}
            name="accountReceiverEmail"
            label={`${t("global:rentContract.accountReceiverEmail")}`}
            component={BMXTextField}
            data-cy="accountReceiverEmail"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }}>
          <Field
            disabled={view}
            component={BMXSelectField}
            values={servicePriorityContractSelect
              ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
              ?.map((el) => ({
                name: el.entryName,
                value: el.uuid,
              }))}
            label={`${t("global:rentContract.servicePriorityContract")}`}
            name="servicePriorityContract"
            data-cy="servicePriorityContract"
          />
        </Col>
        <Col xs={2}>
          <Field
            disabled={view}
            name="deployCost"
            label={`${t("global:rentContract.deployCost")}`} 
            type="money"
            component={BMXTextField}
            decimalScale={2}
            data-cy="deployCost"
          />
        </Col>
      </Row>
      <Row>       
        <Col xs={5}>
        <Field
            disabled={view}
            name="automaticExtensionOfTheContract"
            data-cy="automaticExtensionOfTheContract"
            component={BMXTextField}
            label={t("global:rentContract.automaticExtensionOfTheContract")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractFormStepTwo;
