import { Form } from "react-final-form";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import DictionaryFormStepOne from "./DictionaryFormSteps/DictionaryFormStepOne";
import { onSubmit, onValidate } from "./DictionaryFormSubmit";

const DictionaryForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues } = props;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="DictionaryForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <DictionaryFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default DictionaryForm;
