import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../../../Utils/i18next/i18next";
const { t } = i18n;
const initialState = {
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data:[],
    searchFields: [{ key: "name", name: t('global:secretaryEmail.name') }],
    selectedSearchFields: ["name"]
  },
};

const secretaryEmailReducer = createSlice({
  name: "secretaryEmailReducer",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRole: () => initialState
  },
  extraReducers: {},
});

export const { updateRegistry, clearRole } = secretaryEmailReducer.actions;

export default secretaryEmailReducer.reducer;
