import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "client.name", name: "global:operationLock.client" },
      { key: "reason.entryName", name: "global:operationLock.reason" },
      { key: "status.name", name: "global:operationLock.status" },
    ],
    selectedSearchFields: ["client.name"],
    dictionaryFields: ["reason"],
    url: "operationlock",
  },
};

const operationlockReducer = createSlice({
  name: "operationlockReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = operationlockReducer.actions;

export default operationlockReducer.reducer;
