import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BMXFetch from "../../../Utils/GlobalFetch/GlobalFetch";
import Cookies from "js-cookie";


export const loadRightBoxAlert = createAsyncThunk("loadRightBoxAlert", async () => {
  const token = `Bearer ${Cookies.get("BMX")}`;
  const payload = await new BMXFetch("/alert/listForMe").setHeaders({ Authorization: token }).send()
  return payload.data;
});

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [],
    selectedSearchFields: [],
    dictionaryFields: ["priority"],
    url: "alert",
    selected:0
  },
  rightBoxAlertsData:[]
};

const alertReducer = createSlice({
  name: "alertReducer",
  initialState,
  reducers: {
    updateManager: (state, { payload }) => {
      state.manager = payload
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    updateSelected:(state,{payload}) => {
      state.table = {...state.table, page:1, selected:payload}
    },
    clearRegistry: () => initialState
  },
  extraReducers: {
    [loadRightBoxAlert.fulfilled]: (state,{payload})=>{
      state.rightBoxAlertsData = payload
    }
  },
});

export const { updateRegistry, clearRegistry, updateManager, updateDefaultFileStatus, updateDefaultFolders, updateAvailableStatuses,updateSelected } = alertReducer.actions;

export default alertReducer.reducer;