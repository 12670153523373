import { t } from "i18next";

export const submit = (values, props) => {
  const { setFolders, folders, handleHide, selectedElement } = props;
  delete values.icons
  
  if (props?.update) {
    setFolders(folders.map((el,index)=>index===selectedElement.index?{...el,...values}:el));
    handleHide();
    return;
  }
  setFolders([...folders, values]);
  handleHide();

};

export const validate = (values) => {
  const errors = {};

  if(!values.name){
    errors.name= t('global:fieldRequired');
  }

  return errors;
};
