import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";

const DictionaryFormStepOne = (props) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <Row>
        <Col>         
          <BMXSeparator title={t('global:dictionary.addDictionary')} />
        </Col>     
      </Row>     
      <Row>
        <Col> 
          <Field            
            name="dictionaryName"
            component={BMXTextField}
            label={t('global:dictionary.name')}
            />        
        </Col>         
      </Row>     
    </div>
  );
};

export default DictionaryFormStepOne;