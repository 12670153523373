import store from "../../../../../store";
import { show } from "redux-modal";
import { withTranslation } from "react-i18next";
import "./AddAttachmentsFormStepTwo.scss";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { Attachments } from "../../../../../Utils/GlobalVariables/Statuses/GlobalStatuses";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
const { dispatch } = store;
class AddAttachmentsFormStepTwo extends PureComponent {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      folders: [],
      attachments: Attachments(t),
    };
  }

  componentDidMount() {
    const { folders } = this.props;
    this.setState({ folders: [...folders] });
  }

  setNewFolders = (newFolders) => {
    this.setState({ folders: [...newFolders] });
  };

  addFolderModal = () => {
    const {
      match,
      loadData,
      updateSingleRegistry,
      form: { change },
    } = this.props;
    dispatch(
      show("AttachmentsFolderModal", {
        match,
        uuid: match.params.uuid,
        loadData,
        updateSingleRegistry,
        change,
        setNewFolders: this.setNewFolders,
      })
    );
  };

  render() {
    const { t } = this.props;
    const { folders, attachments } = this.state;

    return (
      <>
        <div className="AddAttachmentsFormStepTwo">
          <Row>
            <Col md={12}>
              <Field
                name="selectFolder"
                values={folders?.map((folder) => ({
                  value: folder.uuid,
                  name: folder.name,
                }))}
                afterIcon={"create_new_folder"}
                afterIconClick={this.addFolderModal}
                component={BMXSelectField}
                label={t("global:settings.selectFolder")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="status"
                values={[
                  attachments.active,
                  attachments.draft,
                  attachments.secret,
                  attachments.archive,
                ]}
                component={BMXSelectField}
                label={t("global:settings.status")}
              />
            </Col>
            <Col md={6}>
              <Field
                name="archiveDate"
                component={BMXDatePicker}
                label={t("global:settings.archiveDate")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={12}>
                  <Field
                    name="attachmentDescription"
                    component={BMXTextField}
                    label={t("global:settings.attachmentDescription")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Field
                name="comments"
                component={BMXTextField}
                label={t("global:settings.comments")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(withTranslation())(AddAttachmentsFormStepTwo);
