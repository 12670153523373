import { sanitize } from "dompurify";
export const EmailImageLoader = ({ document = window.document } = {}) => {
  const cids = document.querySelectorAll('[src^="cid"]');

  for (const cid of cids) {
    const cidSrc = cid.src;
    const HTMLTag = cidSrc.split("cid:")[1];
    if (Boolean(HTMLTag)) {
      const getTagElements = document.getElementsByClassName(HTMLTag);
      for (const element of getTagElements) {
        let firstElement = element.innerHTML.search("iVBOR");
        if (firstElement === -1) firstElement = 0;
        let base64 = element.innerHTML.slice(firstElement);
        base64 = base64.split("---")[0];
        cid.src = `data:image/png;base64,${base64}`;
        element.style.display = "none";
      }
    }
  }
};

export const emailExtarctor = (email) => {
  return email?.slice(email?.indexOf("<") + 1, email?.indexOf(">"));
};

export const extractHtmlContent = (content) => {
  var span = document.createElement("span");
  //   span.setHTML(content||"");
  span.innerHTML = sanitize(content) || "";
  return span.textContent || span.innerText;
};
