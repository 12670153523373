import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { passwordValidator } from "../../../../Utils/Validators/Validators";

export const onSubmit = async (values, props) => {
  const {
    userSession: { user },
  } = store.getState();
  const { payload } = await GlobalAuthFetch({
    path: `/worker/${user.uuid}/passwordUpdate`,
    method: "PUT",
    data: values,
  });
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return {
      oldPassword: i18n.t(`global:${payload?.data?.message || "Error"}`),
    };
  }
  props.setErrorHeader(0, false);
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  if (values.oldPassword === values.newPassword) {
    errorsStep0.oldPassword = i18n.t("global:samePassword");
    errorsStep0.newPassword = i18n.t("global:samePassword");
  }

  if (values.repetPassword !== values.newPassword) {
    errorsStep0.repetPassword = i18n.t("global:passwordsDontMatch");
    errorsStep0.newPassword = i18n.t("global:passwordsDontMatch");
  }

  if (!values.newPassword?.length > 8) {
    errorsStep0.newPassword = i18n.t("global:shortPassword");
  }

  if (!values.newPassword) {
    errorsStep0.newPassword = i18n.t("global:passwordIsRequired");
  }

  if (!values.oldPassword) {
    errorsStep0.oldPassword = i18n.t("global:passwordIsRequired");
  }

  if (!values.repetPassword) {
    errorsStep0.repetPassword = i18n.t("global:passwordIsRequired");
  }

  if (!passwordValidator(values.newPassword)) {
    errorsStep0.newPassword = i18n.t("global:paswordNotMatchRequirement");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
