import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./PrinterModelFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import PrinterModelFormStepOne from "./PrinterModelFormSteps/PrinterModelFormStepOne";

const PrinterModelForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        manufacturer: Boolean(_initialValues?.manufacturer)
          ? {
              uuid: _initialValues?.manufacturer?.uuid,
              label: _initialValues?.manufacturer?.entryName,
            }
          : null,
        deviceType: Boolean(_initialValues?.deviceType)
          ? {
              uuid: _initialValues?.deviceType?.uuid,
              label: _initialValues?.deviceType?.entryName,
            }
          : null,
        competenceIndicator: {
          value: Number(_initialValues?.competenceIndicator),
        },
      });
      return;
    }

    setInitialValues({});
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="PrinterModelForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <PrinterModelFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default PrinterModelForm;
