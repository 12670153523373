import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const RentContractFormStepTwo = (props) => {
  const { view } = props;
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.rentOptions")} />
      <Row>
        <Col xs={3}>
          <Field
            disabled={view}
            fieldtype="checkbox"
            component={BMXTextField}
            name="specialOffer"
            label={t("global:rentContract.specialOffer")}
          />
        </Col>
        <Col xs={{ span: 5 }}>
          <Field
            disabled={view}
            name="specialOfferEnd"
            data-cy="specialOfferEnd"
            component={BMXDatePicker}
            label={t("global:rentContract.specialOfferEnd")}
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={view}
            name="specialOfferDefault"
            label={`${t("global:rentContract.specialOfferDefault")}`}
            type="money"
            decimalScale={2}
            component={BMXTextField}
            data-cy="specialOfferDefault"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractFormStepTwo;
