import { MenuList, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const DictionaryConnectFormStepTwo = (props) => {
  const { t } = useTranslation();
  const {
    step,
    selectedValues,
    setSelectedValues,
    setDisableButtons,
    usedFields,
    setUsedFields,
    element,
  } = props;
  const [fields, setFields] = useState([]);
  const store = useSelector((state) => state);

  useEffect(() => {
    setFields([]);
  }, [step]);

  useEffect(() => {
    if (step === 1) {
      loadData();
    }
    if (selectedValues?.field) {
      setDisableButtons(false);
    }
    if (!selectedValues?.field) {
      setDisableButtons(true);
    }
    // eslint-disable-next-line
  }, [step, selectedValues]);

  const loadData = async () => {
    const { payload } = await GlobalAuthFetch({ path: "/dictionary" });
    if (payload?.data?.length > 0) {
      setUsedFields(
        payload?.data?.map((el) => el.fieldName).filter((e) => !!e)
      );
    }
    const findRegistry = Object.keys(store)
      ?.map((el) => {
        return store[el];
      })
      .find((els) => els?.table?.url === selectedValues?.registry);
    setFields(findRegistry?.table?.dictionaryFields);
  };

  const onSelectedFields = (field) => {
    setSelectedValues({ ...selectedValues, field: field });
  };

  const isDisabled = (field) => {
    if (element?.fieldName === field) {
      return false;
    }
    return usedFields.includes(field);
  };

  const wasSelected = (field) => {
    return element?.fieldName === field;
  };

  const isSelected = (field) => {
    return selectedValues.field === field || element?.fieldName === field;
  };

  return (
    <div className="justify-content-start">
      <Row>
        <Col>
          <BMXSeparator title={t("global:dictionary.selectField")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <MenuList>
            {fields?.map((field, index) => (
              <MenuItem
                key={`MenuItem-${index}`}
                className={`${wasSelected(field) ? "success text" : ""}`}
                disabled={isDisabled(field)}
                selected={isSelected(field)}
                onClick={() => onSelectedFields(field)}
              >
                {`${field}`}
              </MenuItem>
            ))}
          </MenuList>
        </Col>
      </Row>
    </div>
  );
};

export default DictionaryConnectFormStepTwo;
