import { t } from "i18next";
import store from "../../../../store";

export const submit = (values, props) => {
  const { handleHide, outerForm } = props;
  const {
    userSession: { user },
  } = store.getState();

  const outerFormValues = outerForm.getState().values;
  const prevNotes = outerFormValues?.notes || [];
  const notes = [...prevNotes];

  if (Boolean(values?.content) && Boolean(values?.uuid)) {
    const noteIndex = notes.findIndex((el) => el.uuid === values.uuid);
    if (noteIndex!==-1) {
      notes[noteIndex]={
        uuid: values?.uuid,
        content: values?.content,
        createdBy: user?.uuid
          ? {
              uuid: user?.uuid,
              firstName: user?.firstName,
              lastName: user?.lastName,
            }
          : null,
        createdAt: new Date(),
      }
    }
  }
  if (Boolean(values?.content) && !Boolean(values?.uuid)) {
    notes.push({
      content: values?.content,
      createdBy: user?.uuid
        ? {
            uuid: user?.uuid,
            firstName: user?.firstName,
            lastName: user?.lastName,
          }
        : null,
      createdAt: new Date(),
    });
  }
  outerForm.change("notes", notes);
  handleHide();
  return;
};

export const validate = (values) => {
  const errors = {};

  if (!values.content) {
    errors.content = t("global:fieldRequired");
  }

  return errors;
};
