import { cloneDeep } from "lodash";
import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  const { update, updateSingleRegistry, updateTableRegistry } = props;
  const data = cloneDeep(values);

  if (update) {
    const { payload } = await GlobalAuthFetch({
      path: `/attachments/fileUpdate`,
      method: "PATCH",
      data: data,
    });

    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }
  }

  props.setErrorHeader(0, false);

  props.loadData();
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.name) {
    errorsStep0.name = i18n.t("global:attachments.errors.name");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
