import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep } from "lodash";

export const onSubmit = async (values, props) => {
  const { rentContractReducer } = store.getState();
  const {
    updateTableRegistry,
    loadData,
    update,
    updateSingleRegistry,
    uuid,
    reckoningUUID,
  } = props;
  const data = cloneDeep(values);

  let payload;

  if (!update) {
    data.status = rentContractReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/reckoning/${uuid}/${reckoningUUID}/reckoningSingleDevice`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  // if (!values.title) {
  //   errorsStep0.title = t("global:rentContract.errors.title");
  // }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
