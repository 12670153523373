import i18n from "../../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";
import { updateAdminRegistryByName } from "../../../../../Utils/Sockets";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  const ROLE_REGISTRY_NAME = 'roles';

  const { update } = props;

  const data = values;

  if (update) {
    const {
      uuid
    } = data;
    const { payload } = await GlobalAuthFetch({
      path: `/authorities/role/${uuid}`,
      method: "PATCH",
      data: data,
    });

    if (payload?.status >= 400 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }

    props.setErrorHeader(0, false);
    //Succes nofify
    props.loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
  }

  if (!update) {
    const { payload } = await GlobalAuthFetch({
      path: "/authorities/role",
      method: "POST",
      data: data,
    });

    if (payload?.status >= 400 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }

    props.setErrorHeader(0, false);
    //Succes nofify
    props.loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
  }
  updateAdminRegistryByName(ROLE_REGISTRY_NAME);
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.name) {
    errorsStep0.name = t("global:role.errors.name");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
