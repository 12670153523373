import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, method, set } from "lodash";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { suppliesManagementReducer } = store.getState();
  const {
    updateTableRegistry,
    loadData,
    update,
    updateSingleRegistry,
    uuid,
    selectedToner,
    confirmModalShow,
  } = props;

  const data = cloneDeep(values);
  data.uuid = selectedToner.uuid;

  const submitFunc = async () => {
    switch (data?.selectedPerson) {
      case "technician":
        data.responsibleTechnic = true;
        data.responsibleDriver = false;
        data.responsibleCourier = false;
        break;
      case "driver":
        data.responsibleTechnic = false;
        data.responsibleDriver = true;
        data.responsibleCourier = false;
        break;
      case "courier":
        data.responsibleTechnic = false;
        data.responsibleDriver = false;
        data.responsibleCourier = true;
        break;
      default:
        data.responsibleTechnic = false;
        data.responsibleDriver = false;
        data.responsibleCourier = false;
    }
    delete data?.selectedPerson;

    let payload;

    // if (!update) {
    //   // data.status = suppliesManagementReducer?.availableStatuses.find(
    //   //   (el) => !!el.isSelected
    //   // );

    //   const res = await GlobalAuthFetch({
    //     path: `/suppliesmanagement/addReleaseSupplies`,
    //     method: "POST",
    //     data,
    //   });
    //   payload = res.payload;
    // }

    // if (update) {
    const res = await GlobalAuthFetch({
      path: `/suppliesmanagement/addReleaseSupplies`,
      method: "POST",
      data,
    });
    payload = res.payload;

    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);

      return;
    }

    props.setErrorHeader(0, false);

    //Succes nofify
    loadData();
    NotificationManager.success(i18n.t("global:success"));
    if (updateSingleRegistry) updateSingleRegistry();
    if (updateTableRegistry) updateTableRegistry();
    props.handleHide();
  };

  const foundOnRentContract = await GlobalAuthFetch({
    path: `/device/findOnRentContract/${data?.device?.uuid}`,
    method: FetchMethodType.get,
  });

  if (!foundOnRentContract.payload.data && values.biuromaxStatus === "WYDANE") {
    confirmModalShow(submitFunc);
    return;
  }

  await submitFunc();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.biuromaxStatus) {
    errorsStep0.biuromaxStatus = t("global:fieldRequired");
  }
  if (values.biuromaxStatus === "WYDANE") {
    if (!values.documentId) {
      errorsStep0.documentId = t("global:fieldRequired");
    }

    if (values.selectedPerson === "courier" && !values.numberWaybill) {
      errorsStep0.numberWaybill = t("global:fieldRequired");
    }

    if (!values.selectedPerson) {
      errorsStep0.selectedPerson = t("global:fieldRequired");
    }
    if (!values.acceptedAs) {
      errorsStep0.acceptedAs = t("global:fieldRequired");
    }
    if (!values.supplieState) {
      errorsStep0.supplieState = t("global:fieldRequired");
    }
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
