import { get } from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  const { update, updateSingleRegistry, pathUuid, file, folder } = props;
  const archiv = get(values, "archiveDate", false);

  const dataFile = {
    uuid: file.uuid,
    description: values.attachmentDescription || "",
    comment: values.comments || "",
    name: values.fileName,
    archiveDate: archiv ? moment(archiv) : null,
    status: get(values, "status", "")?.value,
  };

  const dataFolder = {
    uuid: folder.uuid,
    description: values.folderDescription || "",
  };

  if (update) {
    const folderFetch = GlobalAuthFetch({
      path: `/attachments/folderUpdate`,
      method: "PATCH",
      data: dataFolder,
    });

    const fileFetch = GlobalAuthFetch({
      path: `/attachments/fileUpdate`,
      method: "PATCH",
      data: dataFile,
    });

    const [payloadFolder, payloadFile] = await Promise.all([
      folderFetch,
      fileFetch,
    ]);

    if (
      payloadFolder?.payload?.status >= 300 ||
      !payloadFolder?.payload?.status
    ) {
      props.setErrorHeader(0, true);
      return;
    }

    if (payloadFile?.payload?.status >= 300 || !payloadFile?.payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }
  }
  props.setErrorHeader(0, false);
  //Succes nofify
  props.loadData();
  updateSingleRegistry(pathUuid);
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
