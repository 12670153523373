import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./RolesFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import RolesFormStepOne from "./RolesFormSteps/RolesFormStepOne";
import { useEffect, useState } from "react";


const RolesForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues } = props;
  const [selected, setSelected] = useState([]);
  
  useEffect(() => {
    if (initialValues) {
      setSelected(initialValues.rights)
    }
  }, [initialValues])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit({ ...val, rights: selected }, props)}
      validate={(val) => onValidate({ ...val, rights: selected }, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="RolesForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <RolesFormStepOne selected={selected} setSelected={setSelected} {...formProps} {...props} />
          </SwipeableViews>
        </form>
      }}
    </Form>
  );
};

export default RolesForm;
