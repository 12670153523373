import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import { connectModal } from "redux-modal";
import { useEffect, useState } from "react";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useTranslation } from "react-i18next";
import DictionaryConnectForm from "../../Forms/Admin/DictionaryConnectForm/DictionaryConnectForm";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const DictionaryConnectModal = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:dictionary.chooseRegistry"), error: false },
    { title: t("global:dictionary.chooseField"), error: false },
  ]);
  //Dodawać tu pola słownikowe które nie są rejestrami
  const [registry, setRegistry] = useState([
    { name: t("global:dictionary.alert.main"), url: "alert" },
  ]);
  const [selectedValues, setSelectedValues] = useState({});
  const [usedFields, setUsedFields] = useState([]);

  const [disableButton, setDisableButtons] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const formName = "DictionaryConnectForm";
  const { handleHide, show } = props;

  const loadData = async () => {
    const { payload } = await GlobalAuthFetch({ path: "/registry/all" });

    if (payload?.data?.length > 0) {
      setRegistry([...registry, ...payload.data]);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const buttons = () => {
    return (
      <>
        {step > 0 && (
          <BMXButton
            type="subbmit"
            onClick={() => setStep(step - 1)}
            bmxclass="primary"
          >
            {t("global:back")}
          </BMXButton>
        )}
        {step >= steps.length - 1 && (
          <BMXButton
            type="subbmit"
            onClick={() => setIsSubmit(true)}
            bmxclass="Success"
            disabled={disableButton}
            form={formName}
          >
            {t("global:save")}
          </BMXButton>
        )}
      </>
    );
  };

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      onClose={handleHide}
      header={t("global:selectRegistry")}
      buttons={buttons()}
      steps={steps}
      disableStepper
    >
      <DictionaryConnectForm
        setSelectedValues={setSelectedValues}
        selectedValues={selectedValues}
        usedFields={usedFields}
        setUsedFields={setUsedFields}
        registry={registry}
        setDisableButtons={setDisableButtons}
        steps={steps}
        isSubmit={isSubmit}
        step={step}
        setStep={setStep}
        setErrorHeader={setErrorHeader}
        handleHide={handleHide}
        formName={formName}
        {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "DictionaryConnectModal" })(
  DictionaryConnectModal
);
