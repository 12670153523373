import { Form } from "react-final-form";
import "./ChangePasswordForm.scss";
import { onSubmit, onValidate } from "./ChangePasswordFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import ChangePasswordFormStepOne from "./ChangePasswordFormSteps/ChangePasswordFormStepOne";

const ChangePasswordForm = (props) => {
  const theme = useTheme();
  const { step, setStep } = props;
  return (
    <Form
      initialValues={{
        oldPassword: "",
        newPassword: "",
        repetPassword: "",
      }}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {({ handleSubmit, values }) => {
        return (
          <form
            id={props.formName}
            onSubmit={handleSubmit}
            className="ChangePasswordForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <ChangePasswordFormStepOne values={values} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default ChangePasswordForm;
