import Quill from "quill";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./BMXQuillArea.scss";
import { sanitize } from "dompurify";

const BMXQuillArea = (props) => {
  const { className, quillOptions, onChangeText, content } = props;
  const quilRef = useRef();
  const loadQuill = () => {
    const timer = setTimeout(() => {
      const editor = new Quill(quilRef.current, quillOptions);
      // editor.root.setHTML(content||"");
      editor.root.innerHTML = sanitize(content) || "";
      editor.on("text-change", (delta, oldDelta, source) => {
        onChangeText(editor, delta, oldDelta, source);
      });
      clearTimeout(timer);
    }, 200);
  };
  useEffect(() => {
    loadQuill();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={className}>
      <div className="QuilArea">
        <div ref={quilRef} />
      </div>
    </div>
  );
};

BMXQuillArea.propTypes = {
  /**
        Props zawiera className komponentu 
      */
  className: PropTypes.string,
  /**
      Props zawiera opcje quill
    */
  quillOptions: PropTypes.object,
  /**
      Props zawiera funkcje onChnageText
    */
  onChangeText: PropTypes.func,
  /**
      Props zawiera funkcje onChnageText
    */
  content: PropTypes.string,
};

BMXQuillArea.defaultProps = {
  content: "",
  onChangeText: (editor, delta, oldDelta, source) => {
    console.log(editor, delta, oldDelta, source);
  },
  quillOptions: {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        // ["blockquote", "code-block"],

        // [{ header: 1 }, { header: 2 }], // custom button values
        // [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ],
    },
    theme: "snow",
  },
};

export default BMXQuillArea;
