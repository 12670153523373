import { Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import BMXSeparator from "../../Separator/BMXSeparator";
import "./BMXTabsSelect.scss";

const BMXTabsSelect = (props) => {
  const { input, tabsData, className, label } = props;

  const selectedValue = input.value ? input.value : tabsData?.at(0)?.value;
  input.onChange(selectedValue);

  const isSmall = tabsData.every((el) => Boolean(el?.icon) === false);

  return (
    <div className={`BMXTabsSelect ${Boolean(className) ? className : ""}`}>
      <BMXSeparator miniSeparator title={label || ""} />
      <Tabs
        className={`${isSmall ? "Small" : ""}`}
        {...props}
        value={selectedValue}
        onChange={(e, value) => input.onChange(value)}
      >
        {tabsData.map((el) => (
          <Tab disableRipple {...el} label={el?.name} />
        ))}
      </Tabs>
    </div>
  );
};

export default BMXTabsSelect;

BMXTabsSelect.propTypes = {
  tabsData: PropTypes.array.isRequired,
};
BMXTabsSelect.defaultProps = {
  tabsData: [],
};
