import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import "./StorageEditFileFormStepOne.scss";

const StorageEditFileStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div className="StorageEditFileStepOne">
      <BMXSeparator title={t("global:storage.fileInStorage")} />
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:storage.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            multiline={true}
            label={`${t("global:storage.description")}*`}
            name="description"
            data-cy="description"
          />
        </Col>
      </Row>
    </div>
  );
};

export default StorageEditFileStepOne;
