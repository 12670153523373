import { Form } from "react-final-form";
import "./ChangePhoneForm.scss";
import { onSubmit, onValidate } from "./ChangePhoneFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import ChangePhoneFormStepOne from "./ChangePhoneFormSteps/ChangePhoneFormStepOne";

const ChangePhoneForm = (props) => {
  const theme = useTheme();
  const { step, setStep } = props;
  return (
    <Form
      initialValues={{
        phone: props.phone,
      }}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {({ handleSubmit, values }) => {
        return (
          <form
            id={props.formName}
            onSubmit={handleSubmit}
            className="ChangePhoneForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <ChangePhoneFormStepOne values={values} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default ChangePhoneForm;
