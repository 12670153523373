import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';
import ChangeImageFormStepOne from './ChangeImageFormSteps/ChangeImageFormStepOne';
import { useState } from 'react';
import './ChangeImageForm.scss';
import PropTypes from "prop-types";

const ChangeImageForm = (props) => {
  const theme = useTheme();
  const [croppedImg, setCroppedImg] = useState();
  const { step, setStep, setDisableSubmit, onSubmit, onValidate, circularCrop } = props;
  return (
    <Form
      initialValues={{}}
      onSubmit={(val) => onSubmit({ ...val, ...croppedImg }, props)}
      validate={(val)=>onValidate(val,props)}
    >
      {({ handleSubmit }) => (
        <form
          id={props.formName}
          onSubmit={handleSubmit}
          className="ChangeImageForm"
        >
          <SwipeableViews
            className="h-100"
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={step}
            onChangeIndex={(index) => {
              setStep(index);
            }}
          >
            <ChangeImageFormStepOne
              setDisableSubmit={setDisableSubmit}
              setCroppedImg={setCroppedImg}
              circularCrop={circularCrop}
            />        
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default ChangeImageForm;

ChangeImageForm.propTypes = {
  step:PropTypes.number.isRequired,
  setStep:PropTypes.func.isRequired
};

ChangeImageForm.defaultProps = {
  circularCrop: true,
  onSubmit:()=>{},
  onValidate:()=>{}
};