import BMXModal from '../../../../Components/Modals/BMXModal/BMXModal';
import { connectModal } from 'redux-modal';
import { useState } from 'react';
import BMXButton from '../../../../Components/Buttons/BMXButton/BMXButton';

const Modal = (props) => {
  const [step, setStep] = useState(0);
  const { handleHide, show } = props;

  const buttons = () => {
    return (
      <>
        <BMXButton bmxclass="Success" onClick={handleHide}>
          Zapisz
        </BMXButton>
      </>
    );
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={'600px'}
      onClose={handleHide}
      header="Ustawienia"
      buttons={buttons()}
      steps={[
        { title: 'Ustawienia klienta' },
        { title: 'Ustawienia aplikacji' },
      ]}
    >
      <div className="row">
        <div className="col-6">Step: {step}</div>
      </div>
    </BMXModal>
  );
};

export default connectModal({ name: 'Modal' })(Modal);
