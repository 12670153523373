import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const DepartmentsFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [categorySelect, setCategorySelect] = useState([]);
  const { availableStatuses } = useSelector(
    (state) => state.departmentsReducer
  );

  useEffect(() => {
    const fetchData = async () => {
      const {
        payload: {
          data: { dictionaryEntries },
        },
      } = await GlobalAuthFetch({ path: "/dictionary/category/fieldName" });
      setCategorySelect(dictionaryEntries);
    };
    fetchData();
  }, [dispatch]);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:departments.departmentData")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:departments.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:departments.shortName")}`}
            name="shortName"
            data-cy="shortName"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={availableStatuses?.map((el) => ({
              name: el.name,
              value: el.uuid,
            }))}
            label={`${t("global:departments.status")}*`}
            name="status"
            data-cy="status"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:departments.location")}`}
            name="location"
            data-cy="location"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={categorySelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={t("global:departments.category")}
            name="category"
            data-cy="category"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "5%" }}>
        <Col xs={12}>
          <Field
            type="description"
            component={BMXTextField}
            label={`${t("global:departments.description")}`}
            name="description"
            data-cy="description"
          />
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentsFormStepOne;
