import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";

const PrinterModelFormStepOne = (props) => {
  const { t } = useTranslation();
  const [manufacturerSelect, setManufacturerSelect] = useState([]);
  const [deviceTypeSelect, setDeviceTypeSelect] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState([]);

  const { values, initialValues } = props;

  useEffect(() => {
    const fetchData = async () => {
      const fetchManufacturer = GlobalAuthFetch({
        path: "/dictionary/manufacturer/fieldName",
      });
      const fetchDeviceType = GlobalAuthFetch({
        path: "/dictionary/deviceType/fieldName",
      });
      const fetchOptions = GlobalAuthFetch({
        path: "/dictionary/options/fieldName",
      });

      const [payloadManufacturer, payloadDeviceType, payloadOptions] =
        await Promise.all([fetchManufacturer, fetchDeviceType, fetchOptions]);

      setManufacturerSelect(
        payloadManufacturer?.payload?.data?.dictionaryEntries
      );
      setDeviceTypeSelect(payloadDeviceType?.payload?.data?.dictionaryEntries);
      setOptionsSelect(
        payloadOptions?.payload?.data?.dictionaryEntries?.map((el) => ({
          value: el.uuid,
          name: el.entryName,
        }))
      );
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:printerModel.modelInformation")} />
      <Row>
        <Col xs={4}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:printerModel.manufacturer")}*`}
            name="manufacturer"
            data-cy="manufacturer"
            options={
              manufacturerSelect
                ?.sort((a, b) => a?.entryName?.localeCompare(b?.entryName))
                ?.map((el) => ({
                  uuid: el.uuid,
                  label: `${el.entryName || ""}`,
                })) || []
            }
            currentValues={values}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:printerModel.modelName")}*`}
            name="modelName"
            data-cy="modelName"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:printerModel.manufacturerIndex")}`}
            name="manufacturerIndex"
            data-cy="manufacturerIndex"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:printerModel.deviceType")}*`}
            name="deviceType"
            data-cy="deviceType"
            options={
              deviceTypeSelect
                ?.sort((a, b) => a?.entryName?.localeCompare(b?.entryName))
                ?.map((el) => ({
                  uuid: el.uuid,
                  label: `${el.entryName || ""}`,
                })) || []
            }
            currentValues={values}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={optionsSelect}
            selectedValues={values}
            initialValues={{
              options: initialValues?.options?.map((e) => ({
                value: e.uuid,
                name: e.entryName,
              })),
            }}
            multiple
            displayEmpty
            label={`${t("global:printerModel.options")}`}
            name="options"
            renderValue={(el) => {
              return el.map((e) => e.name).join(", ");
            }}
          />
        </Col>
        {/* <Col xs={4}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:printerModel.packagePrice")}`}
            name="packagePrice"
            data-cy="packagePrice"
            options={contacts?.sort((a, b) => a?.name?.localeCompare(b?.name))}
            currentValues={values}
          />
        </Col> */}
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={[
              { value: 1, name: 1 },
              { value: 2, name: 2 },
              { value: 3, name: 3 },
              { value: 4, name: 4 },
              { value: 5, name: 5 },
              { value: 6, name: 6 },
              { value: 7, name: 7 },
              { value: 8, name: 8 },
              { value: 9, name: 9 },
              { value: 10, name: 10 },
            ]}
            label={`${t("global:printerModel.competenceIndicator")}`}
            name="competenceIndicator"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            multiline={true}
            component={BMXTextField}
            label={`${t("global:printerModel.additionalDescription")}`}
            name="additionalDescription"
            data-cy="additionalDescription"
          />
        </Col>
      </Row>
    </div>
  );
};

export default PrinterModelFormStepOne;
