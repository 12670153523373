import { Icon } from "@mui/material";
import i18n from "../../i18next/i18next";

export const ResetTimeType = {
  month: "MONTH",
  year: "YEAR",
};

export const deliveredAsType = () => {
  const { t } = i18n;
  return {
    EMAIL: {
      icon: <Icon fontSize={"small"}>alternate_email</Icon>,
      name: t("global:secretaryDeliveredAsTypeEmail"),
      value: "EMAIL",
    },
    FAX: {
      icon: <Icon fontSize={"small"}>fax</Icon>,
      name: t("global:secretaryDeliveredAsTypeFax"),
      value: "FAX",
    },
    LETTER: {
      icon: <Icon fontSize={"small"}>email_outlined</Icon>,
      name: t("global:secretaryDeliveredAsTypeLetter"),
      value: "LETTER",
    },
    REGISTERED: {
      icon: <Icon fontSize={"small"}>email</Icon>,
      name: t("global:secretaryDeliveredAsTypeRegistered"),
      value: "REGISTERED",
    },
    PACKAGE: {
      icon: <Icon fontSize={"small"}>inventory2_outlined</Icon>,
      name: t("global:secretaryDeliveredAsTypePackage"),
      value: "PACKAGE",
    },
    MESSENGER: {
      icon: <Icon fontSize={"small"}>person4</Icon>,
      name: t("global:secretaryDeliveredAsTypeMessenger"),
      value: "MESSENGER",
    },
    OTHER: {
      icon: <Icon fontSize={"small"}>more_horiz</Icon>,
      name: t("global:secretaryDeliveredAsTypeOther"),
      value: "OTHER",
    },
  };
};

export const correspondenceType = () => {
  const { t } = i18n;

  return {
    LETTERS: {
      name: t("global:correspondenceTypeLetters"),
      value: "LETTERS",
      chip: "#8F8B8B",
    },
    INVOICE: {
      name: t("global:correspondenceTypeInvoice"),
      value: "INVOICE",
      chip: "#19aa8d",
    },
    OFFERS: {
      name: t("global:correspondenceTypeOffers"),
      value: "OFFERS",
      chip: "#4c517d",
    },
    ARCHIVE: {
      name: t("global:correspondenceTypeArchive"),
      value: "ARCHIVE",
      chip: "#fff",
    },
    ADVERTISEMENT: {
      name: t("global:correspondenceTypeAdvertisement"),
      value: "ADVERTISEMENT",
      chip: "#f7a13d",
    },
    SPAM: {
      name: t("global:correspondenceTypeSpam"),
      value: "SPAM",
      chip: "#f27171",
    },
    BIN: {
      name: t("global:correspondenceTypeBin"),
      value: "BIN",
      chip: "#eaecd1",
    },
  };
};

export const priorityType = () => {
  const { t } = i18n;

  return {
    IMPORTANT: {
      name: t("global:priorityTypeImportant"),
      value: "IMPORTANT",
      chip: "#485D75",
    },
    NONURGENT: {
      name: t("global:priorityTypeNonUrgent"),
      value: "NONURGENT",
      chip: "#8F8B8B",
    },
    URGENT: {
      name: t("global:priorityTypeUrgent"),
      value: "URGENT",
      chip: "#E86E71",
    },
    TOFORWARD: {
      name: t("global:priorityTypeToForward"),
      value: "TOFORWARD",
      chip: "#42AA8D",
    },
    TOCLARIFY: {
      name: t("global:priorityTypeToClarify"),
      value: "TOCLARIFY",
      chip: "#EE9F3C",
    },
  };
};

export const statusType = () => {
  const { t } = i18n;

  return {
    SENDED: {
      name: t("global:secretaryCorespondence.sended"),
      value: "SENDED",
      chip: "#19aa8d",
    },
    TOSEND: {
      name: t("global:secretaryCorespondence.toSend"),
      value: "TO_SEND",
      chip: "#FFAD33",
    },
    DRAFT: {
      name: t("global:secretaryCorespondence.draftStatus"),
      value: "DRAFT",
      chip: "#FFAD33",
    },
  };
};


export const envelopeSizeList = [
  { name: "DL", value: "DL" },
  { name: "C5", value: "C5" },
];