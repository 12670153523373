import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const OfferCustomerPotenitalFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:offer.basicInformation")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:offer.devicesAmount")}`}
            name="devicesAmount"
            data-cy="devicesAmountModal"
          />
        </Col>
        <Col xs={{ span: 6 }}>
          <Field
            component={BMXTextField}
            label={`${t("global:offer.devicesLocation")}`}
            name="devicesLocation"
            data-cy="devicesLocationModal"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="websiteOpinionModal"
            component={BMXTextField}
            label={`${t("global:offer.websiteOpinion")}`}
            name="websiteOpinion"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="socialMediaModal"
            component={BMXTextField}
            label={`${t("global:offer.socialMedia")}`}
            name="socialMedia"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="emailFormatModal"
            component={BMXTextField}
            label={`${t("global:offer.emailFormat")}`}
            name="emailFormat"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="customerShareCapitalModal"
            component={BMXTextField}
            label={`${t("global:offer.customerShareCapital")}`}
            name="customerShareCapital"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="scopeActivitiesModal"
            component={BMXTextField}
            label={`${t("global:offer.scopeActivities")}`}
            name="scopeActivities"
          />
        </Col>

        <Col xs={6}>
          <Field
            data-cy="industryModal"
            component={BMXTextField}
            label={`${t("global:offer.industry")}`}
            name="industry"
          />
        </Col>
      </Row>
    </div>
  );
};

export default OfferCustomerPotenitalFormStepOne;
