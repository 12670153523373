import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";

const DeviceImplementationDataFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <Row>
        <Col xs={12}>
          <BMXSeparator title={t("global:device.implementationData")} />
          <Row>
            <Col xs={{ span: 12 }}>
              <Field
                name="credentials"
                label={`${t("global:device.credentials")}`}
                component={BMXTextField}
                data-cy="credentials"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }}>
              <Field
                name="settings"
                label={`${t("global:device.settings")}`}
                component={BMXTextField}
                data-cy="settings"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DeviceImplementationDataFormStepOne;
