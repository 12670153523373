import React from "react";

function App() {
  return (
    <>
      <header className="App-header">
        <p>Aplikacja dostępna na platformach z wyświetlaczem od 10 cali.</p>
      </header>
    </>
  );
}

export default App;
