import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectModal } from 'redux-modal';
import BMXButton from '../../../../Components/Buttons/BMXButton/BMXButton';
import BMXModal from '../../../../Components/Modals/BMXModal/BMXModal';
import AttachmentsEditFileForm from '../../Forms/AttachmentsEditFileForm/AttachmentsEditFileForm';

const AttachmentsEditFileModal = (props) => {

  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t('global:attachments.editFileModal'), error: false }
  ]);
  const [isSubmit, setIsSubmit] = useState(false);
  const formName = "AttachmentsEditFileModal";
  const { handleHide, show, title } = props;

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          bmxclass="Success"
          onClick={() => setIsSubmit(true)}
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };


  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={'700px'}
      height={'550px'}
      onClose={handleHide}
      buttons={buttons()}
      steps={steps}
      header={title}
    >
      <AttachmentsEditFileForm
        step={step}
        setStep={setStep}
        formName={formName}
        handleHide={handleHide}
        steps={steps}
        isSubmit={isSubmit}
        setErrorHeader={setErrorHeader}
        {...props} />
    </BMXModal>
  );
};

export default connectModal({ name: 'AttachmentsEditFileModal' })(AttachmentsEditFileModal);
