import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectModal } from 'redux-modal';
import BMXButton from '../../../../Components/Buttons/BMXButton/BMXButton';
import BMXModal from '../../../../Components/Modals/BMXModal/BMXModal';
import SettingsModalForm from '../../../Components/Forms/Admin/Settings/SettingsForm';

const SettingsModal = (props) => {
  const formName = 'SettingsModal';
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t(`global:settings.clientSettings`), error: false },
    { title: t(`global:settings.applicationSettings`), error: false },
  ]);
  const [isSubmit, setIsSubmit] = useState(false);
  const { handleHide, show } = props;

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          onClick={() => setIsSubmit(true)}
          bmxclass="Success"
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={"800px"}
      onClose={handleHide}
      header={props.header}
      buttons={buttons()}
      steps={steps}
    >
      <SettingsModalForm
          steps={steps}
          isSubmit={isSubmit}
          step={step}
          setStep={setStep}
          setErrorHeader={setErrorHeader}
          handleHide={handleHide}
          formName={formName}
          {...props}
      />
      
    </BMXModal>
  );
};

export default connectModal({ name: 'SettingsModal' })(SettingsModal);
