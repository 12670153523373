import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import SwipeableViews from "react-swipeable-views";
import "./ResetPasswordModal.scss";
import { useSwiper } from "../../../../Hooks/SwiperHook";
import ResetPasswordStepOne from "../../Forms/ResetPasswordForm/ResetPasswordSteps/ResetPasswordStepOne";
import ResetPasswordStepTwo from "../../Forms/ResetPasswordForm/ResetPasswordSteps/ResetPasswordStepTwo";
import ResetPasswordStepThree from "../../Forms/ResetPasswordForm/ResetPasswordSteps/ResetPasswordStepThree";
import { useState } from "react";
import { ResetPasswordContext } from "../../../../Contexts/ResetPasswordContext";

const ResetPasswordModal = (props) => {
  const { handleHide, show } = props;

  const [values, setValues] = useState({});

  const [isLoading, setIsLoading] = useState(false); // przemysleć to

  const [step, goNext, goPrev] = useSwiper({ maxPage: 2 });

  const { t } = useTranslation();

  return (
    <BMXCardModal
      right="10px"
      bottom="10px"
      width="500px"
      height="300px"
      header={t("global:resetPassword.header")}
      open={show}
      onClose={handleHide}
      onOutside={false}
    >
      <ResetPasswordContext.Provider
        value={{ values, setValues, step, goNext, goPrev, setIsLoading }}
      >
        <SwipeableViews className="ResetPasswordSwipe" index={step}>
          <ResetPasswordStepOne onClose={handleHide} />
          <ResetPasswordStepTwo onClose={handleHide} />
          <ResetPasswordStepThree onClose={handleHide} />
        </SwipeableViews>
      </ResetPasswordContext.Provider>

      <div className="ButtonAreaResetPassword">
        {step > 0 && (
          <BMXButton bmxclass="secondary" onClick={goPrev}>
            {t("global:resetPassword.prev")}
          </BMXButton>
        )}
        <BMXButton
          disabled={isLoading}
          form="resetPasswordForm"
          bmxclass={isLoading ? "" : "Success"}
          type="Submit"
        >
          {step === 2
            ? t("global:resetPassword.send")
            : t("global:resetPassword.next")}
        </BMXButton>
      </div>
    </BMXCardModal>
  );
};

export default connectModal({ name: "resetPasswordModal" })(ResetPasswordModal);
