import React, { PureComponent } from "react";
import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import "./BMXUserView.scss";
import { GlobalAuthFetch } from "../../Utils/GlobalAuthFetch/GlobalAuthFetch";

class BMXUserView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      urlPicture: null
    }
  }

  loadImage = async () => {
    const { load, user } = this.props;
    if (load && user?.picture) {

      const { payload } = await GlobalAuthFetch({
        path: `/worker/${user?.uuid}/getPicture`,
        addons: { responseType: "blob" },
      });

      if (payload?.status < 300) {
        this.setState({
          urlPicture: URL.createObjectURL(payload?.data)
        })
      }
    }
  };

  componentDidMount() {
    this.loadImage();
  }

  render() {
    const {
      user,
      department,
      line,
      color,
      className = "",
      comma,
      disableNames,
    } = this.props;

    const { urlPicture } = this.state;

    return (
      <div className={`userView ${line} ${className}`}>
        <Avatar
          sx={{ width: 35, height: 35 }}
          className={`${color} background`}
          src={user?.profileImage || urlPicture}
        >{`${user?.firstName?.toUpperCase().slice(0, 1) || ""}${user?.lastName?.toUpperCase().slice(0, 1) || ""
          } ${department?.shortName.slice(0, 2) || ""}`}</Avatar>
        {!disableNames && (
          <>
            <span className="mx-1">{user?.lastName || department?.name}</span>
            <span>{user?.firstName}</span>
            <span>{comma ? "," : ""}</span>
          </>
        )}
      </div>
    );
  }
}
BMXUserView.propTypes = {
  /**
   Props zawiera użytkowników aplikacji 
  */

  user: PropTypes.object,
  /**
   Props zawiera departamenty 
  */

  department: PropTypes.object,
  /**
   Props określający na dole komponentu ma pojawić się linia oddzielająca
  */

  line: PropTypes.string,
  /**
   Props określający kolor tła awatara. W przypadku kiedy użytkownik nie ma zdjęcia wyświetla się kolor primary. W przypadku departamentów kolor zawsze jest secondary
  */

  color: PropTypes.string,
  /**
   Props określający czy po imieniu i nazwisku lub departamencie ma pojawić się przecinek (stosowane w polu typu select, aby oddzielić użytkowników od siebie)
  */

  comma: PropTypes.bool,
};
export default BMXUserView;
