import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./OperationLockFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import OperationLockFormStepOne from "./OperationLockFormSteps/OperationLockFormStepOne";
import { useEffect, useState } from "react";

const OperationLockForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        reason: _initialValues?.reason?.uuid
          ? { value: _initialValues?.reason?.uuid }
          : null,
        client: _initialValues?.client?.uuid
          ? {
              ..._initialValues?.client,
              label: _initialValues?.client?.name,
              uuid: _initialValues?.client?.uuid,
            }
          : null,
      });
      return;
    }

    setInitialValues({});
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="OperationLockForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <OperationLockFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default OperationLockForm;
