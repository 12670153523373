import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./BMXPortal.scss";
import { createRef } from "react";

const modal = document.getElementById("root-modal");
class BMXPortal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.reff = createRef();
    this.state = {
      fixBottom: 0,
      fixRight: 0,
      observer: new ResizeObserver(() => {
        const bottomFixSize =
          this.reff.current.getBoundingClientRect().bottom - window.innerHeight;
        const rightFixSize =
          this.reff.current.getBoundingClientRect().right - window.innerWidth;
        this.setState({
          fixBottom: bottomFixSize > 0 ? bottomFixSize + 10 : null,
          fixRight: rightFixSize > 0 ? rightFixSize + 10 : null,
        });
      }),
    };
  }

  componentDidMount() {
    modal.appendChild(this.el);
    const { observer } = this.state;
    observer.observe(this.reff.current);

    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    const { observer } = this.state;
    observer.unobserve(this.reff.current);
    modal.removeChild(this.el);
    document.removeEventListener("click", this.handleClickOutside, true);
    this.el.remove();
  }

  handleClickOutside = (event) => {
    const { onClose } = this.props;
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      onClose();
    }
  };

  render() {
    const { children, left, top, right, bottom } = this.props;
    const { fixRight, fixBottom } = this.state;
    const style =
      top || left || right || bottom
        ? {
            top: top - fixBottom,
            left: left - fixRight,
            right: right,
            bottom: bottom,
          }
        : { transform: "translate(-50%,-50%)", top: "50%", left: "50%" };
    return ReactDOM.createPortal(
      <div className="BMXPortal" ref={this.reff} style={style}>
        {children}
      </div>,
      this.el
    );
  }
}

BMXPortal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  top: PropTypes.node,
  right: PropTypes.node,
  bottom: PropTypes.node,
  left: PropTypes.node,
};

export default BMXPortal;
