import { NotificationManager } from "react-notifications";
import { t } from "i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep } from "lodash";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";

export const onSubmit = async (values, props) => {
  const {
    updateSingleRegistry,
    loadData,
    pathUuid,
    handleHide,
    setErrorHeader,
    update,
    match,
  } = props;
  const data = cloneDeep(values);

  let payload;

  if (!update) {
    const tmp = match?.url.split("/");
    const regUrl = tmp[tmp.length - 2];
    const res = await GlobalAuthFetch({
      path: `/${regUrl}/addNotes/${pathUuid}`,
      method: FetchMethodType.patch,
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/notes`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    setErrorHeader(0, true);
    return;
  }

  setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry(pathUuid);
  handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  if (!values.content) {
    errorsStep0.content = t("global:note.errors.content");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }

  return { ...errorsStep0 };
};
