import React, { useContext } from 'react';
import './../ResetPasswordForm.scss';
import { Field, Form } from 'react-final-form';
import { submit, validate } from "./../ResetPasswordFormSubmit"
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BMXTextField from '../../../../../Components/Inputs/BMXTextField/BMXTextField';
import { ResetPasswordContext } from '../../../../../Contexts/ResetPasswordContext';

const ResetPasswordStepThree = (props) => {
    const { t } = useTranslation();
    const context = useContext(ResetPasswordContext);
    const { values, step } = context;

    return <Form
        initialValues={values}
        onSubmit={(val) => submit(val, { ...props, ...context })}
        validate={(val) => validate(val, { ...props, ...context })}
    >{({ handleSubmit }) => (
        <form id={`${step === 2 ? 'resetPasswordForm' : ''}`} onSubmit={handleSubmit} className="ResetPasswordForm">
             <Row>
                <Col>
                    <Field name="login" type="text" component={BMXTextField} label={`${t("global:resetPassword.login")}`} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name="password" type="password" component={BMXTextField} label={`${t("global:resetPassword.password")}`} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name="repeatPassword" type="password" component={BMXTextField} label={`${t("global:resetPassword.repeatPassword")}`} />
                </Col>
            </Row>
        </form>
    )}</Form>
}

export default ResetPasswordStepThree;

