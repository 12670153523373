import {
  Autocomplete,
  TextField,
  FormHelperText,
  IconButton,
  Icon,
  Tooltip,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import "./BMXAsynchronous.scss";
import { get, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function BMXAsynchronous(props) {
  const {
    meta: { touched, error, submitError },
    input,
    currentValues,
    getData,
    afterIcon,
    afterIconSize,
    afterIconClick,
    disabled,
    afterTooltipText,
    afterIconClass,
  } = props;

  const { t } = useTranslation();

  const prevCurrentValues = useRef();

  const [defaultVal, setDefaultVal] = useState(null);

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (
      !isEqual(
        get(currentValues, input?.name),
        get(prevCurrentValues, input?.name)
      )
    ) {
      setDefaultVal(get(currentValues, input?.name));
    }
    prevCurrentValues.current = currentValues;
  });

  const isError = () => {
    return !!((error || submitError) && touched);
  };

  return (
    <>
      <Autocomplete
        {...props}
        name={input?.name}
        value={defaultVal}
        data-cy="bmx-asynchronous-select"
        className="BMXAsynchronous"
        onInputChange={onInputChange}
        onChange={(event, option) => input.onChange(option)}
        isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
        // getOptionLabel={(option) => {
        //   if (option.value) return option.value;
        //   return option;
        // }}
        renderInput={(params) => (
          <div className="InputArea">
            <TextField
              variant="standard"
              {...params}
              {...props}
              {...input}
              error={isError()}
            />
            {isError() && (
              <FormHelperText>
                <span className="error text">{error || submitError}</span>
              </FormHelperText>
            )}
            {afterIcon && (
              <Tooltip
                title={afterTooltipText ? t(`${afterTooltipText}`) : null}
              >
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={afterIconClick}
                  disableRipple={!afterIconClick}
                >
                  <Icon
                    className={`${afterIconClass}`}
                    style={{ fontSize: afterIconSize }}
                  >
                    {afterIcon}
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      />
    </>
  );
}

BMXAsynchronous.propTypes = {
  getData: PropTypes.func.isRequired,
  afterTooltipText: PropTypes.string,
};
