import {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectModal } from 'redux-modal';
import BMXButton from '../../../../Components/Buttons/BMXButton/BMXButton';
import BMXModal from '../../../../Components/Modals/BMXModal/BMXModal';
import AttachmentsFromTemplateForm from '../../Forms/AttachmentsFromTemplateForm/AttachmentsFromTemplateForm';

const AttachmentsFromTemplateModal = (props) => {
  const formName = 'AttachmentsFromTemplateModal';
  const [step, setStep] = useState(0);
  const { handleHide, show } = props;
  const { t } = useTranslation();

  const [steps, setSteps] = useState([
    { title: t('global:attachments.addTemplateHeader'), error: false },
  ]);

  const [isSubmit, setIsSubmit] = useState(false);

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

 

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          bmxclass="Success"
          form={formName}
          onClick={() => setIsSubmit(true)}
        >
          {t('global:send')}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={'450px'}
      height={'300px'}
      onClose={handleHide}
      header={t('global:attachments.addTemplate')}
      buttons={buttons()}
      steps={steps}
    >
      <AttachmentsFromTemplateForm
      isSubmit={isSubmit} 
      step={step} 
      steps={steps}
      setErrorHeader={setErrorHeader}
      setStep={setStep} 
      formName={formName} 
      handleHide={handleHide} 
      {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: 'AttachmentsFromTemplateModal' })(AttachmentsFromTemplateModal);
