import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      {
        key: "deviceSupplies.device.client.name+device.client.city+device.client.street+device.client.houseNumber+device.client.apartmentNumber",
        name: "global:suppliesmanagement.device.clientName",
      },
      {
        key: "deviceSupplies.device.serialNumber",
        name: "global:suppliesmanagement.sn",
      },
    ],
    selectedSearchFields: [
      "deviceSupplies.device.serialNumber",
      "deviceSupplies.device.client.name+device.client.city+device.client.street+device.client.houseNumber+device.client.apartmentNumber",
    ],
    dictionaryFields: [],
    url: "releasesupplies",
  },
};

const releaseSuppliesReducer = createSlice({
  name: "releaseSuppliesReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = releaseSuppliesReducer.actions;

export default releaseSuppliesReducer.reducer;
