import axios from "axios";
import { env } from "../../env";
import Cookies from "js-cookie";

let acctualRequests = [];

export default class BMXFetch {
  constructor(path, { method = "GET", addons = {} } = {}) {
    this.url = env.REACT_APP_API_URL;
    this.path = path;
    this.method = method;
    this.addons = addons;
  }

  setHeaders(headers) {
    this.addons = {
      ...this.addons,
      headers: { ...this?.addons?.headers, ...headers },
    };
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  async send() {
    if (
      acctualRequests.every((el) => el !== this.path) ||
      this.path.includes(Cookies.get("BMX_ID")) ||
      this.path.includes("auth") ||
      this.path.includes("getByUrl") ||
      this.path.includes("settings")
    ) {
      acctualRequests.push(this.path);
      const data = await axios({
        url: `${this.url}${this.path}`,
        method: this.method,
        data: this.body,
        ...this.addons,
      }).catch((err) => {
        acctualRequests = acctualRequests.filter((el) => el !== this.path);
        throw err;
      });
      acctualRequests = acctualRequests.filter((el) => el !== this.path);
      return data;
    }
    return;
  }
}
