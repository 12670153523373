import { connectModal } from "redux-modal";
import React, { forwardRef } from "react";

import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
} from "@mui/material";
import "./FullScreenDialog.scss";

const FullScreenDialog = (props) => {
  const { handleHide, show, content } = props;

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={handleHide}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", bgcolor: "#19aa8d" }}>
        <Toolbar sx={{ alignSelf: "flex-end" }}>
          <IconButton onClick={handleHide} size="small">
            <Icon style={{ color: "white" }} fontSize={"small"}>
              close
            </Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="mainContent">{content}</div>
    </Dialog>
  );
};

export default connectModal({ name: "FullScreenDialog" })(FullScreenDialog);
