import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXConfirmModal from "../../../../Components/Modals/BMXConfirmModal/BMXConfirmModal";
import ConfirmWarningModal from "../../ConfirmModalsForms/ConfirmWarningModal/ConfirmWarningModal";

const ConfirmModal = (props) => {
  const { handleHide, show, confirmButtonName } = props;
  const formName = "ConfirmForm";
  const { t } = useTranslation();

  const buttons = () => {
    return (
      <>
        <BMXButton
          data-cy="submit"
          type="subbmit"
          className="secondary background"
          form={formName}
        >
          {confirmButtonName ? t(`${confirmButtonName}`) : t("global:delete")}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXConfirmModal
      {...props}
      open={show}
      width={"500px"}
      height={"250px"}
      onClose={handleHide}
      buttons={buttons()}
    >
      <ConfirmWarningModal
        {...props}
        formName={formName}
        handleHide={handleHide}
      />
    </BMXConfirmModal>
  );
};

export default connectModal({ name: "ConfirmModal" })(ConfirmModal);
