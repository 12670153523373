import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep } from "lodash";

export const onSubmit = async (values, props) => {
  const {
    updateSingleRegistry,
    uuid,
    match: { url },
    edit,
    setNewFolders,
    change
  } = props;

  const tmp = url.split("/");
  const regUrl = tmp[tmp.length - 2];

  const data = cloneDeep(values);

  data.description = data.description || "";

  let payload;

  if (!edit) {
    const res = await GlobalAuthFetch({
      path: `/${regUrl}/addFolder/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (edit) {
    const res = await GlobalAuthFetch({
      path: `/attachments/folderUpdate`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return;
  }

  props.setErrorHeader(0, false);
  //Succes nofify
  props.loadData();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
  updateSingleRegistry();

  if(Boolean(setNewFolders)){
    setNewFolders(payload?.data?.folders);
  }
  const newFolder = payload?.data?.folders?.find(el=>el.name===data.name);
  if(Boolean(change) && Boolean(newFolder))
    change("selectFolder",{value:newFolder?.uuid});
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.name) {
    errorsStep0.name = i18n.t("global:attachmentsFolderNameError");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return errorsStep0;
};
