import React, { useContext } from 'react';
import './../ResetPasswordForm.scss';
import { Field, Form } from 'react-final-form';
import { submit, validate } from "./../ResetPasswordFormSubmit"
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BMXTextField from '../../../../../Components/Inputs/BMXTextField/BMXTextField';
import { ResetPasswordContext } from '../../../../../Contexts/ResetPasswordContext';


const ResetPasswordStepOne = (props) => {
    const { t } = useTranslation();
    const context = useContext(ResetPasswordContext);
    const { values, step } = context;

    return <Form
        initialValues={values}
        onSubmit={(val) => submit(val, { ...props, ...context })}
        validate={(val) => validate(val, { ...props, ...context })}
    >{({ handleSubmit }) => (
        <form id={`${step === 0 ? 'resetPasswordForm' : ''}`} onSubmit={handleSubmit} className="ResetPasswordForm">
            <Row>
                <Col>
                    <Field name="email" component={BMXTextField} label={`${t("global:resetPassword.email")}`} />
                </Col>
            </Row>
            <Row>
                <Col className='my-3' xs={8}>
                    <span className="secondary text">{`${t("global:resetPassword.emailInformation")}`}</span>
                </Col>
            </Row>
        </form>
    )}</Form>
}

export default ResetPasswordStepOne;

