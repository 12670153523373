import React from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./SecretaryCorspondencyTransferFormSubmit";
import { Col, Row } from "react-bootstrap";
import './SecretaryCorspondencyTransferForm.scss'
import BMXSelectField from "../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";
import { useState } from "react";


const SecretaryCorspondencyTransferForm = (props) => {
    const { initialValues, formName } = props;
    const { t } = useTranslation();
    const [workers, setWorkers] = useState([]);
    const loadData = async () => {
        const { payload } = await GlobalAuthFetch({
            path: '/worker/all',
            method: FetchMethodType.get,
        })
        if (payload) {
            setWorkers(payload.data);
        }
    }
    useEffect(() => {
        loadData();
    }, [])
    return (
        <Form
            initialValues={{
                ...initialValues,
            }}
            onSubmit={(val) => submit(val, props)}
            validate={validate}
        >
            {({ handleSubmit }) => (
                <form id={formName} onSubmit={handleSubmit} className="SecretaryCorspondencyTransferForm">
                    <Row >

                        <Col style={{ marginTop: '1rem' }} xs={12}>


                            <Field
                            data-cy="transferTo"
                                beforeIcon="people"
                                component={BMXSelectField}
                                values={workers.map(e => ({
                                    value: e.uuid,
                                    name: `${e.firstName} ${e.lastName}`
                                }))}
                                label={`${t("global:secretariatCorespondenceContact.contact")}`}
                                name="transferTo"
                            />

                        </Col>


                    </Row>



                </form>
            )}
        </Form>
    );
};

export default SecretaryCorspondencyTransferForm;
