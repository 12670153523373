import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: undefined,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [{ key: "name", name: "global:roles.name" }],
    selectedSearchFields: ["name"],
  },
};

const roleReducer = createSlice({
  name: "roleReducer",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRole: () => initialState,
  },
  extraReducers: {},
});

export const { updateRegistry, clearRole } = roleReducer.actions;

export default roleReducer.reducer;
