import { withTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import warning from "./../../../../../src/assets/img/global/confirmModal/warning.svg";
import { compose } from "redux";
import React, { PureComponent } from "react";
import "./ConfirmInvoiceModalForm.scss";
import { Form } from "react-final-form";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXDatePicker from "../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import moment from "moment";

class ConfirmInvoiceModalForm extends PureComponent {
  static defaultProps = {};

  render() {
    const { t, onSubmit, formName, mainText, contentText, initialValues } =
      this.props;

    return (
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        {(formProps) => (
          <form
            onSubmit={formProps.handleSubmit}
            className="ConfirmInvoiceModalForm"
            id={formName}
          >
            <Row>
              <Col className="d-flex justify-content-center mt-5" md={12}>
                <img className="img" src={warning} alt="Warning logo" />
              </Col>
            </Row>
            <Row>
              <Col className="title mt-3A mt-2" md={12}>
                {t(mainText)}
              </Col>
            </Row>
            <Row>
              <Col className="content mb-2" md={12}>
                {t(contentText)}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={6}>
                <Field
                  name="invoiceDateField"
                  label={`${t("global:reckoning.invoiceDateField")}`}
                  component={BMXDatePicker}
                  data-cy="invoiceDateField"
                />
              </Col>
              <Col xs={6}>
                <Field
                  name="invoiceNumber"
                  label={`${t("global:reckoning.invoiceNumber")}`}
                  component={BMXTextField}
                  data-cy="invoiceNumber"
                />
              </Col>
            </Row>

            <Row className="invoiceFrom">
              <Col xs={12}>
                {initialValues?.invoiceUpdatedBy ? (
                  <span>
                    {`${t("global:reckoning.invoiceFrom")} ${
                      initialValues?.invoiceUpdatedBy?.firstName
                    } ${initialValues?.invoiceUpdatedBy?.lastName}`}
                    {initialValues?.invoiceModifyDate
                      ? `, ${moment(initialValues?.invoiceModifyDate).format(
                          "DD-MM-YYYY HH:mm"
                        )}`
                      : ""}
                  </span>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </form>
        )}
      </Form>
    );
  }
}

ConfirmInvoiceModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  mainText: PropTypes.string,
  contentText: PropTypes.string,
};

ConfirmInvoiceModalForm.defaultProps = {
  onSubmit: () => {},
  mainText: "global:confirmModal.delete",
  contentText: "global:confirmModal.confirmDeleteText",
};

export default compose(withTranslation())(ConfirmInvoiceModalForm);
