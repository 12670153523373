import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { compose } from "redux";
import { AuthFetch } from "../../Redux/Reducers/AuthFetchReducer";
import { setUser } from "../../Redux/Reducers/UserSession";
import Cookies from "js-cookie";
import { intersection } from "lodash";
import { PERMISSIONS } from "../Permissions";

class AuthRoute extends Component {
  async componentDidMount() {
    const { AuthFetch, user, setUser } = this.props;
    const uuid = Cookies.get("BMX_ID");

    if (Object.keys(user).length > 0 || uuid) {
      const { payload } = await AuthFetch({
        path: `/worker/${user.uuid || uuid}`,
      });
      let data = payload?.data;
      if (data?.picture) {
        const pictureData = await AuthFetch({
          path: `/worker/${user.uuid || uuid}/getPicture`,
          method: "GET",
          addons: { responseType: "blob" },
        });
        if (pictureData?.payload?.data) {
          const blob = new Blob([pictureData?.payload?.data]);
          data = { ...data, profileImage: URL.createObjectURL(blob) };
        }
      }
      setUser(data);
    }
  }

  render() {
    const {
      auth: { auth },
    } = this.props;
    return auth ? <Route {...this.props} /> : <Redirect to={"/login"} />;
  }
}

export default compose(
  connect(
    (state) => ({
      auth: state.auth,
      user: state.userSession.user,
    }),
    (dispatch) => ({
      AuthFetch: (data) => dispatch(AuthFetch(data)),
      setUser: (user) => dispatch(setUser(user)),
    })
  )
)(AuthRoute);

export const shouldRouteRender = (item, user) => {
  if (user.admin || user.superAdmin) {
    return true;
  }
  const routeRights = item?.rights?.map((el) => el.name);
  const userRights = user?.roles?.flatMap((el) =>
    el?.rights.map((el) => el.name)
  );
  if (!routeRights || routeRights?.length <= 0) {
    return true;
  }

  const intersectionRights = intersection(routeRights, userRights).join(" ");

  if(intersectionRights.includes("SECRETARY_READ")){
    return true;
  }

  return (
    intersectionRights.includes(PERMISSIONS.read) ||
    (intersectionRights.includes(PERMISSIONS.delete) ||
      intersectionRights.includes(PERMISSIONS.update) ||
      intersectionRights.includes(PERMISSIONS.create))
  );
};
