import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./BMXTimePicker.scss";

const BMXTimePicker = (props) => {
  const {
    input,
    meta: { error, submitError },
    ...rest
  } = props;

  const isError = () => {
    const {
      meta: { touched, error, submitError },
    } = props;
    return Boolean((touched && error) || submitError);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        ampm={false}
        {...rest}
        {...input}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            onKeyDown={onKeyDown}
            className="BMXTimePicker"
            type="date"
            id="standard-basic"
            variant="standard"
            error={isError()}
            helperText={isError() ? error || submitError : ""}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default BMXTimePicker;
