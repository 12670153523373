import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./AddAttachmentsFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import AddAttachmentsFormStepOne from "./AddAttachmentsFormSteps/AddAttachmentsFormStepOne";
import AddAttachmentsFormStepTwo from "./AddAttachmentsFormSteps/AddAttachmentsFormStepTwo";
import "./AddAttachmentsForm.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Attachments } from "../../../../Utils/GlobalVariables/Statuses/GlobalStatuses";

const AddAttachmentsForm = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { step, setStep, selectedFolder, folders, defaultFileStatus } = props;
  const [initialValues, setInitialValues] = useState({ selectedFile: [] });
  const [uploadProgress, setUploadProgress] = useState(0);
  const attachments = Attachments(t);

  useEffect(() => {
    setInitialValues({
      selectFolder: folders?.find((el) => selectedFolder?.uuid === el.uuid),
      status: { value: defaultFileStatus || attachments.active.value },
    });
    // eslint-disable-next-line
  }, [folders, selectedFolder, defaultFileStatus, t]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, { ...props, setUploadProgress })}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => (
        <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="AddAttachmentsForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AddAttachmentsFormStepOne
              {...formProps}
              {...props}
              uploadProgress={uploadProgress}
            />
            <AddAttachmentsFormStepTwo {...formProps} {...props} />
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AddAttachmentsForm;
