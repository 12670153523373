import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";

const ChangePasswordFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col>
          <BMXSeparator title={t("global:settings.companyInformation")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="companyName"
            name="COMPANY_NAME.value"
            component={BMXTextField}
            label={t("global:settings.companyName")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="companyAddress"
            name="COMPANY_ADDRESS.value"
            component={BMXTextField}
            label={t("global:settings.companyAddress")}
          />
        </Col>
        <Col>
          <Field
            data-cy="companyPostCode"
            name="COMPANY_POST_CODE.value"
            component={BMXTextField}
            label={t("global:settings.companyPostCode")}
          />
        </Col>
        <Col>
          <Field
            data-cy="companyCity"
            name="COMPANY_CITY.value"
            component={BMXTextField}
            label={t("global:settings.companyCity")}
          />
        </Col>
        <Col>
          <Field
            data-cy="companyEmail"
            name="COMPANY_EMAIL.value"
            component={BMXTextField}
            label={t("global:settings.companyEmail")}
          />
        </Col>
        <Col>
          <Field
            data-cy="companyPhone"
            name="COMPANY_PHONE.value"
            component={BMXTextField}
            label={t("global:settings.companyPhone")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field
            data-cy="clientAdministratorEmail"
            name="CLIENT_ADMINISTRATOR_EMAIL.value"
            component={BMXTextField}
            label={t("global:settings.clientAdministratorEmail")}
          />
        </Col>
        <Col md={4}>
          <Field
            data-cy="websiteAddress"
            name="WEBSITE.value"
            component={BMXTextField}
            label={t("global:settings.websiteAddress")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BMXSeparator title={t("global:settings.emailData")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="secretaryEmail"
            name="SECRETARIAT_EMAIL.value"
            component={BMXTextField}
            label={t("global:settings.secretaryEmail")}
          />
        </Col>
        <Col>
          <Field
            data-cy="smtpSerwer"
            name="SMTP_SERVER.value"
            component={BMXTextField}
            label={t("global:settings.smtpSerwer")}
          />
        </Col>
        <Col>
          <Field
            data-cy="smtpPort"
            name="SMTP_PORT.value"
            component={BMXTextField}
            label={t("global:settings.smtpPort")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field
            data-cy="authenticationName"
            name="SMTP_LOGIN.value"
            component={BMXTextField}
            label={t("global:settings.authenticationName")}
          />
        </Col>
        <Col md={4}>
          <Field
            data-cy="AUTHENTICATION_PASSWORD"
            name="SMTP_PASS.value"
            type="password"
            component={BMXTextField}
            label={t("global:settings.AUTHENTICATION_PASSWORD")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChangePasswordFormStepOne;
