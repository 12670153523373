import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      {
        key: "createdBy.firstName+createdBy.lastName",
        name: "global:secretaryNotes.firstNameLastName",
      },
      {
        key: "content",
        name: "global:secretaryNotes:content",
      },
    ],
    selectedSearchFields: ["createdBy.firstName+lastName", "content"],
  },
};

const secretaryNote = createSlice({
  name: "secretaryNote",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRole: () => initialState,
  },
  extraReducers: {},
});

export const { updateRegistry, clearRole } = secretaryNote.actions;

export default secretaryNote.reducer;
