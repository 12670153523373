import { Tooltip } from "@material-ui/core";
import { Icon } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const GlobalAlertFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div className="GlobalAlertFormStepOne pb-5">
      <Row>
        <Col>
          <BMXSeparator title={t("global:globalAlert.addNewGlobalAlert")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            fieldtype="checkbox"
            component={BMXTextField}
            name="active"
            label={t("global:globalAlert.isActive")}
          />
          <Field            
            component={BMXTextField}
            name="text"
            label={t("global:globalAlert.text")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BMXSeparator title={t("global:globalAlert.notifiOptions")} />
        </Col>
      </Row>
      <Row>
        <Col>
         <div className="NotificationDayTooltip d-flex grey text" >
             <Icon>notifications</Icon>
             <span>{t("global:globalAlert.notifiactionDay")}</span>
             <Tooltip title={t("global:globalAlert.tooltipText")}>
                <Icon className="mx-2">question_mark</Icon>
             </Tooltip>
         </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Field            
            component={BMXTextField}
            name="days"
            type="phone"
            label={t("global:globalAlert.amount")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GlobalAlertFormStepOne;
