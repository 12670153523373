import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./StorageEditFileFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import StorageEditFileStepOne from "./StorageEditFileFormSteps/StorageEditFileFormStepOne";
import { useEffect, useState } from "react";

const StorageEditFileForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues( props.initialValues);
      return;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="StorageEditFileForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <StorageEditFileStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default StorageEditFileForm;
