import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import ChangeImageForm from "../../Forms/ChangeImageForm/ChangeImageForm";

const ChangeImageModal = (props) => {
  const { t } = useTranslation();
  const formName = "ChangeImageForm";
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:editPhoto"), error: false },
  ]);
  const { handleHide, show, onSubmit, onValidate, circularCrop, header } =
    props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          bmxclass="Success"
          disabled={disableSubmit}
          form={formName}
          onClick={() => setIsSubmit(true)}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={"600px"}
      onClose={handleHide}
      header={header || t("global:profile.editProfile")}
      buttons={buttons()}
      steps={steps}
    >
      <ChangeImageForm
        setErrorHeader={setErrorHeader}
        isSubmit={isSubmit}
        step={step}
        setStep={setStep}
        formName={formName}
        handleHide={handleHide}
        setDisableSubmit={setDisableSubmit}
        circularCrop={circularCrop}
        onValidate={onValidate}
        onSubmit={onSubmit}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "ChangeImageModal" })(ChangeImageModal);

ChangeImageModal.defaultProps = {
  circularCrop: true,
  onSubmit: () => {},
  onValidate: () => {},
};
