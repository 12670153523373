import React from "react";
import { Field, Form } from "react-final-form";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXButton from "../../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { t } from "i18next";
import BMXColorPicker from "../../../../../Components/Inputs/BMXColorPicker/BMXColorPicker";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { submit, validate } from "./DictionaryValueFormSubmit";
import { useEffect } from "react";

const DictionaryValueForm = (props) => {
  const { initialValues } = props;

  const checkIsFont =
    initialValues?.background || initialValues?.font ? true : false;

  const [checked, setCheck] = useState(false);

  useEffect(() => {
    setCheck(checkIsFont);
  }, [checkIsFont]);

  return (
    <Form
      initialValues={{
        ...initialValues,
      }}
      onSubmit={(val) => submit(val, { ...props, checked })}
      validate={(val) => validate(val, props)}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="DictionaryValueForm">
          <Row>
            <Col xs={7}>
              <Field
                name="entryName"
                component={BMXTextField}
                label={t("global:dictionary.entryName")}
              />
            </Col>
            <Col xs={{ span: 4 }}>
              <FormControlLabel
                className="BMXCheckbox"
                control={
                  <Checkbox
                    color="default"
                    value={checked}
                    checked={checked}
                    onClick={() => setCheck(!checked)}
                  />
                }
                label={t("global:dictionary.chip")}
              />
            </Col>
          </Row>
          {checked && (
            <Row>
              <Col xs={6}>
                <Field
                  className="mt-3"
                  name="background"
                  label={t("global:dictionary.background")}
                  component={BMXColorPicker}
                />
              </Col>
              <Col xs={6}>
                <Field
                  className="mt-3"
                  name="font"
                  label={t("global:dictionary.font")}
                  component={BMXColorPicker}
                />
              </Col>
            </Row>
          )}
          <Row className=" d-flex justify-content-end">
            <Col className="mt-3 d-flex justify-content-end">
              <BMXButton
                bmxclass="Success"
                type="Submit"
                onClick={handleSubmit}
              >
                {t("global:save")}
              </BMXButton>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default DictionaryValueForm;
