import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TripleLine.scss';
class TripleLine extends Component {
  render() {
    const { firstLine, secondLine, thridLine, style } = this.props
    return (
      <div className="TripleLine" style={style}>
        <span>{firstLine}</span>
        <span className="grey text">{secondLine}</span>
        <span className="grey text">{thridLine}</span>
      </div>
    );
  }
}
TripleLine.propTypes = {
  /**
 pierwsaza linia tekstu
*/
  /**
 Druga linia tekstu
*/

  firstLine: PropTypes.string,

  secondLine: PropTypes.string,
  /**
Trzecia linia tekstu
*/
  thridLine: PropTypes.string,
};

export default TripleLine;
