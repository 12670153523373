import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { offerReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  // if (values?.contact?.value) set(data, "contact.uuid", values?.contact?.value);

  if (values?.representative?.value)
    set(data, "representative.uuid", values?.representative?.value);

  if (values?.offerType?.value)
    set(data, "offerType.uuid", values?.offerType?.value);

  if (values?.customerType?.value)
    set(data, "customerType.uuid", values?.customerType?.value);

  let payload;

  if (!update) {
    data.status = offerReducer?.availableStatuses.find((el) => !!el.isSelected);

    const res = await GlobalAuthFetch({
      path: `/offer`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/offer/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.title) {
    errorsStep0.title = t("global:offer.errors.title");
  }

  if (!values.contact) {
    errorsStep0.contact = t("global:offer.errors.contact");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
