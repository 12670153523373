export const Attachments = (t) => {
  if (t) {
    return {
      active: { value: "ACTIVE", name: t("global:attachments.status.active") },
      archive: {
        value: "ARCHIVE",
        name: t("global:attachments.status.archive"),
      },
      draft: { value: "DRAFT", name: t("global:attachments.status.draft") },
      secret: { value: "SECRET", name: t("global:attachments.status.secret") },
    };
  } else {
    throw new Error("Translation object doesn't exist");
  }
};

export const microsoftType = ["doc", "docx", "xlsx", "xls", "pptx", "ppt"];
