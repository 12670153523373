import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import "./SuppliesOtherKitModal.scss";
const SuppliesOtherKitModal = (props) => {
  const { handleHide, show, clientX, clientY, data } = props;
  const { t } = useTranslation();
  const {
    blackToner,
    magentaToner,
    yellowToner,
    cyanToner,
    wasteToner,
    blackCylinder,
    magentaCylinder,
    yellowCylinder,
    cyanCylinder,
    fuser,
    maintenanceKit,
  } = data;

  return (
    <BMXCardModal
      className="SuppliesOtherKitModal"
      left={clientX - 350}
      top={clientY}
      width="350px"
      height="420px"
      open={show}
      onClose={handleHide}
      onOutside={true}
    >
      <ul className="list">
        <li>
          <span>
            <b> {`${t("global:suppliesManagement.blackToner")}: `}</b>
            {blackToner ? `${blackToner.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.magentaToner")}: `}</b>
            {magentaToner ? `${magentaToner.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.yellowToner")}: `}</b>
            {yellowToner ? `${yellowToner.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.cyanToner")}: `}</b>
            {cyanToner ? `${cyanToner.percentRemaining}%` : "-"}
          </span>
        </li>
        <li className="mt-4">
          <span>
            <b>{`${t("global:suppliesManagement.WasteTonerBottle")}: `}</b>
            {wasteToner ? `${wasteToner.percentRemaining}%` : "-"}
          </span>
        </li>
        <li className="mt-4">
          <span>
            <b>{`${t("global:suppliesManagement.blackDrumUnit")}: `}</b>
            {blackCylinder ? `${blackCylinder.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.magentaDrumUnit")}: `}</b>
            {magentaCylinder ? `${magentaCylinder.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.yellowDrumUnit")}: `}</b>
            {yellowCylinder ? `${yellowCylinder.percentRemaining}%` : "-"}
          </span>
        </li>
        <li>
          <span>
            <b>{`${t("global:suppliesManagement.cyanDrumUnit")}: `}</b>
            {cyanCylinder ? `${cyanCylinder.percentRemaining}%` : "-"}
          </span>
        </li>
        <li className="mt-4">
          <span>
            <b>{`${t("global:suppliesManagement.Fuser")}: `}</b>
            {fuser ? `${fuser.percentRemaining}%` : "-"}
          </span>
        </li>
        <li className="mt-4">
          <span>
            <b>{`${t("global:suppliesManagement.Kit")}: `}</b>
            {maintenanceKit ? `${maintenanceKit.percentRemaining}%` : "-"}
          </span>
        </li>
      </ul>
    </BMXCardModal>
  );
};

export default connectModal({ name: "suppliesOtherKitModal" })(
  SuppliesOtherKitModal
);

SuppliesOtherKitModal.defaultProps = {
  data: {},
};
