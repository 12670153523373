import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";
import i18n from "../../../../../../Utils/i18next/i18next";

const TranslationFormStepOne = (props) => {
  const { t } = useTranslation();
  const {store:{data}}=i18n.toJSON();
  
  return (
    <div>
      <Row>
        <Col>         
          <BMXSeparator title={t('global:translation.addTranslation')} />
        </Col>     
      </Row>     
      <Row>
        <Col> 
          <Field            
            name="key"
            component={BMXTextField}
            label={t('global:translation.key')}
            />        
        </Col>
        {Object.keys(data).map((el,idx)=>
        <Col key={"key-"+idx}>         
          <Field            
            name={el}
            component={BMXTextField}
            label={el}
            />
        </Col>)}       
      </Row>     
    </div>
  );
};

export default TranslationFormStepOne;
