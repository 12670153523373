import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const WorkerStaffFormStepTwo = () => {
    const { t } = useTranslation();

    return (
        <div style={{ justifyContent: 'flex-start' }}>
            <BMXSeparator title={t("global:worker.staff.additionalInfo")} />
            <Row>
                <Col>
                    <Field
                        multiline
                        minRows={2}
                        maxRows={6}
                        component={BMXTextField}
                        label={`${t('global:worker.staff.additionalRequirement')}`}
                        name="additionalRequirement"
                        data-cy="additionalRequirement"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        multiline
                        minRows={2}
                        maxRows={6}
                        component={BMXTextField}
                        label={`${t('global:worker.staff.comment')}`}
                        name="comment"
                        data-cy="comment"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default WorkerStaffFormStepTwo;
