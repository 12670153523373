import { Icon } from "@mui/material";
import { withTranslation } from "react-i18next";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import "./ChangeImageFormStepOne.scss";
import ReactCrop from "react-image-crop";
import { compose } from "redux";
import React, { PureComponent } from "react";

class ChangeImageFormStepOne extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      crop: {
        unit: "%",
        width: 30,
        height: 30,
      },
    };
  }

  componentDidUpdate(nextProps, nextState) {
    const { setDisableSubmit } = this.props;
    const { src } = this.state;
    if (src !== nextState.src) {
      setDisableSubmit(!!nextState.src);
    }
  }

  onSelectFile = (e) => {
    if (e.files && e.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    const { setCroppedImg } = this.props;
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );
      setCroppedImg({ croppedImage });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          this.fileUrl = blob;
          resolve(this.fileUrl);
        },
        "image/png",
        1
      );
    });
  }

  render() {
    const { t, circularCrop } = this.props;
    const { src, crop } = this.state;

    return (
      <div className="ChangeImageFormStepOne h-100">
        <BMXSeparator title={t("global:profile.insertPhoto")} />
        <label
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            this.onSelectFile(e?.dataTransfer);
          }}
          htmlFor="fileInput"
          className="PictureArea"
        >
          {src && (
            <ReactCrop
              circularCrop={circularCrop}
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
          {!src && (
            <>
              <div className="IconArea">
                <Icon className="icon" fontSize="large">
                  download_for_offline_icon
                </Icon>
                <h4>{t("global:cropperAction")}</h4>
              </div>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => this.onSelectFile(e?.target)}
              />
            </>
          )}
        </label>
      </div>
    );
  }
}

export default compose(withTranslation())(ChangeImageFormStepOne);
