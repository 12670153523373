import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { carReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.property?.value)
    set(data, "property.uuid", values?.property?.value);

  if (values?.model?.value) set(data, "model.uuid", values?.model?.value);

  if (values?.typeOfCar?.value)
    set(data, "typeOfCar.uuid", values?.typeOfCar?.value);

  if (values?.driver?.value) {
    set(data, "driver.uuid", values?.driver?.value);
  }

  let payload;

  if (!update) {
    data.status = carReducer?.availableStatuses.find((el) => !!el.isSelected);

    const res = await GlobalAuthFetch({
      path: `/car`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/car/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.model) {
    errorsStep0.model = t("global:car.errors.model");
  }
  if (!values.registrationNumber) {
    errorsStep0.registrationNumber = t("global:car.errors.registrationNumber");
  }
  if (!values.registrationIDNumber) {
    errorsStep0.registrationIDNumber = t(
      "global:car.errors.registrationIDNumber"
    );
  }
  if (!values.vin) {
    errorsStep0.vin = t("global:car.errors.vin");
  }
  if (!values.dateOfFirstRegistration) {
    errorsStep1.dateOfFirstRegistration = t(
      "global:car.errors.dateOfFirstRegistration"
    );
  }
  if (!values.insuranceUpTo) {
    errorsStep1.insuranceUpTo = t("global:car.errors.insuranceUpTo");
  }
  if (!values.dateOfTheNextInspection) {
    errorsStep1.dateOfTheNextInspection = t(
      "global:car.errors.dateOfTheNextInspection"
    );
  }
  if (!values.warrantyUntil) {
    errorsStep1.warrantyUntil = t("global:car.errors.warrantyUntil");
  }
  if (!values.yearOfProduction) {
    errorsStep1.yearOfProduction = t("global:car.errors.yearOfProduction");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  return { ...errorsStep0, ...errorsStep1 };
};
