import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import i18n from "../../../../../Utils/i18next/i18next";

export const onSubmit = async (values, props) => {
    const { updateTableRegistry, loadData, update, updateSingleRegistry } = props;
    const data = Object.values(values).filter(el=> typeof el === 'object').map(el => {
      if( typeof el?.value === "object"){
        return {...el,value:el?.value?.value}
      }
      return el;
    });
    let payload;
  
    if (!update) {   
        // możliwe do rozbudowania
    }
  
    if (update) {       
      const res = await GlobalAuthFetch({
        path: `/settings`,
        method: "PATCH",
        data,
      });
      payload = res.payload;
    }
  
    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      props.setErrorHeader(1, true);

      return;
    }
  
    props.setErrorHeader(0, false);
    props.setErrorHeader(1, false);

    //Succes nofify
    loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
    if (updateSingleRegistry) updateSingleRegistry();
    if (updateTableRegistry) updateTableRegistry();
}

export const onValidate = (values, props) => {
    const errorsStep0 = {};
    const errorsStep1 = {};

    // if (!values.EMAIL_SERVICE_ESTIMATES_HOST?.value) {    
    //     set(errorsStep0,'EMAIL_SERVICE_ESTIMATES_HOST.value',i18n.t('global:settings.EMAIL_SERVICE_ESTIMATES_HOST_ERROR'));   
    // }
    // if (!values.EMAIL_SERVICE_ESTIMATES_LOGIN?.value) {       
    //     set(errorsStep0,'EMAIL_SERVICE_ESTIMATES_LOGIN.value',i18n.t('global:settings.EMAIL_SERVICE_ESTIMATES_LOGIN_ERROR'));   
    // }
    // if (!values.EMAIL_SERVICE_ESTIMATES_PASS?.value) {       
    //     set(errorsStep0,'EMAIL_SERVICE_ESTIMATES_PASS.value',i18n.t('global:settings.EMAIL_SERVICE_ESTIMATES_PASS_ERROR'));   
    // }
    
    // if (!values.SMTP_SERVER?.value) {    
    //     set(errorsStep0,'SMTP_SERVER.value',i18n.t('global:settings.SMTP_SERVER_ERROR'));   
    // }
    // if (!values.SMTP_LOGIN?.value) {       
    //     set(errorsStep0,'SMTP_LOGIN.value',i18n.t('global:settings.SMTP_LOGIN_ERROR'));   
    // }
    // if (!values.SMTP_PASS?.value) {       
    //     set(errorsStep0,'SMTP_PASS.value',i18n.t('global:settings.SMTP_PASS_ERROR'));   
    // }
  
    // if (!values.KP_SCHEMA_RECEIVED?.value) {       
    //     set(errorsStep1,'KP_SCHEMA_RECEIVED.value',i18n.t('global:settings.KP_SCHEMA_RECEIVED_ERROR'));   
    // }
    // if (!values.KP_SCHEMA_SENDED?.value) {       
    //     set(errorsStep1,'KP_SCHEMA_SENDED.value',i18n.t('global:settings.KP_SCHEMA_SENDED_ERROR'));   
    // }

    if (
        !props.steps[0]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep0).length > 0
      ) {
        props.setErrorHeader(0, true);
      }
      if (
        !!props.steps[0]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep0).length <= 0
      ) {
        props.setErrorHeader(0, false);
      }
      if (
        !props.steps[1]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep1).length > 0
      ) {
        props.setErrorHeader(1, true);
      }
      if (
        !!props.steps[1]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep1).length <= 0
      ) {
        props.setErrorHeader(1, false);
      }
     
    return {...errorsStep0,...errorsStep1};
}