import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import { connectModal } from "redux-modal";
import {  useState } from "react";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useTranslation } from "react-i18next";
import TranslationForm from "../../Forms/Admin/TranslationForm/TranslationForm";


const TranslationModal = (props) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(0);   
    const [steps, setSteps] = useState([
      { title: props.header, error: false }
    ]);
    const [isSubmit, setIsSubmit] = useState(false);
  const formName = "TranslationForm";
  const { handleHide, show } = props;

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"         
          bmxclass="Success"
          onClick={() => setIsSubmit(true)}
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };
  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={"700px"}    
      height={"250px"} 
      onClose={handleHide}
      header={props.header}
      buttons={buttons()}
      steps={steps}
    >
      <TranslationForm
        steps={steps}
        isSubmit={isSubmit}
        step={step} 
        setStep={setStep} 
        setErrorHeader={setErrorHeader}
        handleHide={handleHide} 
        formName={formName}      
        {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "translationsModal" })(TranslationModal);
