import { Icon } from "@mui/material";
import BMXButton from "../Buttons/BMXButton/BMXButton";
import "./PrintDocumentTemplate.scss";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useRef } from "react";
import html2pdf from "html-to-pdf-js";

const PrintDocumentTemplate = (props) => {
  const { additionalButtons, usersToSendEmail, fileName, children } = props;
  const currentRef = useRef();

  const dispatch = useDispatch();
  const showModal = async () => {
    const document = await getDocumentFile();
    return dispatch(
      show("printDocumentEmailModal", { usersToSendEmail, document })
    );
  };

  const opt = {
    filename: fileName || "document",
  };

  const getDocumentFile = () =>
    new Promise((res) => {
      html2pdf()
        .from(currentRef.current)
        .set(opt)
        .toPdf()
        .output("blob")
        .then((data) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const file = new File([blob], `${opt.filename}.pdf`);
          res(file);
        });
    });

  const onPrint = () => {
    html2pdf()
      .from(currentRef.current)
      .set(opt)
      .toPdf()
      .output("blob")
      .then((data) => {
        var blob = new Blob([data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);

        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);

        iframe.style.display = "none";
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      });
  };

  const onDownload = () => {
    html2pdf(currentRef.current, opt);
  };

  return (
    <div className="PrintDocument">
      <div className="PrintArea" ref={currentRef}>
        {children?.length > 0 ? (
          children.map((child) => <div className="PrintCard">{child}</div>)
        ) : (
          <div className="PrintCard">{children}</div>
        )}
      </div>
      <div className="PrintDocumentButtonsButtonsArea">
        {additionalButtons}
        <BMXButton onClick={onDownload} bmxclass="Success" buttontype="Fab">
          <Icon> file_download_outlined_icon</Icon>
        </BMXButton>
        <BMXButton onClick={onPrint} bmxclass="Success" buttontype="Fab">
          <Icon>local_printshop_outlined</Icon>
        </BMXButton>
        {usersToSendEmail && (
          <BMXButton bmxclass="Success" onClick={showModal} buttontype="Fab">
            <Icon>forward_to_inbox_outlined</Icon>
          </BMXButton>
        )}
      </div>
    </div>
  );
};

export default PrintDocumentTemplate;
