import "./BMXSelectField.scss";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Icon,
  IconButton,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get, set } from "lodash";

const BMXSelectField = (props) => {
  const isError = () => {
    const {
      meta: { touched, error, submitError },
    } = props;

    return Boolean((error || submitError) && touched);
  };

  const { t } = useTranslation();
  const {
    className,
    input,
    meta: { error, submitError },
    values,
    renderValueSurfix,
    MenuProps,
    selectmenuheight,
    renderValue,
    readOnly,
    IconComponent,
    label,
    afterIcon,
    afterIconClick,
    beforeIcon,
    beforeIconClick,
    afterIconSize,
    beforeIconSize,
    disabled,
  } = props;

  const defaultRenderValue = (e) =>
    `${
      e?.name ||
      values?.find((e) => e?.value === input?.value?.value)?.name ||
      t("global:empty")
    } ${
      renderValueSurfix &&
      (e?.name || values?.find((e) => e?.value === input?.value?.value)?.name)
        ? renderValueSurfix
        : ""
    }`;

  const [selected, setSelect] = useState([]);

  const onChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelect(value);
  };

  useEffect(() => {
    if (input.multiple) {
      const initVal = get(props, `initialValues.${input.name}`, []);
      const initData = values
        ?.map((el) => {
          return initVal
            .filter((elm) => elm.value === el.value)
            .map((e) => e.value)
            .includes(el.value)
            ? el
            : null;
        })
        .filter((e) => !!e);
      setSelect(initData);
    }
    //eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (input.multiple) {
      set(props.selectedValues, input.name, selected);
    }
    //eslint-disable-next-line
  }, [selected]);

  return (
    <div className={`BMXSelectField ${className || ""}`}>
      {beforeIcon && (
        <IconButton
          disabled={disabled}
          size="small"
          onClick={beforeIconClick}
          disableRipple={!beforeIconClick}
        >
          <Icon style={{ fontSize: beforeIconSize }}>{beforeIcon}</Icon>
        </IconButton>
      )}
      <FormControl
        {...props}
        variant="standard"
        sx={{ m: 1, minWidth: 120 }}
        error={isError()}
      >
        <InputLabel id="select-standard-label">{label}</InputLabel>
        <Select
          {...input}
          id="select-standard"
          labelId="select-standard-label"
          error={isError()}
          value={input.multiple ? selected : input.value}
          renderValue={renderValue || defaultRenderValue}
          onChange={input.multiple ? onChange : input.onChange}
          MenuProps={{
            PaperProps: { style: { maxHeight: selectmenuheight } },
            ...MenuProps,
          }}
          displayEmpty={false}
          IconComponent={IconComponent}
          inputProps={{ readOnly }}
        >
          {values?.map((e, index) => (
            <MenuItem key={`select-${index}`} className="itemsList" value={e}>
              <ListItemText>{e?.name}</ListItemText>
              {e?.icons?.map((el, idx) => (
                <IconButton
                  key={`Item-${idx}`}
                  onClick={(ev) => {
                    el.onClick(ev, e);
                    ev.stopPropagation();
                  }}
                  size={"small"}
                >
                  <Icon fontSize={"small"}>{el?.icon}</Icon>
                </IconButton>
              ))}
            </MenuItem>
          ))}
        </Select>
        {isError() && (
          <FormHelperText>
            <span className="error text">{error || submitError}</span>
          </FormHelperText>
        )}
      </FormControl>
      {afterIcon && (
        <IconButton
          size="small"
          disabled={disabled}
          onClick={afterIconClick}
          disableRipple={!afterIconClick}
        >
          <Icon style={{ fontSize: afterIconSize }}>{afterIcon}</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default BMXSelectField;

BMXSelectField.propTypes = {
  selectmenuheight: PropTypes.number,
  /**
   Props zawiera informacje czy pole jest aktywne czy nie
  */
  readOnly: PropTypes.bool,
  /**
   Props zawiera nazwe pola
  */
  label: PropTypes.string,
  /**
   Props zawiera nazwę klasy która pozwala nam np modyfikować style css
  */
  className: PropTypes.string,
  /**
   Props zawiera... 
  */
  input: PropTypes.string,
  /**
   Props zawiera...  
  */
  values: PropTypes.string,
  /**
   Props zawiera... 
  */
  renderValueSurfix: PropTypes.string,
  /**
   Props zawiera... 
  */
  MenuProps: PropTypes.string,
  /**
   Props zawiera... 
  */
  renderValue: PropTypes.string,
  /**
   Props zawiera... 
  */
  IconComponent: PropTypes.string,
  /**
   Props zawiera... 
  */
  afterIcon: PropTypes.string,
  /**
   Props zawiera... 
  */
  afterIconSize: PropTypes.string,
  /**
   Props zawiera... 
  */
  afterIconClick: PropTypes.func,
  /**
 Props zawiera... 
*/
  beforeIcon: PropTypes.string,
  /**
   Props zawiera... 
  */
  beforeIconSize: PropTypes.string,
  /**
   Props zawiera... 
  */
  beforeIconClick: PropTypes.func,
};
BMXSelectField.defaultProps = {
  selectmenuheight: 300,
  afterIconSize: "21px",
  beforeIconSize: "21px",
};
