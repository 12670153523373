import { t } from "i18next";

export const submit = (values,props) => {
    const { setStatuses, statuses, handleHide, selectedElement } = props;
    delete values.icons

    if (props?.update) {
      setStatuses(statuses.map((el,index)=>index===selectedElement.index?{...el,...values}:el));
      handleHide();
      return;
    }
    setStatuses([...statuses, values]);
    handleHide();
  };
  
  export const validate = (values) => {
    const errors = {};

    if(!values.name){
      errors.name= t('global:fieldRequired');
    }

    if(!values.status){
      errors.status= t('global:fieldRequired');
    }
  
    return errors;
  };
  