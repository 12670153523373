import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./AttachmentsEditFileFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import AttachmentsEditFileFormStepOne from "./AttachmentsEditFileFormSteps/AttachmentsEditFileFormStepOne";
import { useEffect, useState } from "react";
import { get } from "lodash";
import { Attachments } from "../../../../Utils/GlobalVariables/Statuses/GlobalStatuses";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AttachmentsEditFileForm = (props) => {
  const theme = useTheme();
  const { step, setStep, folder, file } = props;
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setInitialValues({
      folderName: get(folder, "name", ""),
      fileName: get(file, "name", ""),
      folderDescription: get(folder, "description", ""),
      status:
        Object.values(Attachments(t)).find((el) => el.value === file?.status) ||
        null,
      archiveDate: get(file, "archiveDate", false)
        ? moment(get(file, "archiveDate", false))
        : null,
      attachmentDescription: get(file, "description", ""),
      comments: get(file, "comment", ""),
      createdAt: get(file, "createdAt", ""),
      createdBy: get(file, "createdBy", ""),
    });
    // eslint-disable-next-line
  }, [folder, file]);
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {({ handleSubmit, values }) => (
        <form id={props.formName} onSubmit={handleSubmit}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AttachmentsEditFileFormStepOne values={values}/>
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AttachmentsEditFileForm;
