import { get } from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  const { update, updateSingleRegistry } = props;

  const { selectedFile, selectFolder } = values;

  const errorsStep0 = {};
  const errorsStep1 = {};

  if (
    !(values.selectedFile && values.selectedFile.length > 0) &&
    !values.wwwLink
  ) {
    errorsStep0.selectedFile = i18n.t("global:attachments.errors.selectedFile");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  if (Object.keys(errorsStep0).length > 0) return errorsStep0;

  if (
    get(values, "wwwLink", false) &&
    !(values.selectedFile && values.selectedFile.length > 0)
  ) {
    const data = new FormData();
    data.append("status", get(values, "status.value", ""));
    data.append("description", get(values, "attachmentDescription", ""));
    data.append("comment", get(values, "comments", ""));
    data.append("wwwLink", get(values, "wwwLink", ""));
    const archiv = get(values, "archiveDate", false);
    if (archiv) data.append("archiveDate", moment(values.archiveDate));

    if (!update) {
      const { payload } = await GlobalAuthFetch({
        path: `/attachments/${selectFolder.uuid}`,
        method: "POST",
        data: data,
        addons: {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        },
      });

      if (payload?.status >= 300 || !payload?.status) {
        props.setErrorHeader(0, true);
        return;
      }
    }
  }

  for (const file of selectedFile) {
    const data = new FormData();
    data.append("file", file);
    data.append("status", get(values, "status.value", ""));
    data.append("description", get(values, "attachmentDescription", ""));
    data.append("comment", get(values, "comments", ""));
    data.append("wwwLink", get(values, "wwwLink", ""));
    const archiv = get(values, "archiveDate", false);
    if (archiv) data.append("archiveDate", moment(values.archiveDate));

    if (!update) {
      const { payload } = await GlobalAuthFetch({
        path: `/attachments/${selectFolder.uuid || selectFolder.value}`,
        method: "POST",
        data: data,
        addons: {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
          onUploadProgress: async (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            if (percent < 100) {
              props.setUploadProgress(percent);
            }
          },
        },
      });

      if (payload?.status >= 300 || !payload?.status) {
        props.setErrorHeader(0, true);
        return;
      }
    }
  }
  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);
  //Succes nofify
  props.loadData();
  updateSingleRegistry();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.selectFolder) {
    errorsStep1.selectFolder = i18n.t("global:attachments.errors.selectFolder");
  }

  if (
    !(values.selectedFile && values.selectedFile.length > 0) &&
    !values.wwwLink
  ) {
    errorsStep1.selectFolder = i18n.t("global:attachments.errors.selectFolder");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }

  return { ...errorsStep0, ...errorsStep1 };
};
