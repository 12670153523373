import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "status.name", name: "global:worker.status" },
      { key: "position.entryName", name: "global:worker.position" },
      { key: "firstName", name: "global:worker.name" },
      { key: "lastName", name: "global:worker.lastName" },
    ],
    selectedSearchFields: ["position.entryName"],
    dictionaryFields: ["position", "contract", "dayJob"],
    url: "worker",
  },
};

const workerReducer = createSlice({
  name: "workerReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = workerReducer.actions;

export default workerReducer.reducer;
