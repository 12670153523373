import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";

export const onSubmit = async (values, props) => {
  const {
    handleHide,
    updateSocketSecretary,
    setErrorHeader,
    update,
    loadData,
    setDisableButton,
    setContactError,
  } = props;

  if (!values.contact) {
    NotificationManager.error(
      i18n.t("global:secretaryCorrespondence.contactError")
    );
    setContactError(true);
    return;
  }

  if (!values.correspondenceNumber) {
    NotificationManager.error(
      i18n.t("global:secretaryCorrespondence.correspondenceNumberError")
    );
    return;
  }

  setDisableButton(true);
  const finalData = {
    ...values,
    storageLocation: null,
    priority: values?.priority?.value,
    text: values?.email?.content,
    title: values?.subject,
    attachments: [],
  };

  if (
    typeof values?.storageLocation === "string" ||
    typeof values?.storageLocation?.label === "string"
  ) {
    const {
      payload: { data },
    } = await GlobalAuthFetch({
      path: "/storageLocation",
      method: "POST",
      data: {
        value: values?.storageLocation?.label || values?.storageLocation,
      },
    });

    finalData.storageLocation = {
      uuid: data.uuid,
      value: data.value,
    };
  }

  if (Boolean(values?.recipient?.value)) {
    finalData.recipient = { uuid: values?.recipient?.value };
  } else {
    finalData.recipient = null;
  }
  if (Boolean(values?.contact?.uuid))
    finalData.contact = { uuid: values?.contact?.uuid };
  if (Boolean(values?.branch?.uuid))
    finalData.branch = { uuid: values?.branch?.uuid };
  if (Boolean(values?.representative?.uuid))
    finalData.representative = { uuid: values?.representative?.uuid };

  finalData.email = values?.email?.from;

  const mailNumber = values?.email?.number;

  if (Boolean(update)) {
    const { payload } = await GlobalAuthFetch({
      path: `/incomingCorrespondence`,
      addons: {
        params: {
          mailNumber: mailNumber,
        },
      },
      method: FetchMethodType.patch,
      data: finalData,
    });

    if (payload?.status >= 400 || !payload?.status) {
      setErrorHeader(0, true);
      setDisableButton(false);
      return;
    }

    await saveAddAttachments(payload, values?.attachments);
    setErrorHeader(0, false);
    loadData();
    updateSocketSecretary();
    NotificationManager.success(i18n.t("global:success"));
    handleHide();
    setDisableButton(false);
    setContactError(false);
    return;
  }

  const { payload } = await GlobalAuthFetch({
    path: `/incomingCorrespondence`,
    addons: {
      params: {
        mailNumber: mailNumber,
      },
    },
    method: FetchMethodType.post,
    data: finalData,
  });

  if (payload?.status >= 400 || !payload?.status) {
    setErrorHeader(0, true);
    return;
  }

  await saveAddAttachments(payload, values?.attachments);
  setErrorHeader(0, false);
  loadData();
  updateSocketSecretary();
  NotificationManager.success(i18n.t("global:success"));
  handleHide();
  setDisableButton(false);
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.dateAndTimeOfReceipt) {
    errorsStep0.dateAndTimeOfReceipt = i18n.t(
      "global:secretaryCorrespondence.receiptDateError"
    );
  }

  // if (!values.dateOfLetter) {
  //   errorsStep0.dateOfLetter = i18n.t(
  //     "global:secretaryCorrespondence.dateOfLetterError"
  //   );
  // }
  // if (!values.letterConcerns) {
  //   errorsStep0.letterConcerns = i18n.t(
  //     "global:secretaryCorrespondence.letterConcernsError"
  //   );
  // }

  // if (!values.recipient) {
  //   errorsStep0.recipient = i18n.t(
  //     "global:secretaryCorrespondence.recipientError"
  //   );
  // }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};

const saveAddAttachments = async (payload, attachments) => {
  const {
    data: { uuid },
  } = payload;

  if (Boolean(uuid)) {
    for (const file of attachments) {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("incomingCorrespondence", uuid);

      await GlobalAuthFetch({
        path: "/incomingCorrespondence/addAttachment",
        method: FetchMethodType.patch,
        data: formData,
        addons: {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        },
      });
    }
  }
};
