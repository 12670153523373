import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./CarFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import CarFormStepTwo from "./CarFormSteps/CarFormStepTwo";
import CarFormStepOne from "./CarFormSteps/CarFormStepOne";
import { useEffect, useState } from "react";

const CarForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues:_initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
       ..._initialValues,
       model:_initialValues?.model?.uuid ? {value:_initialValues?.model?.uuid} : null,
       typeOfCar:_initialValues?.typeOfCar?.uuid ? {value:_initialValues?.typeOfCar?.uuid} : null,
       property:_initialValues?.property?.uuid ? {value:_initialValues?.property?.uuid} : null,
       driver:_initialValues?.driver?.uuid ? {value:_initialValues?.driver?.uuid} : null
      });
      return;
    }

    setInitialValues({
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="CarForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <CarFormStepOne {...formProps} {...props} />
              <CarFormStepTwo {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default CarForm;
