import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import PC from "./PC/App";
import MOBILE from "./Mobile/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { isMobile } from "./Utils/Mobile";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import { loadLanguage } from "./Utils/i18next/i18next"; //ptomis czy zaladowało
import BMXLoader from "./Components/Loader/BMXLoader/BMXLoader";
import { env } from "./env";

axios.defaults = {
  baseURL: env.REACT_APP_API_URL,
};

ReactDOM.render(
  <Provider store={store}>
    <BMXLoader />
  </Provider>,
  document.getElementById("root")
);

const error = console.error;

function logReactError(...errors) {
  let showError = true;
  errors?.forEach((error) => {
    if (error?.includes("Warning:")) showError = false;
  });
  if (showError) error(...errors);
}

console.error = logReactError;

const warn = console.warn;

function logReactWarning(...warnings) {
  let showWarning = true;
  warnings.forEach((warning) => {
    if (warning?.includes("MUI:")) showWarning = false;
    else if (warning?.includes("The mask")) showWarning = false;
  });
  if (showWarning) warn(...warnings);
}

console.warn = logReactWarning;

loadLanguage()
  .then((res) => {
    ReactDOM.render(
      <Provider store={store}>
        {res && !isMobile() && <PC />}
        {res && isMobile() && <MOBILE />}
      </Provider>,
      document.getElementById("root")
    );
  })
  .catch((err) => {
    console.error(err);
    window.location.reload();
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert("Załadowano nową wersję aplikacji");
    // const Cookies = document.cookie.split(";");
    // for (var i = 0; i < Cookies.length; i++)
    //   document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload(true);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
