import { withTranslation } from "react-i18next";
import "./AttachmentsFromStorageFormStepOne.scss";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { connect } from "react-redux";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
class AttachmentsFromStorageFormStepOne extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      storageFiles: [],
    };
  }

  async componentDidMount() {
    const { user } = this.props;
    const { payload } = await GlobalAuthFetch({
      path: `/worker/${user.uuid}/getStorageFiles`,
    });
    this.setState({
      storageFiles: payload?.data,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.storageFiles.length !== this.state.storageFiles.length) {
      this.setState({
        storageFiles: this.state.storageFiles,
      });
    }
  }

  render() {
    const { t, values, initialValues } = this.props;
    const { storageFiles } = this.state;

    return (
      <div className="AttachmentsFromStorageFormStepOne">
        <Row>
          <Col>
            <BMXSeparator title={t("global:attachments.addFromStorage")} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              multiple
              name="selectFile"
              className="mt-3"
              selectedValues={values}
              initialValues={{
                selectFile: initialValues?.selectFile?.map((e) => ({
                  value: e.uuid,
                  name: `${e.firstName || ""} ${e.lastName || ""}`,
                })),
              }}
              values={storageFiles}
              renderValue={(el) => {
                return el.map((e) => e.name).join(", ");
              }}
              component={BMXSelectField}
              label={t("global:settings.selectFile")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  connect((state) => ({
    user: state.userSession.user,
  })),
  withTranslation()
)(AttachmentsFromStorageFormStepOne);
