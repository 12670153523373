import { t } from "i18next";

export const submit = (values, props) => {
  const { handleHide, outerForm } = props;

  const outerFormValues = outerForm.getState().values;
  const prevDeliveredTo = outerFormValues?.deliveredTo || [];
  const deliveredTo = [...prevDeliveredTo];

    if (Boolean(values?.transferTo?.value)) {
      deliveredTo.push({
        worker: {
          uuid: values?.transferTo?.value,
          nameAndLastName: values?.transferTo?.name,
        },
        createdAt: new Date(),
      });
    }
    outerForm.change("deliveredTo", deliveredTo);
    handleHide();
    return;
  
};

export const validate = (values) => {
  const errors = {};

  if (!values.transferTo) {
    errors.transferTo = t("global:fieldRequired");
  }

  return errors;
};
