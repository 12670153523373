import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const ContactsFormStepTwo = (props) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        payload: { data },
      } = await GlobalAuthFetch({ path: "/worker/all" });
      setUsers(data);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <BMXSeparator title={t("global:contacts.responsiblePeople")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.phoneNumber")}`}
            name="phoneNumber"
            data-cy="phoneNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.email")}`}
            name="email"
            data-cy="email"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.website")}`}
            name="website"
            data-cy="website"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXSelectField}
            values={[
              {
                name: t("global:contacts.private_person"),
                value: "private_person",
              },
              { name: t("global:contacts.company"), value: "company" },
            ]}
            label={t("global:contacts.contactType")}
            name="contactType"
            data-cy="contactType"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5%" }}>
        <Col xs={12}>
          <Field
            component={BMXSelectField}
            values={users?.map((el) => ({
              name: `${el.firstName} ${el.lastName}`,
              value: el.uuid,
            }))}
            label={t("global:contacts.clientGuardian")}
            name="clientGuardian"
            data-cy="clientGuardian"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContactsFormStepTwo;
