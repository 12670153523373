import { Col, Row } from "react-bootstrap";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";
import BMXSelectField from "../../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { ResetTimeType } from "../../../../../../Utils/GlobalVariables/Secretary/SecretaryType";
import "./SettingsSecretaryFormStepTwo.scss";
const SettingsSecretaryFormStepTwo = (props) => {
  const { t } = useTranslation();

  return (
    <div className="justify-content-start">
      <Row>
        <Col>
          <BMXSeparator title={t("global:settings.KPSchema")} />
        </Col>
      </Row>
      <Row>
        <Col className="my-1 secretaryIndexText">
          <Tooltip
            title={t("global:settings.secretaryIndexText")}
            placement="top"
          >
            <IconButton size="small">
              <Icon className="primary text" fontSize={"small"}>
                information
              </Icon>
            </IconButton>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="KP_SCHEMA_RECEIVED"
            name="KP_SCHEMA_RECEIVED.value"
            component={BMXTextField}
            label={t("global:settings.KP_SCHEMA_RECEIVED")}
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="KP_SCHEMA_RECEIVED_RESET"
            name="KP_SCHEMA_RECEIVED_RESET.value"
            component={BMXSelectField}
            values={[
              {
                name: t("global:settings.resetSchema.month"),
                value: ResetTimeType.month,
              },
              {
                name: t("global:settings.resetSchema.year"),
                value: ResetTimeType.year,
              },
            ]}
            label={t("global:settings.KP_SCHEMA_RECEIVED_RESET")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="KP_SCHEMA_SENDED"
            name="KP_SCHEMA_SENDED.value"
            component={BMXTextField}
            label={t("global:settings.KP_SCHEMA_SENDED")}
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="KP_SCHEMA_SENDED_RESET"
            name="KP_SCHEMA_SENDED_RESET.value"
            component={BMXSelectField}
            values={[
              {
                name: t("global:settings.resetSchema.month"),
                value: ResetTimeType.month,
              },
              {
                name: t("global:settings.resetSchema.year"),
                value: ResetTimeType.year,
              },
            ]}
            label={t("global:settings.KP_SCHEMA_SENDED_RESET")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SettingsSecretaryFormStepTwo;
