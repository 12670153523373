import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./WorkerStaffFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import WorkerStaffFormStepOne from "./WorkerStaffFormSteps/WorkerStaffFormStepOne";
import WorkerStaffFormStepTwo from "./WorkerStaffFormSteps/WorkerStaffFormStepTwo";

const WorkerStaffForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues } = props;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="WorkerForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <WorkerStaffFormStepOne {...formProps} />
              <WorkerStaffFormStepTwo {...formProps} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default WorkerStaffForm;
