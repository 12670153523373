import { t } from "i18next";
import { NotificationManager } from "react-notifications";
import {GlobalAuthFetch} from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch"
import { updateDashboardByName } from "../../../../Utils/Sockets";

export const onSubmit = async (values, props) => {

    const { updateSingleRegistry, loadData, uuid ,handleHide, setErrorHeader, update, item } = props;
    const data = values;
    const ALERT_REGISTRY_NAME = 'alert';

    data.uuid = item?.uuid
      
    let payload;
  
    if (!update) {
      
    //   const res = await GlobalAuthFetch({
    //     path: `/alert/${pathUuid}`,
    //     method: "POST",
    //     data,
    //   });
    //   payload = res.payload;

    }
  
    if (update) {
        
      const res = await GlobalAuthFetch({
        path: `/globalAlert`,
        method: "PATCH",
        data,
      });
      payload = res.payload;

    }
  
    if (payload?.status >= 300 || !payload?.status) {
      setErrorHeader(0, true);
      return;
    }
  
    setErrorHeader(0, false);
    
    //Succes nofify
    loadData();
    NotificationManager.success(t("global:success"));
    if (updateSingleRegistry) updateSingleRegistry(uuid);
    updateDashboardByName(ALERT_REGISTRY_NAME);
    handleHide();
  };
  
  export const onValidate = (values, props) => {
    const errorsStep0 = {};
 
    
  
    if (
      !props.steps[0]?.error &&
      props.isSubmit &&
      Object.keys(errorsStep0).length > 0
    ) {
      props.setErrorHeader(0, true);
    }
    if (
      !!props.steps[0]?.error &&
      props.isSubmit &&
      Object.keys(errorsStep0).length <= 0
    ) {
      props.setErrorHeader(0, false);
    }
    
    return { ...errorsStep0 };
  };
  