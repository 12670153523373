import { withTranslation } from "react-i18next";
import "./AttachmentsFromTemplateFormStepOne.scss";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";

class AttachmentsFromTemplateFormStepOne extends PureComponent { 

  render() {
    const { t, folders, values } = this.props;

    return (                     
        <div className="AttachmentsFromTemplateFormStepOne">
          <Row>
            <Col md={12}> 
              <Field
                    name="selectFolder"         
                    className="mt-3"           
                    values={folders?.map(el=>({value:el.uuid,name:el.name}))}                                    
                    component={BMXSelectField}       
                    label={t('global:settings.selectFolder')}
                />
            </Col>   
          </Row>      
          <Row>
            <Col md={12}> 
              <Field
                  name="selectFile"   
                  className="mt-3"               
                  values={folders?.find(el=>el?.uuid===values?.selectFolder?.value)?.files?.map(el=>({value:el.uuid,name:el.name}))}                                   
                  component={BMXSelectField}       
                  label={t('global:settings.selectFile')}
                />
            </Col>   
          </Row>      
        </div>        
    );
  }
}

export default compose(withTranslation())(AttachmentsFromTemplateFormStepOne);
