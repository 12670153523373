import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXInputFile from "../../../../../Components/Inputs/BMXInputFile/BMXInputFile";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

import "./StorageAddFormStepOne.scss";
const StorageAddFormStepOne = (props) => {
  const { t } = useTranslation();
  useEffect(() => {});

  const { form } = props;

  return (
    <div className="storageAddFormStepOne">
      <BMXSeparator title={t("global:storage.fileInStorage")} />
      <Row>
        <Col xs={12}>
          {/* <BMXInputFile /> */}
          <Field
            component={BMXInputFile}
            beforeIcon={"attach_file"}
            label={`${t("global:storage.selectedFile")}*`}
            name="selectedFile"
            afterIcon={"close"}
            data-cy="fromDevice"
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            multiline={true}
            label={`${t("global:storage.description")}`}
            name="description"
            data-cy="description"
          />
        </Col>
      </Row>
    </div>
  );
};

export default StorageAddFormStepOne;
