import { Icon } from "@mui/material";
import BMXButton from "../../Components/Buttons/BMXButton/BMXButton";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./FileIcon.scss";
class FileIcon extends PureComponent {
  render() {
    const { file, deleted } = this.props;
    return (
      <div className="files">
        <div className="item">
          <div className="item-container">
            <Icon style={{ color: "#6F6F6F" }}>insert_drive_file</Icon>
            <BMXButton
              className="remove"
              onClick={() => {
                deleted();
              }}
            >
              <Icon style={{ color: "red", fontSize: "1.6rem" }}>close</Icon>
            </BMXButton>
          </div>
          <p className="name">{file.name}</p>
          <p className="size">{(file.size / 1000).toFixed(2)} KB</p>
        </div>
      </div>
    );
  }
}
FileIcon.propTypes = {
  /**
      Props zawiera nazwe pliku wraz z jego rozmiarem
    */
  file: PropTypes.object,
  /**
      Props usunięcia załącznika
    */
  deleted: PropTypes.func,
};
export default FileIcon;
