import { GlobalAuthFetch } from "../GlobalAuthFetch/GlobalAuthFetch";

export const loadPictures = async (user) => {
  if (user?.picture) {
    const { payload } = await GlobalAuthFetch({
      path: `/worker/${user?.uuid}/getPicture`,
      addons: { responseType: "blob" },
    });
    if (payload?.status < 300) {
      return URL.createObjectURL(payload?.data);
    }
    return null;
  }
};
