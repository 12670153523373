import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";


const RepresentativesFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <BMXSeparator
        title={t("global:representatives.informationAboutContact")}
      />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:representatives.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:representatives.lastname")}*`}
            name="lastname"
            data-cy="lastname"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:representatives.position")}`}
            name="position"
            data-cy="position"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:representatives.phoneNumber")}`}
            name="phoneNumber"
            data-cy="phoneNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:representatives.email")}`}
            name="email"
            data-cy="email"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:representatives.birthday")}`}
            name="dayOfBirth"
            data-cy="birthday"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:representatives.nameDay")}`}
            name="dayOfName"
            data-cy="nameDay"
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: "5%" }}>
        <Col xs={12}>
          <Field
            multiline={true}
            component={BMXTextField}
            label={`${t("global:representatives.comments")}`}
            name="comments"
            data-cy="comments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RepresentativesFormStepOne;
