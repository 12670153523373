import React from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./StatusFormSubmit";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXButton from "../../../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { t } from "i18next";
import BMXSelectField from "../../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXColorPicker from "../../../../../../Components/Inputs/BMXColorPicker/BMXColorPicker";

const StatusForm = (props) => {
  const { initialValues } = props;

  return (
    <Form
      initialValues={{
        ...initialValues,
        status: {
          value:
            initialValues?.status?.value || initialValues?.status || "DRAFT",
        },
        color: {
          font: { hex: initialValues?.color?.font || "#000" },
          background: { hex: initialValues?.color?.background || "#fff" },
        },
      }}
      onSubmit={(val) => submit(val, props)}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="StatusForm">
          <Row>
            <Col xs={7}>
              <Field
                name="name"
                component={BMXTextField}
                label={t("global:registry.status.backgroundName")}
              />
            </Col>
            <Col xs={5}>
              <Field
                name="status"
                MenuProps={{ disablePortal: true }}
                component={BMXSelectField}
                values={[
                  { value: "ARCHIVE", name: t("global:statusArchive") },
                  { value: "ACTIVE", name: t("global:statusActive") },
                  { value: "CLOSED", name: t("global:statusClosed") },
                  { value: "DRAFT", name: t("global:statusDraft") },
                ]}
                label={t("global:registry.status.status")}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                className="mt-3"
                name="color.background"
                label={t("global:registry.status.backgroundColor")}
                component={BMXColorPicker}
              />
            </Col>
            <Col xs={6}>
              <Field
                className="mt-3"
                name="color.font"
                label={t("global:registry.status.font")}
                component={BMXColorPicker}
              />
            </Col>
          </Row>
          <Row className=" d-flex justify-content-end">
            <Col className="mt-3 d-flex justify-content-end">
              <BMXButton
                data-cy="submit"
                bmxclass="Success"
                type="Submit"
                onClick={handleSubmit}
              >
                {t("global:add")}
              </BMXButton>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default StatusForm;
