import i18n from "../i18next/i18next";
import { get } from "lodash";
export const getTranslationsAllKeys = () => {
  const {
    store: { data: payload },
  } = i18n.toJSON();

  const flatMapAll = (val) => {
    if (val) {
      return val.flatMap((el) => {
        if (typeof el !== "object") {
          return el;
        }
        return flatMapAll(el);
      });
    }
  };
  const isNasted = (payload, path, key) => {
    const newPath = `${path}.${key}`;
    const value = get(payload, newPath);
    if (typeof value === "object") {
      if (Boolean(value)&&Object.keys(value).length > 0) {
        const arrayPath = [];
        for (const el in value) {
          arrayPath.push(isNasted(payload, newPath, el));
        }
        return arrayPath;
      }
      if (Boolean(value)&&Object.keys(value).length <= 0) {
        return;
      }
    }
    return [newPath];
  };
  const keys = new Set();
  for (const lang in payload) {
    for (const namespace in get(payload, lang, [])) {
      for (const el in get(payload, `${lang}.${namespace}`, {})) {
        const value = flatMapAll(isNasted(payload, `${lang}.${namespace}`, el));
        if (value && value.length > 0) {
          for (const val of value) {
            if (val) {
              let KeysValue = val.split(".");
              KeysValue.shift();
              keys.add(KeysValue.join("."));
            }
          }
        }
      }
    }
  }
  return Array.from(keys);
};
