import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  table: {
    search: "",
    limit: 9,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "name", name: "global:contacts.name" },
      { key: "address", name: "global:contacts.address" },
      { key: "city", name: "global:contacts.city" },
      { key: "phoneNumber", name: "global:contacts.phoneNumber" },
      { key: "email", name: "global:contacts.email" },
    ],
    selectedSearchFields: ["name"],
    dictionaryFields: [],
    url: "branch",
  },
};

const branchReducer = createSlice({
  name: "branchReducer",
  initialState,
  reducers: {
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
} = branchReducer.actions;

export default branchReducer.reducer;
