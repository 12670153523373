import i18n from "../../../../../Utils/i18next/i18next";
import { hide } from "redux-modal";
import { NotificationManager } from "react-notifications";
import { updateAdminRegistryByName } from "../../../../../Utils/Sockets";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import store from "../../../../../store";
const {dispatch} = store;

export const onDelete = async ({ loadData, uuid }) => {
  const DICTIONARY_REGISTRY_NAME = "dictionary";
  const onHideConfirmModal = () => dispatch(hide("ConfirmModal"));

  const { payload } = await GlobalAuthFetch({
    path: `/dictionary/${uuid}`,
    method: "DELETE",
  });
  if (payload?.status >= 300 || !payload?.status) {
    return;
  }
  
  updateAdminRegistryByName(DICTIONARY_REGISTRY_NAME);
  loadData();
  NotificationManager.success(i18n.t('global:success'));
  onHideConfirmModal();
};

export const onSubmit = async (values, props) => {
  const { loadData, handleHide } = props;
  const DICTIONARY_REGISTRY_NAME = "dictionary";

  const { payload } = await GlobalAuthFetch({
    path: "/dictionary",
    method: "POST",
    data: values,
  });
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return{dictionaryName:i18n.t("global:dictionary.dictionaryNameDuplicate")};
  }
  
  updateAdminRegistryByName(DICTIONARY_REGISTRY_NAME);
  loadData();
  NotificationManager.success(i18n.t('global:success'));
  handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  if (!values.dictionaryName) {
    errorsStep0.dictionaryName = i18n.t("global:required");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
