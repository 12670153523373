import { Form } from 'react-final-form';
import { onSubmit, onValidate } from './SettingsFormSubmit';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';
import SettingsFormStepOne from './SettingsFormSteps/SettingsModalFormStepOne';
import SettingsFormStepTwo from './SettingsFormSteps/SettingsModalFormStepTwo';
import { useEffect, useState } from 'react';

const SettingsForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update } = props;
  const [initialValues, setInitialValues] = useState(null); 

  useEffect(() => {
    if (update) {
      setInitialValues(props.initialValues);
      return;
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Form  
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, {...props, initialValues:initialValues})}
      validate={(val)=>onValidate(val,props)}
    >
      {({ handleSubmit }) => (
        <form
          id={props.formName}
          onSubmit={handleSubmit}
          className="SettingsForm"
        >
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <SettingsFormStepOne />
            <SettingsFormStepTwo />   
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default SettingsForm;
