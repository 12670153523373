import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXSecretaryModal from "../../../../Components/Modals/BMXSecretaryModal/BMXSecretaryModal";
import SecretaryCorspondencyNoteForm from "../../Forms/SecretaryCorspondencyNoteForm/SecretaryCorspondencyNoteForm";

const SecretariatCorespondencyNoteModal = (props) => {
  const { t } = useTranslation();
  const formName = "SecretaryCorspondencyNoteForm";
  const { handleHide, show, header } = props;
  const title = <span style={{ fontSize: "24px" }}>{header}</span>;
  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          data-cy="saveAlert"
          bmxclass="Success"
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXSecretaryModal
      left={1}
      top={1}
      open={show}
      width={"50.875rem"}
      height={"23.25rem"}
      onClose={handleHide}
      buttons={buttons()}
      header={title}
    >
      <SecretaryCorspondencyNoteForm formName={formName} {...props} />
    </BMXSecretaryModal>
  );
};

export default connectModal({ name: "SecretariatCorespondencyNoteModal" })(
  SecretariatCorespondencyNoteModal
);
