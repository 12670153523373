export const DL = (envelopeDataSender, envelopeDataReceiver) =>
  `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
    @page {
        size: 220mm 110mm;
        margin: 0;
    }
    body{
        font-family: 'Roboto', sans-serif;
        line-height: 1.2;
    }
    .sender-container{
        margin-left: 20px;
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 14px;

    }
    .receiver-container{
        position: absolute;
        max-width: 90mm;
        right: 150px;
        bottom: 80px;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 18px;
    }
    .logo {
        max-width: 50mm;
        max-height: 20mm;
        margin-bottom: 30px;
    }
    </style>
    <title>Wydruk koperty</title>
</head>
<body>
    <div class="sender-container">
    <img class="logo" src="${envelopeDataSender.logo}">
    <div><span>${envelopeDataSender.companyName}</span></div>
    <div><span>ul. ${envelopeDataSender.address}</span></div>
    <div><span>${envelopeDataSender.postCode} ${envelopeDataSender.city}</span></div>
    <div><span>${envelopeDataSender.website}</span></div>
    </div>
    <div class="receiver-container">
    <div><span style="font-weight:bold">Adresat:</span></div>
    <div><span>${envelopeDataReceiver.companyName}</span></div>
    <div><span>ul. ${envelopeDataReceiver.address}</span></div>
    <div><span>${envelopeDataReceiver.postCode} ${envelopeDataReceiver.city}</span></div>
    </div>
</body>
</html>`;

export const C5 = (envelopeDataSender, envelopeDataReceiver) =>
  `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
    @page {
        size: 229mm 165mm;
        margin: 0;
    }
    body{
        font-family: 'Roboto', sans-serif;
        line-height: 1.3;
    }
    .sender-container{
        margin-left: 20px;
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 16px;

    }
    .receiver-container{
        position: absolute;
        right: 150px;
        bottom: 140px;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 20px;
        max-width: 90mm;
    }
    .logo {
        max-width: 50mm;
        max-height: 20mm;
        margin-bottom: 30px;
    }
    </style>
    <title>Wydruk koperty</title>
</head>
<body>
    <div class="sender-container">
    <img class="logo" src="${envelopeDataSender.logo}">
    <div><span>${envelopeDataSender.companyName}</span></div>
    <div><span>ul. ${envelopeDataSender.address}</span></div>
    <div><span>${envelopeDataSender.postCode} ${envelopeDataSender.city}</span></div>
    <div><span>${envelopeDataSender.website}</span></div>
    </div>
    <div class="receiver-container">
    <div><span style="font-weight:bold">Adresat:</span></div>
    <div><span>${envelopeDataReceiver.companyName}</span></div>
    <div><span>ul. ${envelopeDataReceiver.address}</span></div>
    <div><span>${envelopeDataReceiver.postCode} ${envelopeDataReceiver.city}</span></div>
    </div>
</body>
</html>`;
