import { withTranslation } from "react-i18next";
import "./NoteFormStepOne.scss";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";

class NoteFormStepOne extends PureComponent {
  render() {
    const { t, update } = this.props;

    return (
      <div className="NoteFormStepOne">
        <Row>
          <Col>
            <BMXSeparator title={t("global:note.newNote")} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="content"
              component={BMXTextField}
              multiline
              maxRows={4}
              label={t("global:note.alert")}
            />
          </Col>
        </Row>
        {update && (
          <Row>
            <Col>
              <Field
                name="active"
                fieldtype="checkbox"
                component={BMXTextField}
                label={t("global:note.isActive")}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default compose(withTranslation())(NoteFormStepOne);
