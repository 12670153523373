import i18n from "../../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { updateAdminRegistryByName } from "../../../../../Utils/Sockets";

export const onSubmit = async (values, props) => {
    const { loadData, handleHide, element, selectedValues, usedFields } = props;
    const DICTIONARY_REGISTRY_NAME = "dictionary";

    let data = { ...element, fieldName: selectedValues.field };    

    if(usedFields.includes(selectedValues.field)){
        data = { ...element, fieldName: null };
    }

    delete data.updatedAt;
    
    const { payload } = await GlobalAuthFetch({
        path: `/dictionary/${data.uuid}`,
        method: "PUT",
        data,
    });
    if (payload?.status >= 300 || !payload?.status) {
        props.setErrorHeader(0, true);
        props.setErrorHeader(1, true);
        return { dictionaryName: i18n.t("global:dictionary.dictionaryNameDuplicate") };
    }

    updateAdminRegistryByName(DICTIONARY_REGISTRY_NAME);
    loadData();
    NotificationManager.success(i18n.t('global:success'));
    handleHide();
};

export const onValidate = (values, props) => {
    const errorsStep0 = {};
    if (
        !props.steps[0]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep0).length > 0
    ) {
        props.setErrorHeader(0, true);
    }
    if (
        !!props.steps[0]?.error &&
        !props.isSubmit &&
        Object.keys(errorsStep0).length <= 0
    ) {
        props.setErrorHeader(0, false);
    }
    return { ...errorsStep0 };
};
