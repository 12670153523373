import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import "./DoubleLine.scss";
class DoubleLine extends Component {
  render() {
    const { firstLine, secondLine, styleFirstLine, styleSecondLine, style, onClick } =
      this.props;
    return (
      <div className={`doubleLine ${onClick?"onClickElement":""}`} onClick={onClick} style={style}>
        <span style={styleFirstLine}>{firstLine}</span>
        <span style={styleSecondLine} className="grey text">
          {secondLine}
        </span>
      </div>
    );
  }
}
DoubleLine.propTypes = {
  /**
   pierwsaza linia tekstu
  */
  firstLine: PropTypes.string,
  /**
   Druga linia tekstu
  */
  secondLine: PropTypes.string,
  /**
   Stylowanie pierwszej linii tekstu 
  */
  styleFirstLine: PropTypes.string,
  /**
   Stylowanie drugiej  linii tekstu 
  */
  styleSecondLine: PropTypes.string,
 /**
   onClick dla komponentu 
  */
  onClick: PropTypes.func
};
export default memo(DoubleLine);
