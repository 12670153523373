import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";

// import { useDispatch } from "react-redux";

import "./RentContractSettledAgreementsFormStepOne.scss";
import { get } from "lodash";
import moment from "moment";
const RentContractSettledAgreementsFormStepOne = (props) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const { form, values, update, initialValues } = props;

  const settled = true;
  const loadData = async () => {};

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const { initialValues, view } = props;

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.basicInformation")} />
      <Row>
        <Col xs={{ span: 5 }}>
          <Field
            name="sn"
            label={`${t("global:rentContract.sn")}`}
            component={BMXTextField}
            data-cy="sn"
            disabled={settled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 5 }}>
          <Field
            name="producerModel"
            label={`${t("global:rentContract.producerModel")}`}
            component={BMXTextField}
            data-cy="producerModel"
            disabled={settled}
          />
        </Col>
        <Col xs={{ span: 5 }}>
          <Field
            name="deviceType"
            label={`${t("global:rentContract.deviceType")}`}
            component={BMXTextField}
            data-cy="deviceType"
            disabled={settled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }} className="pt-2">
          <Field
            name="beforeCounterMono"
            type="number"
            label={`${t("global:rentContract.beforeCounterMono")}`}
            component={BMXTextField}
            data-cy="beforeCounterMono"
            disabled={settled}
          />
        </Col>
        <Col xs={{ span: 3 }} className="currenNumber pt-2">
          <Field
            name="currentCounterMono"
            label={`${t("global:rentContract.currentCounterMono")}`}
            component={BMXTextField}
            data-cy="currentCounterMono"      
            type="number"
            nonDecimale
            disabled={view||!initialValues.billedMono}
          />
        </Col>
        <Col xs={{ span: 3 }} className="pt-2">
          <Field
            name="monthlyMono"
            label={`${t("global:rentContract.monthlyMono")}`}
            component={BMXTextField}
            data-cy="monthlyMono"
            disabled={settled}
          />
        </Col>
        <Col xs={3} className="pt-2">
          <Field
            name="monthlyMonoCost"
            label={`${t("global:rentContract.monthlyMonoCost")}`}
            type="money"
            decimalScale={2}
            component={BMXTextField}
            data-cy="monthlyMonoCost"
            disabled={settled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }}>
          <Field
            name="beforeCounterColor"
            type="number"
            label={`${t("global:rentContract.beforeCounterColor")}`}
            component={BMXTextField}
            data-cy="beforeCounterColor"
            disabled={settled}
          />
        </Col>
        <Col xs={{ span: 3 }} className="currenNumber">
          <Field
            name="currentCounterColor"
            type="number"
            label={`${t("global:rentContract.currentCounterColor")}`}
            component={BMXTextField}
            data-cy="currentCounterColor"         
            nonDecimale
            disabled={view||!initialValues.billedColor}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            name="monthlyColor"
            type="number"
            label={`${t("global:rentContract.monthlyColor")}`}
            component={BMXTextField}
            data-cy="monthlyColor"
            disabled={settled}
          />
        </Col>
        <Col xs={3}>
          <Field
            name="monthlyColorCost"
            type="money"
            label={`${t("global:rentContract.monthlyColorCost")}`}
            decimalScale={2}
            component={BMXTextField}
            data-cy="monthlyColorCost"
            disabled={settled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }}>
          <Field
            name="beforeCounterScan"
            type="number"
            label={`${t("global:rentContract.beforeCounterScan")}`}
            component={BMXTextField}
            data-cy="beforeCounterScan"
            disabled={settled}
          />
        </Col>
        <Col xs={{ span: 3 }} className="currenNumber">
          <Field
            name="currentCounterScan"
            type="number"
            label={`${t("global:rentContract.currentCounterScan")}`}
            component={BMXTextField}
            data-cy="currentCounterScan"          
            nonDecimale
            disabled={view||!initialValues.billedScan}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            name="monthlyScan"
            type="number"
            label={`${t("global:rentContract.monthlyScan")}`}
            component={BMXTextField}
            data-cy="monthlyScan"
            disabled={settled}
          />
        </Col>
        <Col xs={3}>
          <Field
            name="monthlyScanCost"
            type="money"
            label={`${t("global:rentContract.monthlyScanCost")}`}
            decimalScale={2}
            component={BMXTextField}
            data-cy="monthlyScanCost"
            disabled={settled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <span className="settledPerson">
            Rozliczył:{" "}
            {`${get(initialValues, "createdBy.firstName", "System")} ${get(
              initialValues,
              "createdBy.lastName",
              ""
            )} - ${
              get(initialValues, "createdAt", false)
                ? moment(initialValues?.createdAt).format("HH:mm")
                : ""
            } ${
              get(initialValues, "createdAt", false)
                ? moment(initialValues?.createdAt).format("DD-MM-YYYY")
                : ""
            }`}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default RentContractSettledAgreementsFormStepOne;
