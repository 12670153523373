import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const OfferCustomerPotenitalFormStepTwo = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:offer.summary")} />
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:offer.summaryOfClient")}`}
            name="summary"
            data-cy="summary"
          />
        </Col>
      </Row>
    </div>
  );
};

export default OfferCustomerPotenitalFormStepTwo;
