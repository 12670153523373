import {
  EmailValidators,
  passwordValidator,
} from "../../../../Utils/Validators/Validators";
import { t } from "i18next";
import BMXFetch from "../../../../Utils/GlobalFetch/GlobalFetch";
import { cloneDeep } from "lodash";
import { NotificationManager } from "react-notifications";

export const submit = async (values, props) => {
  const { emailCode, email } = cloneDeep(values);
  const { step, goNext, setValues, values: prevValues, setIsLoading } = props;

  if (step === 0) {
    try {
      setIsLoading(true);

      await new BMXFetch(`/worker/restorePassword/${email}`).send();
      setIsLoading(false);
      return goNext(setValues({ ...prevValues, ...values }));
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err;
      setIsLoading(false);
      return { email: t(`global:resetPassword.${message}`) };
    }
  }

  if (step === 1) {
    try {
      setIsLoading(true);
      await new BMXFetch(`/worker/checkEmailCode/${emailCode}`).send();
      setIsLoading(false);
      return goNext(setValues({ ...prevValues, ...values }));
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err;
      setIsLoading(false);
      return { emailCode: t(`global:resetPassword.${message}`) };
    }
  }

  try {
    setIsLoading(true);
    values.login = values?.login?.trim();
    const fetch = new BMXFetch(`/worker/restorePassword`, { method: "PATCH" });
    fetch.setBody(values);
    await fetch.send();
    setIsLoading(false);
    NotificationManager.success(t("global:success"));
  } catch (err) {
    const {
      response: {
        data: { message },
      },
    } = err;
    setIsLoading(false);
    return { login: t(`global:resetPassword.${message}`) };
  }

  props.onClose();
};

export const validate = (values, props) => {
  const errors = {};
  const { email, emailCode, password, repeatPassword, login } = values;
  const { step } = props;

  if (step === 0) {
    if (!email) {
      errors.email = t("global:required");
    }
    if (email && EmailValidators(email)) {
      errors.email = t("global:resetPassword.errorValidation");
    }
  }

  if (step === 1) {
    if (!emailCode) {
      errors.emailCode = t("global:required");
    }
  }

  if (step === 2) {
    if (!login) {
      errors.login = t("global:required");
    }

    if (!password) {
      errors.password = t("global:required");
    }

    if (!repeatPassword) {
      errors.repeatPassword = t("global:required");
    }
    if (password !== repeatPassword) {
      errors.password = t("global:resetPassword.passwordNotEqual");
      errors.repeatPassword = t("global:resetPassword.passwordNotEqual");
    }
    // CHECK IS PASSWORD PASS REQUIRED VALUE
    if (!passwordValidator(password)) {
      errors.password = t("global:paswordNotMatchRequirement");
    }
  }

  return errors;
};
