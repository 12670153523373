import { t } from "i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  
  const {selectedFolder:{uuid},loadData, updateSingleRegistry} = props  
  const {selectFile:{value}} = values;
  
  const { payload } = await GlobalAuthFetch({
    path: `/attachments/copyFileToFolder/${value}/${uuid}`,
    method: "POST",
  });

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return;
  }

  props.setErrorHeader(0, false);
  NotificationManager.success(t("global:success"));
  updateSingleRegistry();
  loadData();
  props.handleHide();
};

export const onValidate = (values) => {
  const errors0 = {};
  if(!values.selectFile)
  errors0.selectFile=t("global:attachmentsValidationTemplate.selectFile")

  if(!values.selectFolder)
  errors0.selectFolder=t("global:attachmentsValidationTemplate.selectFolder")

  return {...errors0};
};
