import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";

export const onSubmit = async (values, props) => {
  const {
    handleHide,
    updateSocketSecretary,
    setErrorHeader,
    update,
    loadData,
    sendMail,
    setDisableButton,
    setContactError,
  } = props;

  if (!values.contact) {
    NotificationManager.error(
      i18n.t("global:secretaryCorrespondence.contactError")
    );
    setContactError(true);
    return;
  }

  if (!values.correspondenceNumber) {
    NotificationManager.error(
      i18n.t("global:secretaryCorrespondence.correspondenceNumberError")
    );
    return;
  }
  setDisableButton(true);

  const finalData = {
    ...values,
    storageLocation: null,
    priority: values?.priority?.value,
    title: values?.subject,
    envelopeType: values?.envelopeType?.value,
    sendMail,
    attachments: [],
    returnAttachment: [],
  };

  if (
    typeof values?.storageLocation === "string" ||
    typeof values?.storageLocation?.label === "string"
  ) {
    const {
      payload: { data },
    } = await GlobalAuthFetch({
      path: "/storageLocation",
      method: "POST",
      data: {
        value: values?.storageLocation?.label || values?.storageLocation,
      },
    });
    finalData.storageLocation = {
      uuid: data.uuid,
      value: data.value,
    };
  }

  if (Boolean(values?.recipient?.value))
    finalData.recipient = { uuid: values?.recipient?.value };
  if (Boolean(values?.contact?.uuid))
    finalData.contact = { uuid: values?.contact?.uuid };
  if (Boolean(values?.branch?.uuid))
    finalData.branch = { uuid: values?.branch?.uuid };
  if (Boolean(values?.representative?.uuid))
    finalData.representative = { uuid: values?.representative?.uuid };

  finalData.email = values?.email?.from;
  if (Boolean(update)) {
    const { payload } = await GlobalAuthFetch({
      path: `/outgoingCorrespondence`,
      method: FetchMethodType.patch,
      data: finalData,
    });

    if (payload?.status >= 400 || !payload?.status) {
      setErrorHeader(0, true);
      setDisableButton(false);
      return;
    }
    await saveAddAttachments(payload, values?.attachments);
    await saveAddReturnAttachments(payload, values?.returnAttachment);
    setErrorHeader(0, false);
    loadData();
    updateSocketSecretary();
    NotificationManager.success(i18n.t("global:success"));
    handleHide();
    setDisableButton(false);
    if (Boolean(sendMail)) {
      await GlobalAuthFetch({
        path: `/outgoingCorrespondence/mailSend/${payload?.data?.uuid}`,
        method: FetchMethodType.patch,
      });
    }
    return;
  }

  const { payload } = await GlobalAuthFetch({
    path: `/outgoingCorrespondence`,
    method: FetchMethodType.post,
    data: finalData,
  });

  if (payload?.status >= 400 || !payload?.status) {
    setErrorHeader(0, true);
    setDisableButton(false);
    return;
  }

  await saveAddAttachments(payload, values?.attachments);
  await saveAddReturnAttachments(payload, values?.returnAttachment);
  setErrorHeader(0, false);
  setContactError(false);
  loadData();
  updateSocketSecretary();
  NotificationManager.success(i18n.t("global:success"));
  handleHide();

  if (Boolean(sendMail)) {
    await GlobalAuthFetch({
      path: `/outgoingCorrespondence/mailSend/${payload?.data?.uuid}`,
      method: FetchMethodType.patch,
    });
  }
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values?.sendDate) {
    errorsStep0.sendDate = i18n.t(
      "global:secretaryCorrespondence.sendDateError"
    );
  }
  if (!values.status) {
    errorsStep0.status = i18n.t("global:secretaryCorrespondence.statusError");
  }

  // if (!values.dateOfLetter) {
  //   errorsStep0.dateOfLetter = i18n.t("global:secretaryDateOfLetterError");
  // }
  // if (!values.letterConcerns) {
  //   errorsStep0.letterConcerns = i18n.t("global:secretaryLetterConcernsError");
  // }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};

const saveAddAttachments = async (payload, attachments) => {
  const {
    data: { uuid },
  } = payload;

  if (Boolean(uuid) && attachments && attachments.length > 0) {
    for (const file of attachments) {
      if (file instanceof File) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("outgoingCorrespondence", uuid);

        await GlobalAuthFetch({
          path: "/outgoingCorrespondence/addAttachment",
          method: FetchMethodType.patch,
          data: formData,
          addons: {
            headers: {
              "Content-Type": "multipart/form-data;",
            },
          },
        });
      }
    }
  }
};

const saveAddReturnAttachments = async (payload, attachments) => {
  const {
    data: { uuid },
  } = payload;

  if (Boolean(uuid) && attachments && attachments.length > 0) {
    for (const file of attachments) {
      if (file instanceof File) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("outgoingCorrespondenceReturn", uuid);

        await GlobalAuthFetch({
          path: "/outgoingCorrespondence/addReturnAttachment",
          method: FetchMethodType.patch,
          data: formData,
          addons: {
            headers: {
              "Content-Type": "multipart/form-data;",
            },
          },
        });
      }
    }
  }
};
