import { Popover } from "@mui/material";
import { cloneElement, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./BMXPopover.scss";

const BMXPopover = ({ icon, disabled, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const iconRef = useRef();
  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    iconRef.current.onclick = handleClick;
    // eslint-disable-next-line
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const iconElement = cloneElement(icon, { ref: iconRef });
  return (
    <>
      {iconElement}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="Popover-container">{children}</div>
      </Popover>
    </>
  );
};

export default BMXPopover;
