import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXSecretaryModal from "../../../../Components/Modals/BMXSecretaryModal/BMXSecretaryModal";
import SecretaryCorspondencyTransferForm from "../../Forms/SecretaryCorspondencyTransferForm/SecretaryCorspondencyTransferForm";

const SecretariatTransferModal = (props) => {
    const { t } = useTranslation();
    const formName = "SecretariatTransferToForm";
    const { handleHide, show, header } = props;
    const title = <span style={{ fontSize: '14px' }}>{header}</span>
    const buttons = () => {
        return (
            <>
                <BMXButton
                    type="subbmit"
                    data-cy="saveAlert"
                    bmxclass="Success"
                    form={formName}
                >
                    {t("global:save")}
                </BMXButton>
            </>
        );
    };

    return (
        <BMXSecretaryModal
            open={show}
            width={"446px"}
            height={"296px"}
            onClose={handleHide}
            buttons={buttons()}
            header={title}
        >
            {/* TU UTWORZYC NA WZOR */}
            <SecretaryCorspondencyTransferForm formName={formName} {...props} />
        </BMXSecretaryModal>
    );
};

export default connectModal({ name: "SecretariatTransferModal" })(SecretariatTransferModal);
