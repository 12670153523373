import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  registryRights: [],
  availableStatuses: null,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "index", name: "global:offer.index" },
      { key: "contact.name", name: "global:offer.contact.contact" },
      { key: "title", name: "global:offer.title" },
      {
        key: "createdBy.firstName, createdBy.lastName",
        name: "global:offer.createdBy",
      },
      { key: "status.name", name: "global:offer.status" },
    ],
    selectedSearchFields: ["title"],
    dictionaryFields: ["offerType", "customerType"],
    url: "offer",
  },
};

const offerReducer = createSlice({
  name: "offerReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = offerReducer.actions;

export default offerReducer.reducer;
