import { MenuList, MenuItem } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";

const DictionaryConnectFormStepOne = (props) => {
  const { t } = useTranslation();
  const { registry, setSelectedValues } = props;

  const onSelectRegistry = (value) => {
    setSelectedValues({ registry: value, field: null });
  };

  return (
    <div className="justify-content-start">
      <Row>
        <Col>
          <BMXSeparator title={t("global:dictionary.selectRegistry")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <MenuList
            sx={{
              overflowY: "auto", // Dodaje przewijanie pionowe, gdy lista jest za długa
              maxHeight: "85%", // Maksymalna wysokość, aby nie przekroczyć kontenera
            }}
          >
            {registry.map((reg) => (
              <MenuItem
                key={reg.uuid}
                onClick={() => onSelectRegistry(reg.url)}
              >
                {reg.name}
              </MenuItem>
            ))}
          </MenuList>
        </Col>
      </Row>
    </div>
  );
};

export default DictionaryConnectFormStepOne;
