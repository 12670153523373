import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash';
import { AuthFetch } from '../AuthFetchReducer';
import { login } from '../UserSession';


const initialState = {
    errors: []
}

const errorsReducer = createSlice({
    name: 'errorsReducer',
    initialState,
    reducers: {
        addError: (state, { payload }) => {
            state.errors = [...state.errors, payload];
        },
        removeError: (state, { payload }) => {
            state.errors = state.errors.filter(err => !isEqual(err.type,payload.type));
        }
    },
    extraReducers: {
        [login.rejected]: (state, { error }) => {
            state.errors = [...state.errors, { type: 'login', error: error || '' }]
        },
        [AuthFetch.rejected]: (state, { error, meta: { arg } }) => {
            state.errors = [...state.errors, { type: arg, error: error || '' }]
        } 
    }
})


export const { addError, removeError } = errorsReducer.actions

export default errorsReducer.reducer
