import { NotificationManager } from "react-notifications";
import i18n from "../../../../Utils/i18next/i18next";
import { updateAdminRegistry } from "../../../../Utils/Sockets";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const onSubmit = async (values, props) => {
  const {
    selectedFolder: { uuid },
    loadData,
    pathUuid,
    updateSingleRegistry,
  } = props;
  const { selectFile } = values;
  const socketRefreshStorage = () => updateAdminRegistry(pathUuid);

  for (let file of selectFile) {
    const { payload } = await GlobalAuthFetch({
      path: `/attachments/copyFileToFolder/${file.uuid}/${uuid}`,
      method: "POST",
    });

    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }
  }

  props.setErrorHeader(0, false);
  NotificationManager.success(i18n.t("global:success"));
  socketRefreshStorage();
  updateSingleRegistry();
  loadData();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values?.selectFile?.length > 0) {
    errorsStep0.selectFile = i18n.t("global:attachments.errors.addToStorage");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
