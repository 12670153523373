import React from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./FolderFormSubmit";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXButton from "../../../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { t } from "i18next";

const FolderForm = (props) => {
  const { initialValues } = props;
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => submit(val, props)}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="FolderForm">
          <Row>
            <Col xs={12}>
              <Field
                name="name"
                component={BMXTextField}
                label={t("global:registry.folder.name")}
              />
            </Col>
          </Row>
          <Row className=" d-flex justify-content-end">
            <Col className="mt-3 d-flex justify-content-end ">
              <BMXButton
                data-cy="submit"
                bmxclass="Success"
                type="Submit"
                onClick={handleSubmit}
              >
                {t("global:save")}
              </BMXButton>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default FolderForm;
