import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const WorkerStaffFormStepOne = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [contractSelect, setContractSelect] = useState([]);
    const [dayJobSelect, setDayJobSelect] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const payloadContract = GlobalAuthFetch({ path: "/dictionary/contract/fieldName" });
            const payloadDayJob = GlobalAuthFetch({ path: "/dictionary/dayJob/fieldName" });
            const response = await Promise.all([payloadContract, payloadDayJob])

            setContractSelect(get(response, '[0].payload.data.dictionaryEntries', []));
            setDayJobSelect(get(response, '[1].payload.data.dictionaryEntries', []));
        };
        fetchData();
    }, [dispatch]);


    return (
        <div>
            <BMXSeparator title={t("global:worker.staff.defaultInfo")} />
            <Row>
                <Col xs={6}>
                    <Field
                        component={BMXSelectField}
                        values={contractSelect?.map(el=>({ name: el.entryName, value: el.uuid }))}
                        label={`${t("global:worker.staff.contract")}`}
                        name="contract"
                        data-cy="contract"
                    />
                </Col>
                <Col xs={6}>
                    <Field
                        component={BMXSelectField}
                        values={dayJobSelect?.map(el=>({ name: el.entryName, value: el.uuid }))}
                        label={`${t("global:worker.staff.dayJob")}`}
                        name="dayJob"
                        data-cy="dayJob"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.employmentStart')}`}
                        name="employmentStart"
                        data-cy="employmentStart"
                    />
                </Col>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.employmentEnd')}`}
                        name="employmentEnd"
                        data-cy="employmentEnd"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.lastExamination')}`}
                        name="lastExamination"
                        data-cy="lastExamination"
                    />
                </Col>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.endExamination')}`}
                        name="endExamination"
                        data-cy="endExamination"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.startBhp')}`}
                        name="startBhp"
                        data-cy="startBhp"
                    />
                </Col>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.endBhp')}`}
                        name="endBhp"
                        data-cy="endBhp"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Field
                        component={BMXDatePicker}
                        label={`${t('global:worker.staff.dayOfBirth')}`}
                        name="dayOfBirth"
                        data-cy="dayOfBirth"
                    />
                </Col>
                <Col xs={6}>
                    <Field
                        component={BMXTextField}
                        label={`${t('global:worker.staff.placeOfBirth')}`}
                        name="placeOfBirth"
                        data-cy="placeOfBirth"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default WorkerStaffFormStepOne;
