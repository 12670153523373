import { get } from "lodash";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import i18n from "../../../../Utils/i18next/i18next";
import store from "./../../../../store";

export const onSubmit = async (values, props) => {

  const { update, updateSingleRegistry ,updateTableRegistry } = props;
  const {userSession:{user:{uuid:workerUuid}}} = store.getState();
  const { selectedFile } = values;

  for (const file of selectedFile) {
    const data = new FormData();
    data.append("file", file);
  
    data.append("description", get(values, "description", ""));

    if (!update) {
      const { payload } = await GlobalAuthFetch({
        path: `/attachments/saveFileToStorage/${workerUuid}`,
        method: "POST",
        data: data,
        addons: {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        },
      });

      if (payload?.status >= 300 || !payload?.status) {
        props.setErrorHeader(0, true);
        return;
      }
    }
  }
  props.setErrorHeader(0, false);

  props.loadData();
  if(updateSingleRegistry) updateSingleRegistry();
  if(updateTableRegistry) updateTableRegistry(); 
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
 
  const errorsStep0 = {};
  if (!values.selectedFile || values.selectedFile?.length <= 0) {
    errorsStep0.selectedFile = i18n.t("global:storage.errors.selectedFile");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
