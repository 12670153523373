import SecondaryTable from "../../../Tables/SecondaryTable/SecondaryTable";
import { Icon, IconButton } from "@mui/material";
import { t } from "i18next";
import TripleLine from "../../../../../Components/TripleLine/TripleLine";
import moment from "moment";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";
import { NotificationManager } from "react-notifications";

const SecretariatCorespondenceStepThree = (props) => {
  const { values, form } = props;
  const dispatch = useDispatch();
  function deleteTableElement(element) {
    dispatch(
      show("ConfirmModal", {
        onSubmit: async () => {
          //   const { payload } = await GlobalAuthFetch({
          //     path: `/attachments/deleteFolder/${folder.uuid}`,
          //     method: "DELETE",
          //   });
          //   if (payload?.status >= 300 || !payload?.status) {
          //     return;
          //   }

          //   loadData();
          NotificationManager.success(t("global:success"));
          dispatch(hide("ConfirmModal"));
        },
      })
    );
  }
  function editTableElement(element) {
    dispatch(
      show("SecretariatCorespondencyNoteModal", {
        initialValues: element,
        outerForm: form,
        update: true,
        header: `${t("global:note.editNote")}`,
      })
    );
  }

  return (
    <div>
      <SecondaryTable
        // sortChange={this.sortChange}
        // searchFunction={this.searchFunction}
        search={false}
        pagination={false}
        headers={[
          {
            key: "nameAndLastNameAndDate",
            name: t("global:nameAndLastNameAndDate"),
            width: "25%",
            justifyContent: "left",
            verticalCenter: true,
          },
          {
            key: "note",
            name: t("global:secreateryCorespondence.notes"),
            width: "auto",
            justifyContent: "left",
            verticalCenter: true,
            numberOfLines: 4,
          },
          {
            key: "Buttons",
            name: "",
            width: "10%",
            justifyContent: "end",
            verticalCenter: true,
            sort: false,
          },
        ]}
        data={values?.notes?.map((element) => ({
          nameAndLastNameAndDate: (
            <TripleLine
              firstLine={`${element?.createdBy?.firstName} ${
                element?.createdBy?.lastName || ""
              }`}
              secondLine={moment(element.createdAt).format("DD/MM/YYYY")}
              thridLine={moment(element.createdAt).format("HH:mm")}
            ></TripleLine>
          ),
          note: <div style={{ fontSize: "0.875rem" }}>{element.content}</div>,
          Buttons: (
            <div style={{ display: "flex" }}>
              <IconButton onClick={() => editTableElement(element)}>
                <Icon
                  style={{ fontSize: "16px", padding: "0" }}
                  className="primary text"
                >
                  edit
                </Icon>
              </IconButton>
              <IconButton onClick={() => deleteTableElement(element)}>
                <Icon
                  style={{ fontSize: "16px", padding: "0" }}
                  className="error text"
                >
                  delete
                </Icon>
              </IconButton>
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default SecretariatCorespondenceStepThree;
