import { Modal, Step, StepLabel, Stepper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BMXButton from "../../Buttons/BMXButton/BMXButton";
import "./BMXModal.scss";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
const BMXModal = (props) => {
  const {
    open,
    onClose,
    children,
    buttons,
    header,
    steps,
    setStep,
    activeStep,
    width,
    height,
    disableStepper,
    t,
  } = props;

  const myModal = useRef();

  const closeModal = () => {
    const BMXModal = myModal.current;
    BMXModal?.classList.add("fadeOut");
    const timer = setTimeout(() => {
      onClose();
    }, 220);
    return () => clearTimeout(timer);
  };
  return (
    <Modal open={open} onClose={closeModal} disableEnforceFocus>
      <div
        ref={myModal}
        className={`BMXModal ${open ? "fadeIn" : "fadeOut"}`}
        style={{ minWidth: width }}
      >
        <div className="Header">
          <span>{header}</span>
          <CloseIcon
            className="CloseIcon"
            fontSize="small"
            data-cy="closeModal"
            onClick={closeModal}
          />
        </div>
        <div className="Body" style={{ minHeight: height }}>
          {steps && steps.length > 0 && (
            <div className="Step">
              <span className="ModalSeparator" />
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((item, index) => (
                  <Step key={item.title}>
                    <StepLabel
                      {...item}
                      title={null}
                      optional={<span>{item.description}</span>}
                      onClick={() => {
                        if (!disableStepper) {
                          setStep(index);
                        }
                      }}
                    >
                      {item.title}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <span className="ModalSeparator" />
            </div>
          )}
          <div className="BodyContent">{children}</div>
        </div>
        <div className="Buttons">
          <div>
            <BMXButton data-cy="closeModalButton" onClick={closeModal}>
              {t("global:close")}
            </BMXButton>
            {buttons}
          </div>
        </div>
      </div>
    </Modal>
  );
};

BMXModal.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  steps: PropTypes.array,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  disableStepper: PropTypes.number
};

export default compose(withTranslation())(BMXModal);
