import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import StatusForm from "../../Forms/Admin/RegistryForm/StatusForm/StatusForm";

const StatusModal = (props) => {
  const { handleHide, show, clientX, clientY, header } = props;
  const { t } = useTranslation();
 
  return (
    <BMXCardModal
      left={clientX}
      top={clientY}
      width="500px"
      height="max-content"
      header={header||t("global:addStatus")}
      open={show}
      onClose={handleHide}
    >
      <StatusForm {...props}/>
    </BMXCardModal>
  );
};

export default connectModal({ name: "statusModal" })(StatusModal);
