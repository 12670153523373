import { NotificationManager } from "react-notifications";
import { hide } from "redux-modal";
import store from "../../../../../store";
import i18n from "../../../../../Utils/i18next/i18next";
import { updateAdminRegistryByName } from "../../../../../Utils/Sockets";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
const { dispatch } = store;

export const onDelete = async (props) => {
  const { uuid, loadData, element } = props;
  const DICTIONARY_REGISTRY_NAME = "dictionary";

  const handleHide = () => dispatch(hide('ConfirmModal'))
  delete element.updatedAt

  element.dictionaryEntries = element.dictionaryEntries.filter(el => el.uuid !== uuid)

  const { payload } = await GlobalAuthFetch({
    path: `/dictionary/${element.uuid}`,
    method: "PUT",
    data: element,
  });
  if (payload?.status >= 300 || !payload?.status) {
    return;
  }

  updateAdminRegistryByName(DICTIONARY_REGISTRY_NAME);
  loadData();
  NotificationManager.success(i18n.t('global:success'));
  handleHide();
}

export const submit = async (values, props) => {
  const { handleHide, checked, loadData, update, element } = props;
  const DICTIONARY_REGISTRY_NAME = "dictionary";

  const data = values;
  delete element.updatedAt
  if (checked) {
    data.background = values?.background?.hex || "#fff";
    data.font = values?.font?.hex || "#fff";
  }

  if (!checked) {
    data.background = null;
    data.font = null;
  }

  if (update) {
    element.dictionaryEntries = element.dictionaryEntries.filter(el => el.uuid !== data.uuid)
  }

  element.dictionaryEntries.push(data);

  const { payload } = await GlobalAuthFetch({
    path: `/dictionary/${element.uuid}`,
    method: "PUT",
    data: element,
  });
  if (payload?.status >= 300 || !payload?.status) {
    return;
  }

  updateAdminRegistryByName(DICTIONARY_REGISTRY_NAME);
  loadData();
  NotificationManager.success(i18n.t('global:success'));
  handleHide();
};

export const validate = (values, props) => {
  const errors = {};
  const { initialValues, element } = props;
  if (!values.entryName) {
    errors.entryName = i18n.t("global:dictionary.errors.entryName");
  }

  if (element?.dictionaryEntries?.map(el => el?.entryName)?.includes(values?.entryName)) {
    if (initialValues?.entryName !== values.entryName) {
      errors.entryName = i18n.t("global:dictionary.errors.entryNameDuplicate");
    }
  }

  return errors;
};
