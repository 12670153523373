import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import BMXButton from "../../../../../Components/Buttons/BMXButton/BMXButton";
import { Icon, Tooltip, createFilterOptions, IconButton } from "@mui/material";
import BMXTabsSelect from "../../../../../Components/Inputs/BMXTabsSelect/BMXTabsSelect";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { get } from "lodash";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import {
  correspondenceType,
  deliveredAsType,
  envelopeSizeList,
  statusType,
} from "../../../../../Utils/GlobalVariables/Secretary/SecretaryType";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import moment from "moment/moment";
import { useSecretaryCorespondenceFormContext } from "../../../../../Contexts/SecretaryCorespondenceFormContext";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../../Utils/GlobalVariables/Fetch/MethodsType";
import "./SecretariatCorespondenceStepOne.scss";
import BMXPopover from "../../../../../Components/BMXPopover/BMXPopover";
import { downloadFromBloob } from "../../../../../Utils/Attachments/Attachments";

const SecretariatCorespondenceStepOne = (props) => {
  const { t } = useTranslation();
  const {
    form,
    values,
    storageLocations,
    contactValues,
    attachmentValues,
    subject,
    emailHtml,
  } = props;
  const [kpNumber, setKwNumber] = useState(values?.corespondenceNumber?.number);
  const { setIsSendActive, setEnvelopeSize, loadData } =
    useSecretaryCorespondenceFormContext();
  const [returnAttachment, setReturnAttachment] = useState(
    values?.returnAttachment
  );

  const inputRef = useRef(null);
  const { expectReturnAttachment } = values;

  let tempStatusHistory = values.statusHistory;
  tempStatusHistory = [...(tempStatusHistory || [])]?.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const correspondenceTypeData = correspondenceType();
  const deliveredAsTypeData = deliveredAsType();
  const statusTypeData = statusType();
  const filter = createFilterOptions();

  const statusList = [
    {
      name: statusTypeData.DRAFT.name,
      value: statusTypeData.DRAFT.value,
    },
    {
      name: statusTypeData.SENDED.name,
      value: statusTypeData.SENDED.value,
    },
    {
      name: statusTypeData.TOSEND.name,
      value: statusTypeData.TOSEND.value,
    },
  ];

  useEffect(() => {
    for (const key in contactValues) {
      form.change(key, get(contactValues, key, null));
    }
    // eslint-disable-next-line
  }, [contactValues]);

  useEffect(() => {
    form.change("attachments", attachmentValues);
    // eslint-disable-next-line
  }, [attachmentValues]);

  useEffect(() => {
    form.change("subject", subject);
    // eslint-disable-next-line
  }, [subject]);

  useEffect(() => {
    form.change("returnAttachment", returnAttachment);
    // eslint-disable-next-line
  }, [returnAttachment]);

  useEffect(() => {
    form.change("text", emailHtml);
    // eslint-disable-next-line
  }, [emailHtml]);

  const addNextAttachment = async (el) => {
    const {
      target: { files },
    } = el;
    setReturnAttachment((prevValues) => [...prevValues, ...files]);
    inputRef.current.value = "";
  };

  const giveNumberKW = async () => {
    // TODO GIVING NUMBER KW FUNC
    const { payload } = await GlobalAuthFetch({
      path: "/correspondenceNumber/KW",
      method: FetchMethodType.post,
    });
    if (!Boolean(payload?.status) || payload?.status >= 400) {
      return;
    }
    const { data } = payload;
    form.change("correspondenceNumber", { number: data?.number });
  };

  const deleteReturnAttachment = async (el) => {
    setReturnAttachment((state) =>
      state.filter((item) => item.name !== el.name)
    );
    if (el instanceof File) {
      return;
    }
    await GlobalAuthFetch({
      path: `/outgoingCorrespondence/deleteReturnAttachmentFile/${el?.uuid}`,
      method: FetchMethodType.delete,
    });
    loadData();
  };

  const downloadReturnAttachment = async (el) => {
    if (el instanceof File) {
      downloadFromBloob(el, el?.name);
      return;
    }
    const {
      payload: { data },
    } = await GlobalAuthFetch({
      path: `/attachments/${el?.uuid}`,
      addons: { responseType: "blob" },
    });
    if (Boolean(data)) {
      downloadFromBloob(data, el?.name);
    }
  };

  const setKw = () => {
    const kwValue = get(
      form.getState(),
      "values.correspondenceNumber.number",
      null
    );
    setKwNumber(kwValue);
  };

  useEffect(() => {
    //Sterowanie pojawianiem się przycisku wyślij i druku koperty
    setIsSendActive(values.sendAs === deliveredAsTypeData.EMAIL.value);
    // eslint-disable-next-line
  }, [values.sendAs]);

  useEffect(() => {
    setEnvelopeSize(values.envelopeType);
    // eslint-disable-next-line
  }, [values.envelopeType]);

  useEffect(() => {
    setKw();
    // eslint-disable-next-line
  }, [form]);

  return (
    <div className="SecretariatCorespondenceStepOne mx-3">
      <Row>
        <Col
          className="d-flex justify-content-start align-items-center"
          xs={{ span: 3 }}
        >
          {kpNumber ? (
            <BMXButton
              data-cy="numberButton"
              id="numberButton"
              disabled
              style={{ fontWeight: 700, textTransform: "none" }}
            >
              {kpNumber}
            </BMXButton>
          ) : (
            <BMXButton
              data-cy="setKWValue"
              onClick={giveNumberKW}
              style={{ fontWeight: 700, textTransform: "none" }}
              startIcon={
                <Icon style={{ fontSize: "15px" }}>email_plus_outline</Icon>
              }
              bmxclass={"Secondary"}
            >
              {t("global:SecretaryCorespondence.giveNumberKw")}
            </BMXButton>
          )}
        </Col>
        <Col xs={{ span: 5 }}>
          <Field
            component={BMXSelectField}
            label={`${t("global:SecretaryCorespondence.status")}`}
            name="status"
            data-cy="status"
            values={statusList.map((e) => ({
              value: e.value,
              name: `${e.name}`,
            }))}
            afterIcon={
              <BMXPopover
                disabled={tempStatusHistory?.length <= 0}
                icon={
                  <Tooltip
                    title={t("global:SecretaryCorespondence.history-status")}
                    placement="top"
                  >
                    <Icon
                      disabled={tempStatusHistory?.length <= 0}
                      className="primary text"
                      style={{ fontSize: "22px" }}
                    >
                      pending_actions
                    </Icon>
                  </Tooltip>
                }
              >
                {tempStatusHistory?.map((item, index) => (
                  <div className="content" key={index}>
                    <span>
                      {`${moment(item.createdAt).format("DD.MM.YYYY")} ${
                        item.createdBy.firstName
                      } ${item.createdBy.lastName} ${t(
                        "global:SecretaryCorespondence.text-change"
                      )} ${
                        statusList.find((e) => e.value === item.value).name
                      }`}
                    </span>
                  </div>
                ))}
              </BMXPopover>
            }
          />
        </Col>

        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:SecretaryCorespondence.sendDate")}`}
            name="sendDate"
            data-cy="sendDate"
          />
        </Col>
      </Row>
      <Row className="extraLarge">
        <Col xs={8} className="extraLarge">
          <Field
            component={BMXTabsSelect}
            label={`${t("global:SecretaryCorespondence.correspondenceType")}`}
            name="correspondenceType"
            data-cy="correspondenceType"
            tabsData={[
              correspondenceTypeData.LETTERS,
              correspondenceTypeData.INVOICE,
              correspondenceTypeData.OFFERS,
              correspondenceTypeData.ADVERTISEMENT,
              correspondenceTypeData.BIN,
            ]}
          />
        </Col>
        <Col xs={4}>
          <Row className="deliveryAttachmentRow">
            <Col className="d-flex align-items-start" xs={12}>
              <Field
                fieldtype="checkbox"
                component={BMXTextField}
                name="expectReturnAttachment"
                label={t("global:SecretaryCorespondence.deliveryNote")}
              />
              {expectReturnAttachment && returnAttachment?.length === 0 && (
                <IconButton component="label">
                  <input
                    ref={inputRef}
                    onChange={addNextAttachment}
                    single
                    hidden
                    type="file"
                  />
                  <Icon className="grey text" style={{ fontSize: "18px" }}>
                    attach_file
                  </Icon>
                </IconButton>
              )}
            </Col>

            {returnAttachment &&
              returnAttachment?.map((el) => (
                <div className="deliveryNoteAttachmentItem">
                  <Icon
                    onClick={() => downloadReturnAttachment(el)}
                    className="grey text"
                    style={{ fontSize: "14px", padding: "0" }}
                  >
                    attach_file
                  </Icon>
                  <div
                    className="attachmentName"
                    onClick={() => downloadReturnAttachment(el)}
                  >
                    <span className="grey text ">{el?.name}</span>
                  </div>
                  <Icon
                    className="error text icon"
                    style={{ fontSize: "14px", paddingLeft: "2px" }}
                    onClick={() => deleteReturnAttachment(el)}
                  >
                    delete
                  </Icon>
                </div>
              ))}
          </Row>
        </Col>
      </Row>
      <Row className="extraLarge">
        <Col xs={12}>
          <Field
            component={BMXTabsSelect}
            label={`${t("global:SecretaryCorespondence.sendAs")}`}
            name="sendAs"
            data-cy="sendAs"
            tabsData={[
              deliveredAsTypeData.EMAIL,
              deliveredAsTypeData.FAX,
              deliveredAsTypeData.LETTER,
              deliveredAsTypeData.REGISTERED,
              deliveredAsTypeData.PACKAGE,
              deliveredAsTypeData.MESSENGER,
              deliveredAsTypeData.OTHER,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 6, offset: 6 }}>
          <Field
            component={BMXTextField}
            label={`${t("global:SecretaryCorespondence.documentNumber")}`}
            name="documentNumber"
            data-cy="documentNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={BMXTextField}
            label={`${t("global:SecretaryCorespondence.letterConcerns")}`}
            name="letterConcerns"
            data-cy="letterConcerns"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 6 }}>
          <Field
            component={BMXSelectField}
            label={`${t("global:SecretaryCorespondence.envelopeType")}`}
            name="envelopeType"
            data-cy="envelopeType"
            values={envelopeSizeList.map((e) => ({
              value: e.value,
              name: `${e.name}`,
            }))}
          />
        </Col>
        <Col xs={{ span: 6 }}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:SecretaryCorespondence.storageLocation")}`}
            name="storageLocation"
            data-cy="storageLocation"
            options={storageLocations}
            currentValues={values}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              if (filtered?.length <= 0)
                form.change("storageLocation", inputValue);
              return filtered;
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SecretariatCorespondenceStepOne;
