import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import {
  EmailValidators,
  PhoneValidators,
} from "../../../../Utils/Validators/Validators";
const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = values;

  let payload;

  if (!update) {
    const res = await GlobalAuthFetch({
      path: `/branch/${uuid}`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/branch`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.name) {
    errorsStep0.name = t("global:branches.errors.name");
  }
  if (!values.city) {
    errorsStep0.city = t("global:branches.errors.city");
  }
  if (!values.postCode) {
    errorsStep0.postCode = t("global:branches.errors.postCode");
  }
  if (!values.address) {
    errorsStep0.address = t("global:branches.errors.address");
  }

  if (Boolean(values.email) && EmailValidators(values.email)) {
    errorsStep0.email = t("global:branches.errors.emailValid");
  }

  if (Boolean(values.phoneNumber) && PhoneValidators(values.phoneNumber)) {
    errorsStep0.phoneNumber = t("global:branches.errors.phoneNumberValid");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
