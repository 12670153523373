import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import plLocale from "date-fns/locale/pl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./BMXDatePicker.scss";
import PropTypes from "prop-types";

const BMXDatePicker = (props) => {
  const isError = () => {
    const {
      meta: { touched, error, submitError },
    } = props;
    return !!((touched && error) || submitError);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const {
    input,
    className,
    label,
    minDate,
    maxDate,
    readOnly,
    meta: { error, submitError },
  } = props;

  const ref = useRef();

  return (
    <div className={`BMXDatePicker ${className || ""}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
        <DatePicker
          ref={ref}
          readOnly={readOnly}
          {...input}
          {...props}
          label={label}
          disableToolbar
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="dd-MM-yyyy"
          renderInput={(innerProps) => (
            <TextField
              autoComplete="off"
              type="date"
              onClick={() => {         
                ref.current
                  .querySelector('[aria-label*="Choose date"]')
                  ?.click();
              }}
              {...props}
              {...innerProps}
              onKeyDown={onKeyDown}
              readOnly={true}
              id="standard-basic"
              variant="standard"
              error={isError()}
              helperText={isError() ? error || submitError : ""}
            />
          )}
        />
      </LocalizationProvider>
      {!isError() && <span className="BottomBar" />}
    </div>
  );
};
BMXDatePicker.propTypes = {
  /**
    Props zawiera nazwe pola
  */
  label: PropTypes.string,
  /**
    Props zawiera minimalny zakres daty tj. Od kiedy
  */
  minDate: PropTypes.string,
  /**
    Props zawiera maksymalny zakres daty tj. Do kiedy
  */
  maxDate: PropTypes.string,
  /**
    Props zawiera informacje czy pole jest aktywne czy nie
  */
  readOnly: PropTypes.bool,
};
export default BMXDatePicker;
