import TextField from "@mui/material/TextField";
import "./BMXTextField.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";

const NumericCustom = (innerProps) => {
  const { onChange, input, ...rest } = innerProps;
  const [innerValue, setInnerValue] = useState(input?.value || null);

  return (
    <NumericFormat
      {...rest}
      {...input}
      onBlur={() => {
        onChange(innerValue);
      }}
      onValueChange={(values) => {
        setInnerValue(values?.floatValue);
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
    />
  );
};

const BMXTextField = (props) => {
  const isError = () => {
    const {
      meta: { touched, error, submitError },
    } = props;
    return !!((touched && error) || submitError);
  };

  const {
    className,
    input,
    meta: { error, submitError },
    fieldtype,
    font_color,
    readOnly,
    label,
    nonDecimale,
  } = props;
  const { type } = input;

  const isCheckbox = fieldtype === "checkbox";
 

  return (
    <div className={`BMXTextField ${className || ""}`}>
      {isCheckbox ? (
        <FormControlLabel
          className="BMXCheckbox"
          control={
            <Checkbox
              label="Custom icon"
              style={{ color: font_color }}
              disabled={readOnly}
              {...props}
              {...input}
            />
          }
          label={label}
          checked={input?.value}
        />
      ) : (
        <TextField         
          id="standard-basic"
          variant="standard"
          error={isError()}
          inputProps={{ readOnly: readOnly }}
          helperText={isError() ? error || submitError : ""}
          onKeyDown={(e) => {
            if (type === "number") {
              if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
                //wykluczenie e + -
                e.preventDefault();
              }
              if (
                nonDecimale &&
                (e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 110) //wykluczenie liczb przecinkowych
              ) {
                e.preventDefault();
              }
            }
            if (type === "phone") {
              // eslint-disable-next-line
              if (
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                e.code === "Space" ||
                e.key === "-" ||
                e.key === "." ||
                e.key === "Backspace" ||
                e.key === "+" ||
                e.key === "Delete"
              )
                return e;
              e.preventDefault();
            }
            return e;
          }}
          InputProps={{
            endAdornment:
              type === "money" ? (
                <InputAdornment position="end">
                  <span style={{ fontSize: "12px" }}>PLN</span>
                </InputAdornment>
              ) : null,
            inputComponent: type === "money" ? NumericCustom : null,
            inputProps: { ...props, ...input },
          }}
          {...props}
          {...input}   
          
        />
      )}
      <span className="BottomBar" />
    </div>
  );
};

export default BMXTextField;

BMXTextField.propTypes = {
  /**
      Props zawiera kolor czcionki 
    */
  font_color: PropTypes.string,
  /**
    Props zawiera informacje czy pole jest aktywne czy nie
  */
  readOnly: PropTypes.bool,
  /**
    Props zawiera nazwe pola
  */
  label: PropTypes.string,
  /**
    Props zawiera nazwę klasy która pozwala nam np modyfikować style css
  */
  className: PropTypes.string,
  /**
    Props określa czy pole jest checkboxem
  */
  fieldtype: PropTypes.string,
};
