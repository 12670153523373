import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import FolderForm from "../../Forms/Admin/RegistryForm/FolderForm/FolderForm";

const FolderModal = (props) => {
  const { handleHide, show, clientX, clientY, header } = props;
  const { t } = useTranslation();
 
  return (
    <BMXCardModal
      left={clientX}
      top={clientY}
      width="300px"
      height="max-content"
      header={header||t("global:addFolder")}
      open={show}
      onClose={handleHide}
    >
      <FolderForm {...props}/>
    </BMXCardModal>
  );
};

export default connectModal({ name: "folderModal" })(FolderModal);
