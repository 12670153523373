import React from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./SecretaryCorspondencyNoteFormSubmit";
import { Col, Row } from "react-bootstrap";
import './SecretaryCorspondencyNoteForm.scss'
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";
import { useTranslation } from "react-i18next";

const SecretaryCorspondencyNoteForm = (props) => {
    const { initialValues, formName } = props;
    const { t } = useTranslation();
    return (
        <Form
            initialValues={initialValues}
            onSubmit={(val) => submit(val, props)}
            validate={validate}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="SecretaryCorspondencyNoteForm" id={formName}>
                    <Row>


                        <Col style={{ marginTop: '1rem' }} xs={12}>
                            <Field
                            data-cy="noteText"
                                multiline={true}
                                minRows={2}
                                maxRows={6}
                                component={BMXTextField}
                                label={`${t("global:secretaryCorespondence.addNote.content")}`}
                                name="content"
                            />
                        </Col>

                    </Row>



                </form>
            )}
        </Form>
    );
};

export default SecretaryCorspondencyNoteForm;
