export const submit = (values, props) => {
  const { handleHide, setContactValues, setSubject } = props;

  const data = {
    contact: values.contact,
    branch: values.branch,
    representative: values.representative,
  };

  setContactValues(data);
  setSubject(values.subject);
  handleHide();
};

export const validate = (values) => {
  const errors = {};

  // if (!values.name) {
  //     errors.name = t('global:fieldRequired');
  // }

  // if (!values.status) {
  //     errors.status = t('global:fieldRequired');
  // }

  return errors;
};
