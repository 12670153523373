import { Form } from "react-final-form";
import {
  onSubmit,
  onValidate,
} from "./SecretariatCorespondenceFormSendedSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import "./SecretariatCorespondenceFormSended.scss";
import { useSecretaryCorespondenceFormContext } from "../../../../Contexts/SecretaryCorespondenceFormContext";
import SecretariatCorespondenceStepOne from "./SecretaryCorespondenceSendedSteps/SecretaryCorespondenceStepOne";
import SecretariatCorespondenceStepTwo from "./SecretaryCorespondenceSendedSteps/SecretaryCorespondenceStepTwo";
import SecretariatCorespondenceStepThree from "./SecretaryCorespondenceSendedSteps/SecretaryCorespondenceStepThree";
import { useEffect, useState } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { Icon } from "@mui/material";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useTranslation } from "react-i18next";

const SecretariatCorespondenceFormSended = () => {
  const theme = useTheme();
  const {
    isSubmit,
    step,
    setStep,
    initialValues,
    formName,
    handleHide,
    updateSocketSecretary,
    setErrorHeader,
    steps,
    contactValues,
    subject,
    attachmentValues,
    update,
    loadData: outerLoadData,
    emailHtml,
    sendMail,
    setDisableButton,
    setContactError
  } = useSecretaryCorespondenceFormContext();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [workers, setWorkers] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);

  const loadData = async () => {
    const workersFetch = GlobalAuthFetch({ path: "/worker/all" });
    const storageFetch = GlobalAuthFetch({ path: "/storageLocation" });

    const { 0: workersPayload, 1: storagePayload } = await Promise.all([
      workersFetch,
      storageFetch,
    ]);
    setWorkers(
      workersPayload?.payload?.data?.map((el) => ({
        name: `${el.firstName} ${el.lastName}`,
        value: el.uuid,
      }))
    );
    setStorageLocations(
      storagePayload?.payload?.data?.map((el) => ({
        label: el.value,
        uuid: el.uuid,
      }))
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  function openModalAddTransfer(props) {
    dispatch(show("SecretariatTransferModal", props));
  }
  function openModalAddNote(props) {
    dispatch(show("SecretariatCorespondencyNoteModal", props));
  }
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) =>
        onSubmit(val, {
          isSubmit,
          handleHide,
          updateSocketSecretary,
          setErrorHeader,
          update,
          loadData: outerLoadData,
          sendMail,
          setDisableButton,
          setContactError
        })
      }
      validate={(val) => onValidate(val, { isSubmit, setErrorHeader, steps })}
    >
      {({ handleSubmit, values, form }) => {
        return (
          <form
            id={formName}
            onSubmit={handleSubmit}
            className="SecretariatCorespondenceFormSended"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <SecretariatCorespondenceStepOne
                attachmentValues={attachmentValues}
                contactValues={contactValues}
                storageLocations={storageLocations}
                subject={subject}
                workers={workers}
                form={form}
                values={values}
                emailHtml={emailHtml}
              />
              <SecretariatCorespondenceStepTwo form={form} values={values} />
              <SecretariatCorespondenceStepThree form={form} values={values} />
            </SwipeableViews>
            {step === 1 && (
              <BMXButton
                data-cy="addTransferTo"
                bmxclass={"Success Add_To_Table"}
                onClick={() =>
                  openModalAddTransfer({
                    header: t("global:secretaryCorespondence.transferTo.title"),
                    outerForm: form,
                    update: update,
                  })
                }
                buttontype="Fab"
              >
                <Icon>add</Icon>
              </BMXButton>
            )}
            {step === 2 && (
              <BMXButton
                data-cy="addNote"
                bmxclass={"Success Add_To_Table"}
                onClick={() =>
                  openModalAddNote({
                    header: t("global:secretaryCorespondence.addNote.title"),
                    outerForm: form,
                    update: update,
                  })
                }
                buttontype="Fab"
              >
                <Icon>add</Icon>
              </BMXButton>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default SecretariatCorespondenceFormSended;
