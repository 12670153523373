import { useTranslation } from "react-i18next";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";

const RentContractDeviceFormStepTwo = (props) => {
  const { view } = props;
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContractDevice.additional")} />
      <Row>
        <Col>
          <Field
            disabled={view}
            name="comments"
            label={`${t("global:RentContractDevice.comments")}`}
            component={BMXTextField}
            data-cy="comments"
            maxRows={17}
            multiline={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractDeviceFormStepTwo;
