import i18n from "../../../../../Utils/i18next/i18next";
import store from "../../../../../store";
import { updateRegistryMenu } from "../../../../../Redux/Reducers/SocketReducer";
import { NotificationManager } from "react-notifications";
import { updateAdminRegistry } from "../../../../../Utils/Sockets";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { get } from "lodash";
const { dispatch } = store;
const { t } = i18n;

export const onSubmit = async (values, props) => {
  const updateRegistryTable = () => updateAdminRegistry();
  const updateSingleRegistry = (uuid) => updateAdminRegistry(uuid);
  const updateMenu = () => dispatch(updateRegistryMenu());

  delete values?.availableFolders?.icons;
  delete values?.defaultStatus?.icons;
  const { statuses, folders, update } = props;

  const data = {
    registry: {
      ...values,
      defaultManager: { uuid: get(values, "defaultManager.value", null) },
      defaultStatusFiles:
        Object.keys(values.defaultStatusFiles).length > 0
          ? values.defaultStatusFiles?.value
          : values.defaultStatusFiles,
      isActive:
        Object.keys(values.isActive).length > 0
          ? values.isActive?.value
          : values.isActive,
      availableManagers: [],
      availableStatuses: statuses.map((el) => ({
        uuid: el?.uuid,
        name: el?.name,
        isDeleted: el?.isDeleted,
        status: el?.status?.value || el?.status,
        isSelected: el.name === values?.defaultStatus?.name,
        color: {
          uuid: el?.uuid,
          background: el?.color?.background?.hex || el?.color?.background,
          font: el?.color?.font?.hex || el?.color?.font,
        },
      })),
      defaultFolders: folders,
    },
    availableManagersList:
      values.availableManagers && values.availableManagers.length > 0
        ? values.availableManagers.map((el) => el.value)
        : [],
  };

  if (update) {
    const {
      match: {
        params: { uuid },
      },
    } = props;
    const { payload } = await GlobalAuthFetch({
      path: `/registry/${uuid}`,
      method: "PUT",
      data: data,
    });

    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }

    props.setErrorHeader(0, false);
    //Succes nofify
    props.loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
    updateSingleRegistry(uuid);
  }

  if (!update) {
    const { payload } = await GlobalAuthFetch({
      path: "/registry",
      method: "POST",
      data: data,
    });

    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      return;
    }

    props.setErrorHeader(0, false);
    //Succes nofify
    props.loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
  }
  updateRegistryTable();
  updateMenu();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.name) {
    errorsStep0.name = t("global:fieldRequired");
  }
  if (!values.index) {
    errorsStep0.index = t("global:fieldRequired");
  }
  if (!values.url) {
    errorsStep0.url = t("global:fieldRequired");
  }
  if (!values.icon) {
    errorsStep0.icon = t("global:fieldRequired");
  }
  if (!values.defaultStatus) {
    errorsStep0.defaultStatus = t("global:fieldRequired");
  }
  if (!values.defaultStatusFiles) {
    errorsStep0.defaultStatusFiles = t("global:fieldRequired");
  }
  if (!values.availableManagers?.length > 0) {
    errorsStep0.availableManagers = t("global:fieldRequired");
  }

  if (!values.defaultManager?.value) {
    errorsStep0.defaultManager = t("global:fieldRequired");
  }
  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
