import { Checkbox, Icon, IconButton, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import "./BMXSearchField.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

const BMXSearchField = (props) => {
  const { t } = useTranslation();
  const [openSearchFields, setOpenSearchFields] = useState(false);

  const { resetTable, disableSearchFieldsIcon, disableResetButton } = props;
  const openSearchFieldsRef = useRef();
  const buttonSearchFieldsRef = useRef();

  const inputButtons = (
    <>
      {!disableSearchFieldsIcon && (
        <Tooltip title={t("global:edit-search-text")} placement="top">
          <IconButton
            ref={buttonSearchFieldsRef}
            onClick={() => {
              setOpenSearchFields(!openSearchFields);
            }}
          >
            <Icon className="SettingsIcon" fontSize={"small"}>
              settingsIcon
            </Icon>
          </IconButton>
        </Tooltip>
      )}
      {!disableResetButton && (
        <Tooltip title={t("global:reset-text")} placement="top">
          <IconButton onClick={resetTable}>
            <Icon className="LoopIcon" fontSize={"small"}>
              loopIcon
            </Icon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const clickDetectOpenSearchField = (e) => {
    if (
      openSearchFieldsRef?.current?.contains(e?.target) ||
      buttonSearchFieldsRef?.current?.contains(e?.target)
    )
      return;

    setOpenSearchFields(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickDetectOpenSearchField);

    return () => {
      document.removeEventListener("mousedown", clickDetectOpenSearchField);
    };
  }, []);

  const enterCatch = (e) => {
    const { search } = props;
    if (e.keyCode !== 13) {
      return;
    }
    onSearchChange(search.trim());
  };

  const {
    className,
    search,
    onSearchChange,
    searchFields,
    onSelectSearchField,
    selectedSearchFields,
  } = props;

  return (
    <div className={`BMXSearchField ${className || ""}`} onKeyDown={enterCatch}>
      <TextField
        InputProps={{
          endAdornment: inputButtons,
        }}
        id="standard-basic"
        variant="standard"
        {...props}
        value={search}
      />
      <Tooltip title={t("global:confirm-text")} placement="top">
        <IconButton onClick={() => onSearchChange(search.trim())}>
          <Icon className="SearchIcon" fontSize={"medium"}>
            searchIcon
          </Icon>
        </IconButton>
      </Tooltip>

      {openSearchFields && (
        <Paper
          ref={openSearchFieldsRef}
          onMouseDown={clickDetectOpenSearchField}
          className="SearchFieldsArea"
          elevation={3}
        >
          <p className="pa-2 ma-0"> {t("global:searchByField")} </p>
          {searchFields.map((el) => (
            <div className="ps-2" key={el.key}>
              <Checkbox
                id={el.key}
                checked={selectedSearchFields.includes(el.key)}
                onChange={() => {
                  onSelectSearchField(el.key);
                }}
              />
              <label htmlFor={el.key}>{t(el.name)}</label>
            </div>
          ))}
        </Paper>
      )}
    </div>
  );
};

export default BMXSearchField;

BMXSearchField.propTypes = {
  /**
    Props zawiera nazwę klasy która pozwala nam np modyfikować style css
  */
  className: PropTypes.string,
  /**
    Props zawiera ...
  */
  search: PropTypes.string,
  /**
    Props zawiera ...
  */
  onSearchChange: PropTypes.func,
  /**
    Props zawiera ...
  */
  searchFields: PropTypes.array,
  /**
    Props zawiera ...
  */
  onSelectSearchField: PropTypes.func,
  /**
    Props zawiera ...
  */
  selectedSearchFields: PropTypes.array,
};
BMXSearchField.defaultProps = {
  onSearchChange: () => {},
};
