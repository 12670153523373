import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BMXFetch from "../../../Utils/GlobalFetch/GlobalFetch";
import Cookies from "js-cookie";
import { get } from "lodash";
import axios from "axios";
import { FetchMethodType } from "../../../Utils/GlobalVariables/Fetch/MethodsType";

const initialState = {
  auth: true,
  loading: false,
};

const deleteCredentials = () => {
  Cookies.remove("BMX");
  Cookies.remove("BMX_ID");
};

export const AuthFetch = createAsyncThunk(
  "AuthFetch",
  async (
    {
      path = "",
      method = FetchMethodType.get,
      data = null,
      addons = {},
      disableError = false,
    } = {},
    { dispatch }
  ) => {
    try {
      const token = `Bearer ${Cookies.get("BMX")}`;
      const fetch = new BMXFetch(path, {
        method: method,
        addons: addons,
      }).setHeaders({ Authorization: token });
      if (!!data) fetch.setBody(data);
      const res = await fetch.send();
      const refreshToken = get(res, 'headers["refresh-token"]', null);
      const BMX_ID = get(res, 'headers["bmx_id"]', null);
      if (Boolean(refreshToken) && Boolean(BMX_ID)) {
        const options = {
          secure: true,
          sameSite: "strict",
          expires: 1,
        };
        Cookies.set("BMX", refreshToken, options);
        Cookies.set("BMX_ID", BMX_ID, options);
      }
      return res;
    } catch (err) {
      //Jeśli token się skonczy wylogowuje
      if (
        method === FetchMethodType.get &&
        [401, 403].includes(err?.response?.status)
      ) {
        dispatch(setNoAuth());
        return deleteCredentials();
      }
      if (disableError) {
        return;
      }
      // WYŁĄCZENIE KOMUNIKATÓW BŁĘDÓW DLA ZAPYTAŃ, KTÓRE ZOSTAŁY PRZERWANE
      if (axios.isCancel(err)) {
        return;
      }
      throw err?.response?.data;
    }
  }
);

const userSesstionReducer = createSlice({
  name: "AuthFetchReducer",
  initialState,
  reducers: {
    setAuth: (state) => {
      state.auth = true;
    },
    setNoAuth: (state) => {
      state.auth = false;
    },
  },
  extraReducers: {},
});

export const { setAuth, setNoAuth } = userSesstionReducer.actions;

export default userSesstionReducer.reducer;
