import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Icon, Tooltip } from "@mui/material";
const RadioButtonGroup = (props) => {
  const { label, options, input, disabled } = props;

  return (
    <FormControl disabled={disabled} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={label} name="radio-buttons-group" row {...input}>
        {options.map((option) => (
          <Tooltip key={option.value} title={option.tooltip || ""}>
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  icon={
                    option?.icon ? (
                      <Icon
                        className=" text mt-1"
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginRight: "3px",
                          color: "grey",
                        }}
                      >
                        {option.icon}
                      </Icon>
                    ) : undefined
                  }
                  checkedIcon={
                    option?.checkedIcon ? (
                      <Icon
                        className=" text mt-1"
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginRight: "3px",
                        }}
                      >
                        {option.checkedIcon}
                      </Icon>
                    ) : undefined
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#19aa8d",
                    },
                  }}
                />
              }
              label={option.label}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
