import "./BMXColorPicker.scss";
import { ChromePicker } from "react-color";
import React, { Component } from "react";
import PropTypes from 'prop-types';
class BMXColorPicker extends Component {
  isError = () => {
    const {
      meta: { touched, error, submitError },
    } = this.props;
    return !!((touched && error) || submitError);
  };

  render() {
    const { label, className, input } = this.props;
    return (
      <div className={`BMXColorPicker ${className || ""}`}>
        <span>{label}</span>
        <ChromePicker disableAlpha={true} {...this.props} {...input} color={input.value.hsv||input.value.hex||input.value||'#fff'} />
      </div>
    );
  }
}
BMXColorPicker.propTypes = {    
  /**
    Props zawiera nazwe klasy
  */ 
    className: PropTypes.string,     
  /**
    Props zawiera blokowanie kanału alfa????
  */ 
    disableAlpha: PropTypes.bool,    
  /**
    Props zawiera komponent input
  */ 
    input: PropTypes.node,    
};
export default BMXColorPicker;
