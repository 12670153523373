import { Chip } from "@mui/material";
import "./BMXChip.scss";
import propTypes from "prop-types";

const BMXChip = ({ label, size, font, backgroundColor }) => {
  return (
    <Chip
      className="BMXChip"
      size={size}
      style={{ color: font, backgroundColor: backgroundColor }}
      label={label}
    />
  );
};

BMXChip.prototype = {
  /**
   Props zawiera dane nazwę danych które będą w chipie
  */ 
  label: propTypes.string,
  /**
   Props zawiera rozmiar 
  */ 
  size: propTypes.string,
  /**
   Props zawiera kolor czcionki
  */ 
  font: propTypes.string,
  /**
   Props zawiera kolor tła chipa 
  */ 
  backgroundColor: propTypes.string,
};

export default BMXChip;
