import { Icon, InputAdornment } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const ChangePasswordFormStepOne = (props) => {
  const { values } = props;
  const { t } = useTranslation();
  const [showPasswords, setShowPasswords] = useState(false);
  return (
    <div className="justify-content-start">
      <BMXSeparator title={t("global:changePassword")} />
      <Row>
        <Col>
          <Field
            className="ChangePasswordField my-2"
            name="oldPassword"
            type={showPasswords ? "text" : "password"}
            label={t("global:oldPassword")}
            component={BMXTextField}
            count={values?.oldPassword?.length || 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility
                    </Icon>
                  )}
                  {showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility_off
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            className="ChangePasswordField my-2"
            name="newPassword"
            type={showPasswords ? "text" : "password"}
            label={t("global:newPassword")}
            component={BMXTextField}
            count={values?.newPassword?.length || 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility
                    </Icon>
                  )}
                  {showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility_off
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            className="ChangePasswordField my-2"
            name="repetPassword"
            type={showPasswords ? "text" : "password"}
            label={t("global:repetPassword")}
            component={BMXTextField}
            count={values?.repetPassword?.length || 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility
                    </Icon>
                  )}
                  {showPasswords && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      visibility_off
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      {/* <Row>
        <div className="d-flex justify-content-end my-3">
          <span className="error text">{t("global:reminder")}</span>
        </div>
      </Row> */}
    </div>
  );
};

export default ChangePasswordFormStepOne;
