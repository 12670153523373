import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { env } from '../../env';

i18n.use(initReactI18next).init({
  resources: {},
  lng: 'pl',
});

export const loadLanguage = () =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(
        `${env.REACT_APP_TRANSLATION_SERVER}/api/v2/language`
      );
      const resources = await res.json();
      if (res.status === 200) {
        for (let lang in resources) {
          if (resources[lang] && Object.keys(resources[lang]).length > 0) {
            for (let namespace in resources[lang]) {
              i18n.removeResourceBundle(lang,namespace)
              i18n.addResourceBundle(
                lang,
                namespace,
                resources[lang][namespace]
              );
            }
          } 
        }
      }

      for (let lang in resources) {
        if (Object.keys(i18n.getDataByLanguage(lang)).length <= 0) {
          loadLanguage();
        }
      }

      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

  export default i18n;