import { Icon, LinearProgress } from "@mui/material";
import { withTranslation } from "react-i18next";
import "./AddAttachmentsFormStepOne.scss";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import FileIcon from "./../../../../../Components/FileIcon/FileIcon";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";
import { isEqual } from "lodash";

class AddAttachmentsFormStepOne extends PureComponent {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();

    this.state = {
      selectedFile: [],
      dragging: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.props;
    const { selectedFile } = this.state;
    if (isEqual(prevState.selectedFile, selectedFile)) {
      values.selectedFile = selectedFile;
    }
  }

  handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: true });
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });

    this.setState((prevState) => ({
      selectedFile: [...prevState.selectedFile, ...e.dataTransfer.files],
    }));
  };

  onFileChange = (event) => {
    this.setState((prevState) => ({
      selectedFile: [...prevState.selectedFile, ...event.target.files],
    }));
  };
  deleteFile = (e, index) => {
    const { selectedFile } = this.state;
    this.setState({
      selectedFile: selectedFile.filter((e, idx) => idx !== index),
    });
    const dt = new DataTransfer();
    const input = document.getElementById("file");
    const { files } = input;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file);
    }
    input.files = dt.files;
  };
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <>
          {this.state.selectedFile.map((file, index) => (
            <FileIcon
              file={file}
              key={index}
              deleted={(e) => this.deleteFile(e, index)}
            ></FileIcon>
          ))}
        </>
      );
    }
  };

  render() {
    const { t } = this.props;
    const { dragging } = this.state;
    const { uploadProgress } = this.props;
    return (
      <div className="AddAttachmentsFormStepOne">
        <Row>
          <Col md={12}>
            <label
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.preventDefault();
                this.onSelectFile(e);
              }}
              htmlFor="file"
              className="FileArea"
            >
              <div
                className={`IconArea file-uploader ${
                  dragging ? "dragging" : ""
                }`}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
              >
                <Icon className="icon" fontSize="large">
                  insert_drive_file
                </Icon>
                <span className="attachmentsLine"></span>
                <h4>{t("global:attachments.attachmentsAction")}</h4>
              </div>
              <input
                id="file"
                multiple
                type="file"
                ref={this.fileInput}
                style={{ display: "none" }}
                accept="*"
                onChange={this.onFileChange}
              />
            </label>
          </Col>
        </Row>
        <div id="fileList">{this.fileData()}</div>
        <Row>
          <Col xs={11}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Col>
          {uploadProgress !== 0 && (
            <Col xs={1}>
              <span className="uploadProgress">{uploadProgress} %</span>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <span className="summaryAttachments">
                  {t("global:attachments.attachmentsNumber")}:{" "}
                  {this.state?.selectedFile.length}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <span className="summaryAttachments">
                  {t("global:attachments.attachmentsWeight")}:{" "}
                  {this.state?.selectedFile.reduce(
                    (accum, item) => accum + item.size,
                    0
                  )}{" "}
                  Bytes
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Field
              name="wwwLink"
              component={BMXTextField}
              label={t("global:settings.wwwLink")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(withTranslation())(AddAttachmentsFormStepOne);
