import { set } from "lodash";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import i18n from "../../../../../Utils/i18next/i18next";
import { EmailValidators, PhoneValidators } from "../../../../../Utils/Validators/Validators";

export const onSubmit = async (values, props) => {
    const { updateTableRegistry, loadData, update, updateSingleRegistry } = props;
    const data = Object.values(values).filter(el=> typeof el === 'object');
    let payload;
  
    if (!update) {   
        // możliwe do rozbudowania
    }
  
    if (update) {       
      const res = await GlobalAuthFetch({
        path: `/settings`,
        method: "PATCH",
        data,
      });
      payload = res.payload;
    }
  
    if (payload?.status >= 300 || !payload?.status) {
      props.setErrorHeader(0, true);
      props.setErrorHeader(1, true);

  
      return;
    }
  
    props.setErrorHeader(0, false);
    props.setErrorHeader(1, false);

    //Succes nofify
    loadData();
    NotificationManager.success(i18n.t("global:success"));
    props.handleHide();
    if (updateSingleRegistry) updateSingleRegistry();
    if (updateTableRegistry) updateTableRegistry();
}

export const onValidate = (values, props) => {
    const errorsStep0 = {};
    const errorsStep1 = {};


    if (Boolean(values.SECRETARIAT_EMAIL?.value) && EmailValidators(values.SECRETARIAT_EMAIL?.value)) {    
        set(errorsStep0,'SECRETARIAT_EMAIL.value',i18n.t('global:settings.SECRETARIAT_EMAIL_ERROR'));   
    }
    if (Boolean(values.CLIENT_ADMINISTRATOR_EMAIL?.value) && EmailValidators(values.CLIENT_ADMINISTRATOR_EMAIL?.value) ) {       
        set(errorsStep0,'CLIENT_ADMINISTRATOR_EMAIL.value',i18n.t('global:settings.CLIENT_ADMINISTRATOR_EMAIL_ERROR'));   
    }
    if (Boolean(values.COMPANY_PHONE?.value) && PhoneValidators(values.COMPANY_PHONE?.value)) {       
        set(errorsStep0,'COMPANY_PHONE.value',i18n.t('global:settings.COMPANY_PHONE_ERROR'));   
    }
    if (Boolean(values.COMPANY_EMAIL?.value) && EmailValidators(values.COMPANY_EMAIL?.value)) {       
        set(errorsStep0,'COMPANY_EMAIL.value',i18n.t('global:settings.COMPANY_EMAIL_ERROR'));   
    }  
    if (!values.LICENSES_NUMBER?.value) {       
        set(errorsStep1,'LICENSES_NUMBER.value',i18n.t('global:licensesNumber_is_required'));
    }
    if (!values.SESSION_TIME?.value) {
        set(errorsStep1,'SESSION_TIME.value',i18n.t('global:sessionTime_is_required'));
    }
    if (!values.LOGIN_ATTEMPTS?.value) {
        set(errorsStep1,'LOGIN_ATTEMPTS.value',i18n.t('global:loginAttempts_is_required'));
    }

    if (
        !props.steps[0]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep0).length > 0
      ) {
        props.setErrorHeader(0, true);
      }
      if (
        !!props.steps[0]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep0).length <= 0
      ) {
        props.setErrorHeader(0, false);
      }
      if (
        !props.steps[1]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep1).length > 0
      ) {
        props.setErrorHeader(1, true);
      }
      if (
        !!props.steps[1]?.error &&
        props.isSubmit &&
        Object.keys(errorsStep1).length <= 0
      ) {
        props.setErrorHeader(1, false);
      }

    return {...errorsStep0,...errorsStep1};
}