import { Modal } from "@mui/material";
import BMXButton from "../../Buttons/BMXButton/BMXButton";
import "./BMXConfirmModal.scss";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const BMXConfirmModal = (props) => {
  const { open, onClose, children, buttons, width, height, cancel } = props;

  const myModal = useRef();
  const { t } = useTranslation();
  const closeModal = () => {
    const BMXConfirmModal = myModal.current;
    BMXConfirmModal?.classList.add("fadeOut");
    const timer = setTimeout(() => {
      onClose();
    }, 220);
    return () => clearTimeout(timer);
  };
  return (
    <Modal open={open} onClose={closeModal} disableEnforceFocus>
      <div
        ref={myModal}
        className={`BMXConfirmModal ${open ? "fadeIn" : "fadeOut"}`}
        style={{ minWidth: width }}
      >
        <div className="Body" style={{ minHeight: height }}>
          {children}
        </div>
        <div className="Buttons">
          <div>
            <BMXButton className="darkGrey background" onClick={closeModal}>
              {`${t(cancel)}`}
            </BMXButton>
            {buttons}
          </div>
        </div>
      </div>
    </Modal>
  );
};
BMXConfirmModal.defaultProps = {
  onClose: () => {},
  cancel: "global:cancel",
};
BMXConfirmModal.propTypes = {
  /**
   Props zawiera główny content 
  */
  children: PropTypes.node,
  /**
   Props zawiera informacje o zamknieciu modala
  */
  onClose: PropTypes.func,
  /**
   Props zawiera informacje o otwarciu modala
  */
  open: PropTypes.bool,
  /**
   Props zawiera informacje o przyciskach
  */
  buttons: PropTypes.func,
  /**
   Props zawiera szerokość okna
  */
  width: PropTypes.string,
  /**
   Props zawiera wysokość okna
  */
  height: PropTypes.string,
  /**
   Props zawiera tekst przycisku anulowania 
  */
  cancel: PropTypes.string,
};

export default BMXConfirmModal;
