import store from "../../../../../store";
import i18n from "../../../../../Utils/i18next/i18next";
import crypto from "crypto-js";
import moment from "moment";
import { hide } from "redux-modal";
import { getTranslationsAllKeys } from "../../../../../Utils/Translations/Translations";
import { NotificationManager } from "react-notifications";
import { env } from "../../../../../env";
import { updateAdminRegistryByName } from "../../../../../Utils/Sockets";

const { dispatch } = store;

export const onDelete = async ({ key, loadData }) => {
  const TRANSLATION_REGISTRY_NAME = "translations";
  const onHideConfirmModal = () => dispatch(hide("ConfirmModal"));
  const secret = crypto
    .HmacSHA512(
      `${moment().format("DD-MM-YYYY mm")}`,
      env.REACT_APP_LANGUAGE_SECRET
    )
    .toString();
  const payload = await fetch(
    `${env.REACT_APP_TRANSLATION_SERVER}/api/v2/language/delete/${key}`,
    {
      headers: {
        authorization: secret,
      },
      method: "DELETE",
    }
  );

  if (payload?.status >= 300 || !payload?.status) {
    return;
  }
  NotificationManager.success(i18n.t("global:success"));
  onHideConfirmModal();
  const timeout = setTimeout(()=>{
    loadData();
    updateAdminRegistryByName(TRANSLATION_REGISTRY_NAME);
    clearTimeout(timeout);
  },1500)
};

export const onSubmit = async (values, props) => {
  const TRANSLATION_REGISTRY_NAME = "translations";
  const { loadData, handleHide } = props;
  const secret = crypto
    .HmacSHA512(
      `${moment().format("DD-MM-YYYY mm")}`,
      env.REACT_APP_LANGUAGE_SECRET
    )
    .toString();

  const payload = await fetch(
    `${env.REACT_APP_TRANSLATION_SERVER}/api/v2/language/upload`,
    {
      headers: {
        authorization: secret,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        translation: {...values,key:values?.key?.trim()},
      }),
      method: "POST",
    }
  );

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return;
  }
  
  NotificationManager.success(i18n.t("global:success"));
  handleHide();
  const timeout = setTimeout(()=>{
    loadData();
    updateAdminRegistryByName(TRANSLATION_REGISTRY_NAME);
    clearTimeout(timeout);
  },1500)
 
};

export const onValidate = (values, props) => {
  const { update } = props;
  const errorsStep0 = {};
  if (!update) {
    if (!values.key) {
      errorsStep0.key = i18n.t("global:required");
    }
    if (values.key) {
      const keys = getTranslationsAllKeys().map((el) => {
        const val = el.split(".");
        val.shift();
        return val.join(".");
      });
      keys.forEach((key) => {
        if (key === values.key) {
          errorsStep0.key = i18n.t("global:keyInUse");
        }
      });
    }
  }
  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
