import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const BranchesFormStepOne = (props) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <BMXSeparator title={t("global:branches.informationAboutContact")} />
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.city")}*`}
            name="city"
            data-cy="city"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.postCode")}*`}
            name="postCode"
            data-cy="postCode"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.address")}*`}
            name="address"
            data-cy="address"
          />
        </Col>
      </Row>
      <BMXSeparator title={t("global:branches.moreInformation")} />

      <Row style={{ marginBottom: "5%" }}>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.phoneNumber")}`}
            name="phoneNumber"
            data-cy="phoneNumber"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:branches.email")}`}
            name="email"
            data-cy="email"
          />
        </Col>
      </Row>
    </div>
  );
};

export default BranchesFormStepOne;
