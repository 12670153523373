import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const DepartmentsFormStepTwo = (props) => {
  const { initialValues, values } = props;
  const { t } = useTranslation();
  const [initUsers, setInitUsers] = useState([]);
  const [superiorDepartmentSelect, setSuperiorDepartmentSelect] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchWorker = await GlobalAuthFetch({ path: '/worker/all' })
      const fetchDepart = await GlobalAuthFetch({ path: '/department/all' })

      const {0:usersPayload,1:departmentPayload } = await Promise.all([fetchWorker,fetchDepart]);

      const {payload: { data:users }} = usersPayload;

      const {payload: { data:department }} = departmentPayload;
      
      setSuperiorDepartmentSelect(department.map(e=>({
        value: e.uuid,
        name: e.name,
      })));

      setInitUsers(users.map(e => ({
        value: e.uuid,
        name: `${e.firstName || ""} ${e.lastName || ""}`,
      })));
    };
    fetchData();

  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:departments.responsiblePeople")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={superiorDepartmentSelect}
            label={t("global:departments.superiorDepartment") + "*"}
            name="superiorDepartment"
            data-cy="superiorDepartment"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={initUsers?.map((el) => ({
              name: el.name,
              value: el.value,
            }))}
            label={t("global:departments.leader") + "*"}
            name="leader"
            data-cy="leader"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "5%" }}>
        <Col xs={12}>
          <Field
            component={BMXSelectField}
            data-cy="users"
            values={initUsers}
            initialValues={{
              users: initialValues?.users?.map((e) => ({
                value: e.uuid,
                name: `${e.firstName || ""} ${e.lastName || ""}`,
              })),
            }}
            selectedValues={values}
            multiple
            displayEmpty
            label={`${t("global:departments.users")}`}
            name="users"
            renderValue={(el) => {
              return el.map((e) => e.name).join(", ");
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentsFormStepTwo;
