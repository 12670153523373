import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: [],
};

const settingsReducer = createSlice({
  name: "settingsReducer",
  initialState,
  reducers: {
    addSettings: (state, { payload }) => {
      state.settings = payload;
    },
  },
  extraReducers: {},
});

export const { addSettings } = settingsReducer.actions;

export default settingsReducer.reducer;
