import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";

const ChangePasswordFormStepOne = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Col>    
        <Row>
            <Col> 
              <BMXSeparator  title={t('global:settings.licences')}></BMXSeparator>
            </Col> 
        </Row>
        <Row>
          <Col > 
            <Field
              data-cy="licensesNumber" 
              name="LICENSES_NUMBER.value"    
              type="number"             
              component={BMXTextField}       
              label={t('global:settings.licensesNumber')}
            />
          </Col>        
        </Row>  
        <Row>
            <Col> 
              <BMXSeparator title={t('global:settings.security')}></BMXSeparator>
            </Col> 
        </Row>
        <Row>
          <Col md={4}> 
            <Field
              data-cy="sessionTime" 
              name="SESSION_TIME.value"   
              type="number"              
              component={BMXTextField}       
              label={t('global:settings.sessionTime')}
            />
          </Col>        
          <Col md={4}> 
            <Field
              data-cy="loginAttempts" 
              name="LOGIN_ATTEMPTS.value"     
              type="number"         
              component={BMXTextField}       
              label={t('global:settings.loginAttempts')}
            />
          </Col>        
        </Row>         
      </Col>  
    </div>
  );
};

export default ChangePasswordFormStepOne;
