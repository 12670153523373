import { Icon } from "@mui/material";
import { withTranslation } from "react-i18next";
import "./AlertFormStepOne.scss";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { Component } from "react";
import { isEqual } from "lodash";
import BMXTimePicker from "../../../../../Components/Inputs/BMXTimePicker/BMXTimePicker";
class AlertFormStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      piorityArray: [],
    };
  }

  clearField = (field) => {
    const { form } = this.props;
    form.change(field, null);
  };

  componentDidUpdate(prevProps) {
    const { form, values, piorityArray } = this.props;

    if (values?.reapetNumber !== prevProps.values?.reapetNumber) {
      const val = parseInt(values?.reapetNumber);
      if (!isNaN(val)) form.change("reapetNumber", val.toString());
    }
    if (values?.notifyNumber !== prevProps.values?.notifyNumber) {
      const val = parseInt(values?.notifyNumber);
      if (!isNaN(val)) form.change("notifyNumber", val.toString());
    }
    if (!isEqual(piorityArray, prevProps.piorityArray)) {
      this.setState(() => ({
        piorityArray: piorityArray?.map((el) => ({
          value: el?.uuid,
          name: el?.entryName,
        })),
      }));
    }
  }

  render() {
    const { piorityArray } = this.state;
    const { t } = this.props;
    return (
      <div className="AlertFormStepOne">
        <Row>
          <Col>
            <BMXSeparator title={t("global:alert.newAlert")} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {/* InsertInvitation
            AccessTime
            Notifications
            Close
            Person
            PeopleAlt
            */}
            <Field
              name="alertData"
              data-cy="alertData"
              minDate={new Date()}
              component={BMXDatePicker}
              label={t("global:alert.alertData")}
            />
          </Col>
          <Col md={4}>
            <Field
              name="alertTime"
              data-cy="alertTime"
              component={BMXTimePicker}
              label={t("global:alert.alertTime")}
            />
          </Col>
          <Col md={4}>
            <Field
              name="priority"
              data-cy="priority"
              label={t("global:alert.priority")}
              component={BMXSelectField}
              values={piorityArray}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="text"
              data-cy="text"
              component={BMXTextField}
              label={t("global:alert.text")}
              multiline={true}
              maxRows={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BMXSeparator
              miniSeparator={true}
              title={t("global:alert.notificationOptions")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Row>
              <Col md={12}>
                <div className="d-flex">
                  <Icon className="dark-grey text">event_repeat</Icon>
                  <span className="d-block mx-2 dark-grey text">
                    {t("global:alert.repeatEvery")}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <Field
                  name="reapetNumber"
                  type="number"
                  data-cy="reapetNumber"
                  component={BMXTextField}
                  label={t("global:alert.reapetNumber")}
                />
              </Col>
              <Col md={8}>
                <Field
                  name="reapetValue"
                  data-cy="reapetValue"
                  label={t("global:alert.reapetValue")}
                  afterIconClick={() => this.clearField("reapetValue")}
                  className="clearSelect"
                  afterIcon={"close"}
                  component={BMXSelectField}
                  values={[
                    { value: "min", name: t("global:alert.min") },
                    { value: "hour", name: t("global:alert.hour") },
                    { value: "days", name: t("global:alert.days") },
                    { value: "weeks", name: t("global:alert.weeks") },
                    { value: "months", name: t("global:alert.months") },
                    { value: "years", name: t("global:alert.years") },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row>
              <Col md={12}>
                <div className="d-flex">
                  <Icon className="dark-grey text">notifications</Icon>
                  <span className="d-block mx-2 dark-grey text">
                    {t("global:alert.notifyBefore")}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <Field
                  name="notifyNumber"
                  type="number"
                  data-cy="notifyNumber"
                  component={BMXTextField}
                  label={t("global:alert.notifyNumber")}
                />
              </Col>
              <Col md={8}>
                <Field
                  name="notifyValue"
                  data-cy="notifyValue"
                  label={t("global:alert.notifyValue")}
                  className="clearSelect"
                  component={BMXSelectField}
                  afterIconClick={() => this.clearField("notifyValue")}
                  afterIcon={"close"}
                  values={[
                    { value: "min", name: t("global:alert.min") },
                    { value: "hour", name: t("global:alert.hour") },
                    { value: "days", name: t("global:alert.days") },
                    { value: "weeks", name: t("global:alert.weeks") },
                    { value: "months", name: t("global:alert.months") },
                    { value: "years", name: t("global:alert.years") },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col md={2}>
            <Field
              name="active"
              data-cy="active"
              fieldtype="checkbox"
              component={BMXTextField}
              label={t("global:alert.isActive")}
            ></Field>
          </Col>
        </Row>
      </div>
    );
  }
}
export default compose(withTranslation())(AlertFormStepOne);
