import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./NoteFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import NoteFormStepOne from "./NoteFormSteps/NoteFormStepOne";
import "./NoteForm.scss";

const NoteForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues } = props;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="NoteForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <NoteFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default NoteForm;
