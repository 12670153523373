import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./GlobalAlertFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import GlobalAlertFormStepOne from "./GlobalAlertFormSteps/GlobalAlertFormStepOne";

const GlobalAlertForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update,item } = props;
  const [initialVal,setInitialVal] = useState();

  useEffect(()=>{
    if(update){      
      setInitialVal(item)
      return;
    }

    setInitialVal({active:true})

  },[item,update])

  
  return (
    <Form
      initialValues={initialVal}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => (
        <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="GlobalAlertForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <GlobalAlertFormStepOne {...formProps} {...props} />
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default GlobalAlertForm;
