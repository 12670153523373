import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./BMXSecretaryModal.scss";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
const BMXSecretaryModal = (props) => {
  const { open, onClose, children, buttons, header, width, height } = props;

  const myModal = useRef();

  const closeModal = () => {
    const BMXSecretaryModaldal = myModal.current;
    BMXSecretaryModaldal?.classList.add("fadeOut");
    const timer = setTimeout(() => {
      onClose();
    }, 220);
    return () => clearTimeout(timer);
  };
  return (
    <Modal open={open} onClose={closeModal} disableEnforceFocus>
      <div
        ref={myModal}
        className={`BMXSecretaryModal ${open ? "fadeIn" : "fadeOut"}`}
        style={{ minWidth: width }}
      >
        <div className="Header">
          <span className="Header-text">{header}</span>
          <CloseIcon
            className="CloseIcon"
            fontSize="small"
            data-cy="closeModal"
            onClick={closeModal}
          />
        </div>

        <div className="Body" style={{ minHeight: height }}>
          {children}
        </div>
        <div className="Buttons">
          <div>{buttons}</div>
        </div>
      </div>
    </Modal>
  );
};

BMXSecretaryModal.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  steps: PropTypes.array,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  disableStepper: PropTypes.number,
};

export default compose(withTranslation())(BMXSecretaryModal);
