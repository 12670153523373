import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./AttachmentsFromStorageFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import AttachmentsFromStorageFormStepOne from "./AttachmentsFromStorageFormSteps/AttachmentsFromStorageFormStepOne";

const AddAttachmentsStorageForm = (props) => {
  const theme = useTheme();
  const { step, setStep } = props;
  return (
    <Form
      initialValues={{}}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => (
        <form id={props.formName} onSubmit={formProps.handleSubmit}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AttachmentsFromStorageFormStepOne {...formProps} {...props} />
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AddAttachmentsStorageForm;
