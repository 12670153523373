import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../../Utils/i18next/i18next";
const { t } = i18n;
const initialState = {
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [{ key: "key", name: t("gloabl:key") }],
    selectedSearchFields: ["key"],
  },
};

const translationReducer = createSlice({
  name: "translationReducer",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearTranslation: () => initialState,
  },
  extraReducers: {},
});

export const { updateRegistry, clearTranslation } =
  translationReducer.actions;

export default translationReducer.reducer;
