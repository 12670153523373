import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./TranslationFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import TranslationFormStepOne from "./TranslationFormSteps/TranslationFormStepOne";


const TranslationForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues } = props;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val)=> onValidate(val, props)}
    >
      {(formProps) => {
        return <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="TranslationForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
              
            <TranslationFormStepOne {...formProps} {...props}/>
          </SwipeableViews>
        </form>
      }}
    </Form>
  );
};

export default TranslationForm;
