import { useState } from "react";


export const useSwiper = ({ maxPage = 99, initState = 0 } = {}) => {

    const [step, setStep] = useState(initState);

    const goNext = (callBack) => {
        setStep((state) => state >= maxPage ? maxPage : state + 1);
        if (callBack) {
            callBack();
        }
    }
    const goPrev = () => {
        setStep((state) => state <= 0 ? 0 : state - 1);
    }

    return [step, goNext, goPrev];
}