import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./AlertFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import AlertFormStepOne from "./AlertFormSteps/AlertFormStepOne";
import AlertFormStepTwo from "./AlertFormSteps/AlertFormStepTwo";
import "./AlertForm.scss";
import { useEffect, useState } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import moment from "moment";

const AlertForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues, update } = props;
  const [priority, setPriority] = useState();
  const [workers, setWorkers] = useState();
  const [departments, setDepartments] = useState();
  const [initialVal,setInitialVal] = useState();

  useEffect(()=>{
    if(update){    
      const {alertTime} = initialValues;
      const time = alertTime.split(":");
      const hours = Number(time[0]);
      const minute = Number(time[1]);
      const second = Number(time[2]);
      setInitialVal({...initialValues,alertTime:moment().set("hour",hours).set("minute",minute).set('second',second)})
      return;
    }

    setInitialVal({active:true})

  },[initialValues,update])

  const loadData = async (signal) => {
    const priorityFetch = GlobalAuthFetch({
      path: "/dictionary/priority/fieldName",
      addons: { signal },
    });
    const workerFetch = GlobalAuthFetch({
      path: "/worker/all",
      addons: { signal },
    });
    const departmentFetch = GlobalAuthFetch({
      path: "/department/all",
      addons: { signal },
    });

    const {0:{payload:priority}, 1:{payload:worker},2:{payload:department}} = await Promise.all([priorityFetch, workerFetch,departmentFetch])
    setPriority(priority?.data?.dictionaryEntries);
    setWorkers(worker?.data)
    setDepartments(department?.data)
  };

  useEffect(() => {
    const controller = new AbortController();
    loadData(controller.signal);
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialVal}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => (
        <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="AlertForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AlertFormStepOne {...formProps} {...props} piorityArray={priority} />
            <AlertFormStepTwo {...formProps} {...props} workers={workers} departments={departments} />
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AlertForm;
