import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import BMXButton from "../../../../../Components/Buttons/BMXButton/BMXButton";
import { Icon, createFilterOptions } from "@mui/material";
import BMXTabsSelect from "../../../../../Components/Inputs/BMXTabsSelect/BMXTabsSelect";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "lodash";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import {
  correspondenceType,
  deliveredAsType,
  priorityType,
} from "../../../../../Utils/GlobalVariables/Secretary/SecretaryType";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { FetchMethodType } from "../../../../../Utils/GlobalVariables/Fetch/MethodsType";

const SecretariatCorespondenceStepOne = (props) => {
  const { t } = useTranslation();
  const {
    form,
    values,
    workers,
    storageLocations,
    contactValues,
    attachmentValues,
    subject,
  } = props;
  const [kpNumber, setKpNumber] = useState(values?.corespondenceNumber?.number);
  const correspondenceTypeData = correspondenceType();
  const deliveredAsTypeData = deliveredAsType();
  const priorityTypeData = priorityType();
  const filter = createFilterOptions();

  useEffect(() => {
    for (const key in contactValues) {
      form.change(key, get(contactValues, key, null));
    }
    // eslint-disable-next-line
  }, [contactValues]);

  useEffect(() => {
    form.change("attachments", attachmentValues);
    // eslint-disable-next-line
  }, [attachmentValues]);

  useEffect(() => {
    form.change("subject", subject);
    // eslint-disable-next-line
  }, [subject]);

  const setCorrespondenceNumber = async () => {
    const { payload } = await GlobalAuthFetch({
      path: "/correspondenceNumber/KP",
      method: FetchMethodType.post,
    });

    if (!Boolean(payload?.status) || payload?.status >= 400) {
      return;
    }

    const { data } = payload;
    form.change("correspondenceNumber", { number: data?.number });
  };

  const setKP = () => {
    const kpValue = get(
      form.getState(),
      "values.correspondenceNumber.number",
      null
    );
    setKpNumber(kpValue);
  };

  useEffect(() => {
    setKP();
    // eslint-disable-next-line
  }, [form]);

  return (
    <div className="SecretariatCorespondenceStepOne mx-3">
      <Row>
        <Col
          className="d-flex justify-content-start align-items-center"
          xs={{ span: 5 }}
        >
          {kpNumber ? (
            <BMXButton
              data-cy="numberButton"
              id="numberButton"
              disabled
              style={{ fontWeight: 700, textTransform: "none" }}
            >
              {kpNumber}
            </BMXButton>
          ) : (
            <BMXButton
              data-cy="setKPValue"
              onClick={setCorrespondenceNumber}
              style={{ fontWeight: 700, textTransform: "none" }}
              startIcon={<Icon style={{ fontSize: "15px" }}>add</Icon>}
              bmxclass={"Secondary"}
            >
              {t("global:SecretaryCorespondence.addKP")}
            </BMXButton>
          )}
        </Col>

        <Col xs={{ span: 4, offset: 3 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:SecretaryCorespondence.dateAndTimeOfReceipt")}`}
            name="dateAndTimeOfReceipt"
            data-cy="dateAndTimeOfReceipt"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="extraLarge">
          <Field
            component={BMXTabsSelect}
            label={`${t("global:SecretaryCorespondence.correspondenceType")}`}
            name="correspondenceType"
            data-cy="correspondenceType"
            tabsData={[
              correspondenceTypeData.LETTERS,
              correspondenceTypeData.INVOICE,
              correspondenceTypeData.OFFERS,
              correspondenceTypeData.ARCHIVE,
              correspondenceTypeData.ADVERTISEMENT,
              correspondenceTypeData.SPAM,
              correspondenceTypeData.BIN,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="extraLarge">
          <Field
            component={BMXTabsSelect}
            label={`${t("global:SecretaryCorespondence.deliveredAs")}`}
            name="deliveredAs"
            data-cy="deliveredAs"
            tabsData={[
              deliveredAsTypeData.EMAIL,
              deliveredAsTypeData.FAX,
              deliveredAsTypeData.LETTER,
              deliveredAsTypeData.REGISTERED,
              deliveredAsTypeData.PACKAGE,
              deliveredAsTypeData.MESSENGER,
              deliveredAsTypeData.OTHER,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:SecretaryCorespondence.dateOfLetter")}`}
            name="dateOfLetter"
            data-cy="dateOfLetter"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:SecretaryCorespondence.documentNumber")}`}
            name="documentNumber"
            data-cy="documentNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={BMXTextField}
            label={`${t("global:SecretaryCorespondence.letterConcerns")}`}
            name="letterConcerns"
            data-cy="letterConcerns"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            values={workers}
            label={`${t("global:SecretaryCorespondence.recipient")}`}
            component={BMXSelectField}
            name="recipient"
            data-cy="recipient"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            values={[
              priorityTypeData.IMPORTANT,
              priorityTypeData.NONURGENT,
              priorityTypeData.TOCLARIFY,
              priorityTypeData.TOFORWARD,
              priorityTypeData.URGENT,
            ]}
            component={BMXSelectField}
            label={`${t("global:SecretaryCorespondence.priority")}`}
            name="priority"
            data-cy="priority"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 6, offset: 6 }}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:SecretaryCorespondence.storageLocation")}`}
            name="storageLocation"
            data-cy="storageLocation"
            options={storageLocations}
            currentValues={values}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              if (filtered?.length <= 0)
                form.change("storageLocation", inputValue);
              return filtered;
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SecretariatCorespondenceStepOne;
