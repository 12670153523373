import { withTranslation } from "react-i18next";
import "./AddAttachmentsFolderFormStepOne.scss";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import { Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";

class AddAttachmentsFormStepOne extends PureComponent { 

  render() {
    const { t } = this.props;
    return (                     
        <div className="AddAttachmentsFolderFormStepOne">
          <Row>
            <Col md={12}> 
            <Field
                name="name"
                component={BMXTextField}       
                label={t('global:attachments.folderName')}
              />
            </Col>   
          </Row>      
          <Row>
            <Col md={12}> 
            <Field
                name="description"
                component={BMXTextField}       
                label={t('global:attachments.folderDescription')}
              />
            </Col>   
          </Row>      
        </div>        
    );
  }
}

export default compose(withTranslation())(AddAttachmentsFormStepOne);
