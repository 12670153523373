import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { set } from "lodash";
const { t } = i18n;


export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, update, updateSingleRegistry } = props;
  const data = values;
  set(data,"contract.uuid", data?.contract?.value)
  set(data,"dayJob.uuid", data?.dayJob?.value)
  let payload;

  const {match:{params:{uuid}}}=props;

  if (!update) {
    // data.status = workerReducer?.availableStatuses.find(
    //   (el) => !!el.isSelected
    // );

    // const res = await GlobalAuthFetch({
    //   path: `/worker`,
    //   method: "POST",
    //   data,
    // });
    // payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/worker/${uuid}/updateStaff`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(t("global:success"));
  props.handleHide();
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  return { ...errorsStep0, ...errorsStep1 };
};
