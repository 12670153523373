import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./OfferFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import OfferFormStepOne from "./OfferFormSteps/OfferFormStepOne";
import { useEffect, useState } from "react";

const OfferForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        offerType: _initialValues?.offerType?.uuid
          ? { value: _initialValues?.offerType?.uuid }
          : null,
        customerType: _initialValues?.customerType?.uuid
          ? { value: _initialValues?.customerType?.uuid }
          : null,
      });
      return;
    }

    setInitialValues({});
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="OfferForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <OfferFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default OfferForm;
