import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const CarFormStepOne = (props) => {
  const { t } = useTranslation();
  const [modelSelect, setModelSelect] = useState([]);
  const [typeOfCarSelect, setTypeOfCarSelect] = useState([]);
  const [driverSelect, setDriverSelect] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const modelFetch = GlobalAuthFetch({
        path: "/dictionary/model/fieldName",
      });
      const typeOfCarFetch = GlobalAuthFetch({
        path: "/dictionary/typeOfCar/fieldName",
      });
      const driversFetch = GlobalAuthFetch({ path: "/worker/all" });

      const [modelPayload, typeOfCarPayload, driversPayload] =
        await Promise.all([modelFetch, typeOfCarFetch, driversFetch]);
      setTypeOfCarSelect(typeOfCarPayload?.payload?.data?.dictionaryEntries);
      setModelSelect(modelPayload?.payload?.data?.dictionaryEntries);
      setDriverSelect(driversPayload?.payload?.data);
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:car.carData")} />
      <Row>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={modelSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:car.model")}*`}
            name="model"
            data-cy="model"
          />
        </Col>
        <Col xs={4}>
          <Field
            name="registrationNumber"
            label={`${t("global:car.registrationNumber")}*`}
            component={BMXTextField}
            data-cy="registrationNumber"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={typeOfCarSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:car.typeOfCar")}`}
            name="typeOfCar"
            data-cy="typeOfCar"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            name="registrationIDNumber"
            label={`${t("global:car.registrationIDNumber")}*`}
            component={BMXTextField}
            data-cy="registrationIDNumber"
          />
        </Col>
        <Col xs={4}>
          <Field
            name="vin"
            label={`${t("global:car.vin")}*`}
            component={BMXTextField}
            data-cy="vin"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 8 }}>
          <Field
            component={BMXSelectField}
            values={driverSelect?.map((el) => ({
              name: `${el.firstName} ${el.lastName}`,
              value: el.uuid,
            }))}
            label={`${t("global:car.driver")}`}
            name="driver"
            data-cy="driver"
          />
        </Col>
      </Row>
    </div>
  );
};

export default CarFormStepOne;
