import { CircularProgress } from "@mui/material";
import './BMXLoader.scss'

const BMXLoader = () =>{
    return  <div className="BMXLoader"> 
                <CircularProgress/>
            </div>
}


export default BMXLoader;