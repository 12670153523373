import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXConfirmModal from "../../../../Components/Modals/BMXConfirmModal/BMXConfirmModal";
import ConfirmInvoiceModalForm from "../../ConfirmModalsForms/ConfirmInvoiceModalForm/ConfirmInvoiceModalForm";

const ConfirmInvoiceModal = (props) => {
  const { handleHide, show, confirmButtonName } = props;
  const formName = "ConfirmInvoiceModal";
  const { t } = useTranslation();

  

  const buttons = () => {
    return (
      <>
        <BMXButton
          data-cy="submit"
          type="subbmit"
          className="secondary background"
          form={formName}
        >
          {confirmButtonName ? t(`${confirmButtonName}`) : t("global:yes")}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXConfirmModal
      open={show}
      width={"500px"}
      height={"250px"}
      onClose={handleHide}
      buttons={buttons()}
      cancel="global:reckoning.no"
    >
      <ConfirmInvoiceModalForm
        {...props}
        formName={formName}
        handleHide={handleHide}
      />
    </BMXConfirmModal>
  );
};

export default connectModal({ name: "ConfirmInvoiceModal" })(
  ConfirmInvoiceModal
);
