import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import { connectModal } from "redux-modal";
import { useState } from "react";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useTranslation } from "react-i18next";
import RentContractForm from "../../Forms/RentContractForms/RentContractForm";

const RentContractModal = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:rentContract.deviceInformation"), error: false },
    { title: t("global:rentContract.options"), error: false },
    { title: t("global:rentContract.settings"), error: false },
    { title: t("global:rentContract.comments"), error: false },
  ]);
  const [isSubmit, setIsSubmit] = useState(false);
  const formName = "RentContractForm";
  const { handleHide, show, view } = props;

  const buttons = () => {
    return (
      <>
        {!view ? (
          <BMXButton
            data-cy="save"
            type="subbmit"
            onClick={() => setIsSubmit(true)}
            bmxclass="Success"
            form={formName}
          >
            {t("global:save")}
          </BMXButton>
        ) : null}
      </>
    );
  };

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={"1000px"}
      height={"650px"}
      onClose={handleHide}
      header={props.header}
      buttons={buttons()}
      steps={steps}
    >
      <RentContractForm
        steps={steps}
        isSubmit={isSubmit}
        step={step}
        setStep={setStep}
        setErrorHeader={setErrorHeader}
        handleHide={handleHide}
        formName={formName}
        {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "RentContractModal" })(RentContractModal);
