import { t } from "i18next";
import { cloneDeep, set } from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { updateDashboardByName } from "../../../../Utils/Sockets";

export const onSubmit = async (values, props) => {
  const {
    updateSingleRegistry,
    loadData,
    pathUuid,
    handleHide,
    setErrorHeader,
    update,
    initialValues,
  } = props;
  const ALERT_REGISTRY_NAME = "alert";
  const data = cloneDeep(values);
  if (values?.alertData) {
    const alertData = moment(values?.alertData);
    if (values?.alertTime) {
      const alertTime = moment(values?.alertTime)
        .format("HH:mm:ss")
        ?.split(":");
      alertData.set("hour", Number(alertTime[0]));
      alertData.set("minute", Number(alertTime[1]));
      alertData.set("second", Number(alertTime[2]));
    }
    data.alertData = alertData;
  }

  data.type = initialValues?.registryName;

  data.index = initialValues?.index;

  if (Boolean(values?.priority?.value))
    set(data, "priority.uuid", values?.priority?.value);

  data.users = values.alertFor;

  data.departments = values.alertForDepartments;

  data.description = values.text;

  data.repetitionNumber = values.reapetNumber;

  data.repetitionTime = values.reapetValue?.value;

  data.reminderNumber = values.notifyNumber;

  data.reminderTime = values.notifyValue?.value;

  data.active = Boolean(values?.active);

  let payload;

  const URL_PATH = pathUuid ? `/alert/${pathUuid}` : "/alert";

  if(Boolean(data.type) && Boolean(pathUuid)){
    data.linkTo = `${data.type}/${pathUuid}`;
  }

  if (!update) {
    const res = await GlobalAuthFetch({
      path: URL_PATH,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: URL_PATH,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    setErrorHeader(0, true);
    setErrorHeader(1, true);

    return;
  }

  setErrorHeader(0, false);
  setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  updateDashboardByName(ALERT_REGISTRY_NAME);
  handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (values.text?.length > 255) {
    errorsStep0.text = t("global:alerts.errors.alertLength");
  }

  if (!values.alertData) {
    errorsStep0.alertData = t("global:alerts.errors.alertData");
  }

  if (!values.alertTime) {
    errorsStep0.alertTime = t("global:alerts.errors.alertTime");
  }

  if (
    (!values.alertFor && !values.alertForDepartments) ||
    (values.alertFor?.length <= 0 && !values.alertForDepartments?.length > 0)
  ) {
    errorsStep1.alertFor = t("global:alerts.errors.alertFor");
  }

  if (
    (!values.alertForDepartments && !values.alertFor) ||
    (values.alertForDepartments?.length <= 0 && !values.alertFor?.length > 0)
  ) {
    errorsStep1.alertForDepartments = t(
      "global:alerts.errors.alertForDepartments"
    );
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }

  return { ...errorsStep0, ...errorsStep1 };
};
