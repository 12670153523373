import { Form } from 'react-final-form';
import { onSubmit, onValidate } from './AttachmentsFromTemplateFormSubmit';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';
import AttachmentsFromTemplateFormStepOne from './AttachmentsFromTemplateFormSteps/AttachmentsFromTemplateFormStepOne';

const AddAttachmentsTemplateForm = (props) => {
  const theme = useTheme();
 
  const { step, setStep } = props;


  return (
    <Form
      initialValues={{}}
      onSubmit={(val) => onSubmit({ ...val }, props)}
      validate={onValidate}
    >
      {({ handleSubmit, values }) => (
        <form
          id={props.formName}
          onSubmit={handleSubmit}        
        >
          <SwipeableViews            
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AttachmentsFromTemplateFormStepOne values={values} {...props}
            />         
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AddAttachmentsTemplateForm;
