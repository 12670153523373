import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Report } from "./Report/Report";


const RouterPath = [
  {
    pathName: "report",
    Component: Report,
  } 
];

export const PrintDocumentRoute = () => {
  return (
    <Router>
      <Switch>
        {RouterPath.map((el) => ({
          ...el,
          pathName: `/printDocument/${el.pathName}`,
        })).map(({ pathName, Component }) => (
          <Route exact path={pathName} render={()=><Component/>} />
        ))}
      </Switch>
    </Router>
  );
};
