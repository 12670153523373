import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;

  const data = cloneDeep({
    ...values,
    serialNumber: values?.sn?.value,
    device: { uuid: values?.sn?.uuid },
    contractType: values?.contractType?.value,
  });

  let payload;

  if (!update) {
    delete data.sn;

    const res = await GlobalAuthFetch({
      path: `/rentcontract/${uuid}/addDeviceToRentContract`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const resDev = await GlobalAuthFetch({
      path: `/device/findBySN/${data.sn}`,
    });

    delete data.sn;

    set(data, "device", resDev?.payload?.data?.at(0));

    const res = await GlobalAuthFetch({
      path: `/rentcontract/${uuid}/updateDeviceOnRentContract`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values?.sn) {
    errorsStep0.sn = t("global:RentContractDeviceFormSNError");
  }
  if (values?.billedMono && null==values?.monoCounterStart) {
    errorsStep0.monoCounterStart = t(
      "global:RentContractDeviceFormMonoCounterStartError"
    );
  }
  if (values?.billedMono && null==values?.monoSingleCost) {
    errorsStep0.monoSingleCost = t(
      "global:RentContractDeviceFormMonoSingleCostError"
    );
  }
  if (values?.billedColor && null==values?.colorCounterStart) {
    errorsStep0.colorCounterStart = t(
      "global:RentContractDeviceFormColorCounterStartError"
    );
  }
  if (values?.billedColor && null==values?.colorSingleCost) {
    errorsStep0.colorSingleCost = t(
      "global:RentContractDeviceFormColorSingleCostError"
    );
  }
  if (values?.billedScan && null==values?.scanCounterStart) {
    errorsStep0.scanCounterStart = t(
      "global:RentContractDeviceFormScanCounterStartError"
    );
  }
  if (values?.billedScan && null==values?.scanSingleCost) {
    errorsStep0.scanSingleCost = t(
      "global:RentContractDeviceFormScanSingleCostError"
    );
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
