import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import { connectModal } from "redux-modal";
import { useState } from "react";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useTranslation } from "react-i18next";
import StorageEditFileForm from "../../Forms/StorageEditFile/StorageEditFileForm";

const StorageEditFile = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const [steps, setSteps] = useState([
    { title: t("global:storage.fileInStorage"), error: false },
  ]);
  const [isSubmit, setIsSubmit] = useState(false);
  const formName = "StorageEditFile";
  const { handleHide, show } = props;

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          onClick={() => setIsSubmit(true)}
          bmxclass="Success"
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXModal
      activeStep={step}
      setStep={setStep}
      open={show}
      width={"600px"}
      height={"350px"}
      onClose={handleHide}
      header={props.header}
      buttons={buttons()}
      steps={steps}
    >
      <StorageEditFileForm
        steps={steps}
        isSubmit={isSubmit}
        step={step}
        setStep={setStep}
        setErrorHeader={setErrorHeader}
        handleHide={handleHide}
        formName={formName}
        {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "StorageEditFile" })(StorageEditFile);
