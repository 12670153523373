import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: undefined,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "index", name: "global:index" },
      { key: "name", name: "global:name" },
      { key: "currentManager", name: "global:currentManager" },
    ],
    selectedSearchFields: ["index"],
  },
};

const registryReducer = createSlice({
  name: "registryReducer",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const { updateRegistry, clearRegistry } = registryReducer.actions;

export default registryReducer.reducer;
