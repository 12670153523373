import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

const WorkerFormStepOne = (props) => {
  const { t } = useTranslation();
  const [positionSelect, setPositinSelect] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        const {payload:{data:{dictionaryEntries}}} = await GlobalAuthFetch({path:"/dictionary/position/fieldName"})
        setPositinSelect(dictionaryEntries);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <BMXSeparator title={t("global:worker.details")} />
      <Row>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:worker.firstName")}*`}
            name="firstName"
            data-cy="firstName"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:worker.lastName")}*`}
            name="lastName"
            data-cy="lastName"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:worker.id")}`}
            name="id"
            data-cy="id"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={positionSelect?.map(el=>({ name: el.entryName, value: el.uuid }))}
            label={t("global:worker.position")+"*"}
            name="position"
            data-cy="position"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:worker.mail")}*`}
            name="mail"
            data-cy="mail"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:worker.privateMail")}`}
            name="privateMail"
            data-cy="privateMail"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "20%" }}>
        <Col xs={6}>
          <Field
            type="phone"
            component={BMXTextField}
            label={`${t("global:worker.phone")}*`}
            name="phone"
            data-cy="phone"
          />
        </Col>
        <Col xs={6}>
          <Field
            type="phone"
            component={BMXTextField}
            label={`${t("global:worker.privatePhone")}`}
            name="privatePhone"
            data-cy="privatePhone"
          />
        </Col>
      </Row>
    </div>
  );
};

export default WorkerFormStepOne;
