import { Form } from "react-final-form";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import { onSubmit, onValidate } from "./DictionaryConnectFormSubmit";
import DictionaryConnectFormStepOne from "./DictionaryConnectFormSteps/DictionaryConnectFormStepOne";
import DictionaryConnectFormStepTwo from "./DictionaryConnectFormSteps/DictionaryConnectFormStepTwo";
import React,{useEffect} from 'react';

const DictionaryConnectForm = (props) => {
  const theme = useTheme();
  const { step, setStep, initialValues,selectedValues } = props;

  useEffect(()=>{
    if(Object.keys(selectedValues).includes('registry')){
        setStep(1);
    }
    // eslint-disable-next-line
},[selectedValues])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val)=> onValidate(val, props)}
    >
      {(formProps) => {
        return <form
          id={props.formName}
          onSubmit={formProps.handleSubmit}
          className="DictionaryConnectForm"
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >              
            <DictionaryConnectFormStepOne {...formProps} {...props}/>
            <DictionaryConnectFormStepTwo {...formProps} {...props}/>
          </SwipeableViews>
        </form>
      }}
    </Form>
  );
};

export default DictionaryConnectForm;
