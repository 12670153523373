import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BMXSeparator.scss';
class BMXSeparator extends Component {
  render() {
    return (
      <div className={`Separator ${this.props.miniSeparator ? 'mini' : ''}`}>
        <p>          
          {this.props.title}
          <span>{this.props.subtext}</span>
        </p>
        <span>{this.props.children}</span>
        <span>{this.props.footer}</span>
      </div>
    );
  }
}
BMXSeparator.propTypes = {
  /**
   Props określający czy separator ma być w wersji standard czy mini
  */
  miniSeparator: PropTypes.bool,
  /**
   Tytuł separatora
  */
  title:PropTypes.string,
   /**
   Mniejszy tekst znajdującu się po prawiej stronie głównego tekstu
  */
  subtext:PropTypes.string,
  /**
   Props daje możliwość przekazania informacji bądź kodu który wyświetla się w prawym rogu separatora
  */
  children:PropTypes.node,
    /**
   Props daje możliwość przekazania informacji bądź kodu który wyświetla się w prawym rogu separatora
  */
  footer: PropTypes.node
};
export default BMXSeparator;
