import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { setUser as _setUser } from "../../../../Redux/Reducers/UserSession";
export const onSubmit = async (values, props) => {
  const {
    userSession: { user },
  } = store.getState();
  const dispatch = store.dispatch;
  const setUser = (user) => dispatch(_setUser(user));
  const { payload } = await GlobalAuthFetch({
    path: `/worker/${user.uuid}/phoneUpdate`,
    method: "PUT",
    data: values,
  });
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
  }

  setUser(payload.data);
  props.setErrorHeader(0, false);
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const phoneRegex = /^\d+$/;
  if (!phoneRegex.test(values.phone)) {
    errorsStep0.phone = i18n.t("global:phoneError");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    !props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
