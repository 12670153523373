import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";
import moment from "moment";
const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { operationlockReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;

  const data = cloneDeep(values);
  // if (values?.client?.value) set(data, "client.uuid", values?.client?.value);
  if (values?.reason?.value) set(data, "reason.uuid", values?.reason?.value);

  const dataAlert = cloneDeep(data);


  dataAlert.alertData = moment().add(5, "minutes");
  dataAlert.type = "operationlock";
  dataAlert.users = values.to;
  dataAlert.departments = values.departments;
  dataAlert.description = `${moment().format("DD/MM/YYYY")} - ustawiono blokadę obsługi dla kontrahenta: ${data?.client?.name}`// i18n.t("global:operationLock.alertTextForOperationLock");

  let payload;

  if (!update) {
    data.status = operationlockReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );

    const res = await GlobalAuthFetch({
      path: `/operationlock`,
      method: "POST",
      data,
    });
    payload = res.payload;

    if (res.payload?.status !== 200) {
      return;
    }
    
    dataAlert.index = payload.data.index;
    dataAlert.linkTo = `operationlock/${payload.data.uuid}`;

    // SEND ALERT
    const respons = await GlobalAuthFetch({
      path: `/alert/${payload?.data?.uuid}`,
      method: "POST",
      data: dataAlert,
    });
    payload = respons.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/operationlock/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.client) {
    errorsStep0.client = t("global:operationLock.errors.client");
  }
  if (!values.reason) {
    errorsStep0.reason = t("global:operationLock.errors.reason");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
