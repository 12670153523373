import { t } from "i18next";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";
import { NotificationManager } from "react-notifications";
export const submit = async (values, props) => {
  const { handleHide, loadData, updateSocketSecretary } = props;
  delete values.icons;
  // TODO BACKEND SEND VALUES VIA API
  let payload;
  if (props?.update) {
    let data = {
      ...values.element,
      content: values.note,
    };

    const res = await GlobalAuthFetch({
      path: `/secretariatNotes/updateNote/${data.uuid}`,
      method: FetchMethodType.patch,
      data: data,
    });

    payload = res.payload;
  } else {
    const res = await GlobalAuthFetch({
      path: `/secretariatNotes/addNote`,
      method: FetchMethodType.post,
      data: {
        content: values.note,
      },
    });
    payload = res.payload;
  }
  if (payload?.status >= 300 || !payload?.status) {
    return;
  }
  loadData();
  updateSocketSecretary();
  handleHide();
  NotificationManager.success(t("global:success"));
};

export const validate = (values) => {
  const errors = {};

  if (!values.note) {
    errors.note = t("global:fieldRequired");
  }

  return errors;
};
