import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./TonerReleaseFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import TonerReleaseFormStepOne from "./TonerReleaseFormStep/TonerReleaseFormStepOne";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";

const TonerReleaseForm = (props) => {
  const theme = useTheme();
  const {
    step,
    setStep,
    update,
    initialValues: _initialValues,
    selectedToner,
    view,
  } = props;
  const [initialValues, setInitialValues] = useState(null);

  const dispatch = useDispatch();

  const confirmModalShow = (submit) => {
    dispatch(
      show("ConfirmModal", {
        onSubmit: async () => {
          dispatch(hide("ConfirmModal"));
          await submit();
        },
        mainText: "global:tonerRelease.deviceOnRentContractMainText",
        contentText: "global:tonerRelease.deviceOnRentContractInfromation",
        confirmButtonName: "global:continue",
      })
    );
  };

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        ...selectedToner,
        documentId: selectedToner?.releaseSupplies?.documentId
          ? selectedToner?.releaseSupplies?.documentId
          : null,
        numberWaybill: selectedToner?.releaseSupplies?.numberWaybill
          ? selectedToner?.releaseSupplies?.numberWaybill
          : null,
        selectedPerson: selectedToner?.releaseSupplies?.responsibleDriver
          ? "driver"
          : selectedToner?.releaseSupplies?.responsibleCourier
          ? "courier"
          : selectedToner?.releaseSupplies?.responsibleTechnic
          ? "technician"
          : null,
        supplieState: selectedToner?.releaseSupplies?.supplieState || "NOWY",
        acceptedAs: selectedToner?.releaseSupplies?.acceptedAs || "APP",
        biuromaxStatus: selectedToner?.releaseSupplies?.biuromaxStatus,
        createdBy: selectedToner?.releaseSupplies?.createdBy,
        comments: selectedToner?.releaseSupplies?.comments,
      });
      return;
    }
    if (view) {
      setInitialValues({
        ...selectedToner,
        documentId: selectedToner?.documentId
          ? selectedToner?.documentId
          : null,
        numberWaybill: selectedToner?.numberWaybill
          ? selectedToner?.numberWaybill
          : null,
        selectedPerson: selectedToner?.responsibleDriver
          ? "driver"
          : selectedToner?.responsibleCourier
          ? "courier"
          : selectedToner?.responsibleTechnic
          ? "technician"
          : null,
        supplieState: selectedToner?.supplieState || "NOWY",
        acceptedAs: selectedToner?.acceptedAs || "APP",
        biuromaxStatus: selectedToner?.biuromaxStatus,
        comments: selectedToner?.comments,
        createdBy: selectedToner?.createdBy,
      });
      return;
    }

    setInitialValues(_initialValues);
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) =>
        onSubmit(val, { ...props, confirmModalShow: confirmModalShow })
      }
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="TonerReleaseForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <TonerReleaseFormStepOne {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default TonerReleaseForm;
