import { createSlice } from "@reduxjs/toolkit";

export const SecretaryCorespondenceType = {
  Arrived: "incomingCorrespondence",
  Sended: "outgoingCorrespondence",
};

const initialState = {
  table: {
    TableType: SecretaryCorespondenceType.Arrived,
    isNewArrived: false,
    isNewSended: false,
    search: "",
    filterSearch: "",
    limit: 6,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      // {
      //   key: "deliveredTo.worker.lastName",
      //   name: t("global:secretaryCorespondenceReducer.deliveredTo.lastName"),
      // },
      {
        key: "contact.name",
        name: "global:secretaryCorespondenceReducer.contact.name",
      },
      {
        key: "deliveredTo.worker.firstName+lastName",
        name: "global:secretaryCorespondenceReducer.deliveredTo.name",
      },
      {
        key: "email",
        name: "global:secretaryCorespondenceReducer.email",
      },
      {
        key: "title",
        name: "global:secretaryCorespondenceReducer.title",
      },
      // {
      //   key: "correspondenceType.value",
      //   name: "global:secretaryCorespondenceReducer.correspondenceType",
      // },// Nie może tego być 
    ],
    selectedSearchFields: ["email"],
  },
};

const secretaryCorespondenceReducer = createSlice({
  name: "secretaryCorespondenceReducer",
  initialState,
  reducers: {
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const { updateRegistry, clearRegistry } =
  secretaryCorespondenceReducer.actions;

export default secretaryCorespondenceReducer.reducer;
