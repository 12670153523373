import {
  Autocomplete,
  FormHelperText,
  TextField,
  IconButton,
  Icon,
} from "@mui/material";
import { t } from "i18next";
import { get, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import "./BMXAutocomplate.scss";
import { ListItemButton } from "@material-ui/core";

export default function BMXAutocomplente(props) {
  const {
    meta: { touched, error, submitError },
    input,
    currentValues,
    afterIcon,
    afterIconSize,
    afterIconClick,
    disabled,
    options,
  } = props;

  const prevCurrentValues = useRef();
  const initDefault = input.multiple ? [] : null;
  const [defaultVal, setDefaultVal] = useState(initDefault);
  const [inputValue, setInputValue] = useState(""); // Add state for input value

  // eslint-disable-next-line
  useEffect(() => {
    if (input.multiple) return;
    if (
      !isEqual(
        get(currentValues, input?.name),
        get(prevCurrentValues, input?.name)
      )
    ) {
      setDefaultVal(get(currentValues, input?.name));
    }
    prevCurrentValues.current = currentValues;
  });

  const isError = () => {
    return !!((error || submitError) && touched);
  };

  const onChange = (event, option) => {

    if (input.multiple) {
      setDefaultVal(option);
      return input.onChange(option);
    }
    setDefaultVal(option);
    return input.onChange(option);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleBlur = () => {
    const selectedOption = options.find(
      (option) => option?.label?.toLowerCase() === inputValue?.toLowerCase()
    );
    if (!selectedOption) {
      setDefaultVal(null);
      input.onChange(null);
    }
  };

  let conditionalProps = {};

  if (!input.multiple) {
    conditionalProps = {
      renderOption: (props, option, state, ownerState) => {
        return (
          <ListItemButton onClick={(e) => onChange(e, option)}>
            {option?.label}
          </ListItemButton>
        );
      },
    };
  }

  const onBlur = () => {
    if (input?.multiple) return;
    if (!input.value || typeof input.value === "string")
      return input.onChange(null);
  };

  return (
    <>
      <Autocomplete
        data-cy="autocomplete-select"
        {...props}
        {...conditionalProps}
        options={options}
        multiple={input?.multiple}
        onBlur={onBlur || handleBlur}
        name={input?.name}
        className={`BMXAutocomplate ${input.multiple ? "Multiple" : ""}`}
        value={defaultVal}
        disableCloseOnSelect={Boolean(input.multiple)}
        isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
        onChange={onChange}
        onInputChange={handleInputChange}
        noOptionsText={t("global:no_values")}
        renderInput={(params) => (
          <div className="InputArea">
            <TextField
              variant="standard"
              {...params}
              {...props}
              {...input}
              error={isError()}
            />
            {isError() && (
              <FormHelperText>
                <span className="error text">{error || submitError}</span>
              </FormHelperText>
            )}
            {afterIcon && (
              <IconButton
                size="small"
                disabled={disabled}
                onClick={afterIconClick}
                disableRipple={!afterIconClick}
              >
                <Icon style={{ fontSize: afterIconSize }}>{afterIcon}</Icon>
              </IconButton>
            )}
          </div>
        )}
      />
    </>
  );
}
