import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { rentContractReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);
  delete data.listDevicesRender;
  if (values?.servicePriorityContract?.value) {
    set(
      data,
      "servicePriorityContract.uuid",
      values?.servicePriorityContract?.value
    );
  }
  if (values?.rentContractType?.value) {
    set(data, "rentContractType.uuid", values?.rentContractType?.value);
  }
  // if (values?.contact?.value) set(data, "contact.uuid", values?.contact?.value);
  // if (values?.contactBuyer?.value)
  //   set(data, "contactBuyer.uuid", values?.contactBuyer?.value);

  if (values?.representative?.value)
    set(data, "representative.uuid", values?.representative?.value);

  let payload;

  if (!update) {
    data.status = rentContractReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );
    if (Boolean(uuid)) {
      const res = await GlobalAuthFetch({
        path: `/rentcontract/${uuid}/createAnnexToRentContract`,
        method: "POST",
        data,
      });
      payload = res.payload;
    } else {
      const res = await GlobalAuthFetch({
        path: `/rentcontract`,
        method: "POST",
        data,
      });
      payload = res.payload;
    }
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/rentcontract/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);
    props.setErrorHeader(2, true);
    props.setErrorHeader(3, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);
  props.setErrorHeader(2, false);
  props.setErrorHeader(3, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};
  const errorsStep2 = {};
  const errorsStep3 = {};

  if (!values.title) {
    errorsStep0.title = t("global:rentContract.errors.title");
  }

  if (!values.accountDay) {
    errorsStep2.accountDay = t("global:rentContract.errors.accountDay");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  if (
    !props.steps[2]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep2).length > 0
  ) {
    props.setErrorHeader(2, true);
  }
  if (
    !!props.steps[2]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep2).length <= 0
  ) {
    props.setErrorHeader(2, false);
  }
  if (
    !props.steps[3]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep3).length > 0
  ) {
    props.setErrorHeader(3, true);
  }
  if (
    !!props.steps[3]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep3).length <= 0
  ) {
    props.setErrorHeader(3, false);
  }
  return { ...errorsStep0, ...errorsStep1, ...errorsStep2, ...errorsStep3 };
};
