import { updateTableSocket } from "../../Redux/Reducers/SocketReducer";
import store from "../../store";

const {dispatch} = store;

export const updateAdminRegistry =(uuid="") => {
        dispatch(updateTableSocket(`/dashboard/admin/registry/${uuid}`)) 
};

export const updateAdminRegistryByName = (registryName="") => {
        dispatch(updateTableSocket(`/dashboard/admin/${registryName}`));
}

export const updateDashboardByName= (registryName,uuid="") => {
        dispatch(updateTableSocket(`/dashboard/${registryName}/${uuid}`));
}