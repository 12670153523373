import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const ChangePhoneFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div className="justify-content-start">
      <BMXSeparator title={t("global:profile.changePhone")} />
      <Row>
        <Col>
          <Field
            data-cy="phone"
            className="ChangePhoneField my-2"
            name="phone"
            type="text"
            label={t("global:phoneNumber")}
            component={BMXTextField}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChangePhoneFormStepOne;
