import store from "../../store";
import {intersection} from "lodash"

export const create = (rights,path) =>{
   return permissionFind(PERMISSIONS.create)(rights,path);
}

export const edit = (rights,path) => {
    return permissionFind(PERMISSIONS.update)(rights,path);
}

export const remove = (rights,path) =>{
    return permissionFind(PERMISSIONS.delete)(rights,path);
}

export const PERMISSIONS = {
    create: "_CREATE",
    update: "_UPDATE",
    delete: "_DELETE",
    read: "_READ"
}

//Dodajemy miejsca które nie są blokowane
const ModulePermissionsExceptions = [
    "clipboard",
    'alert'
]

const permissionFind=(permission)=>(rights,path)=>{

    if(ModulePermissionsExceptions.includes(path?.split("/").at(2))){
        return true;
    }

    const user = store.getState()?.userSession?.user;
    if(user.admin || user.superAdmin){
        return true;
    }
    const userRights = user?.roles?.flatMap(el=>el?.rights?.map(els=>els.name));
    const registryRights = rights?.map(el=>el.name);
    return Boolean(intersection(userRights,registryRights).find(el=>el.includes(permission)));
}