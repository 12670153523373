import { Form } from 'react-final-form';
import { onSubmit, onValidate } from './AddAttachmentsFolderFormSubmit';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';
import AddAttachmentsFolderFormStepOne from './AddAttachmentsFolderFormSteps/AddAttachmentsFolderFormStepOne';

const AddAttachmentsForm = (props) => {
  const theme = useTheme();

  const { step, setStep, initialValues} = props;
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit({ ...val }, props)}
      validate={(val)=>onValidate(val,props)}
    >
      {({ handleSubmit }) => (
        <form
          id={props.formName}
          onSubmit={handleSubmit}        
        >
          <SwipeableViews          
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={step}
            onChangeIndex={(index) => setStep(index)}
          >
            <AddAttachmentsFolderFormStepOne {...props}
            />         
          </SwipeableViews>
        </form>
      )}
    </Form>
  );
};

export default AddAttachmentsForm;
